import { Component, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AdminService } from "../@shared/services/admin.service"
import { NoteService } from "../@shared/services/note.service"
import { Verification } from "../@shared/models/verification"
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'src/app/@shared/services/confirmation.service';
import  { RelatedEntityModalService } from "../@shared/services/related-entity-modal.service"
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { RelatedEntityModalComponent } from "../related-entity-modal/related-entity-modal.component"
import * as CONSTANTS from '../@shared/constants';
import { Note } from '../@shared/models/note';
import { parseDate } from '../@shared/helpers/date';
import { NetworkType } from '../@shared/models/network-type';
import { AuthService } from "../@shared/services/auth.service"
import { Title } from "@angular/platform-browser"
import { Event } from "../@shared/models/event"
import { ContextMenuService } from '../@shared/services/context-menu.service';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html'
})
export class VerificationComponent {
  @ViewChild(ConfirmationModalComponent) confirmationModal: ConfirmationModalComponent
  @ViewChild(RelatedEntityModalComponent) relatedEntityModal: RelatedEntityModalComponent

  verification: Verification|null = null
  form: UntypedFormGroup
  carveOutPayers: any[] = []
  notes: Note[] = []
  content: string
  jiraTicketTitle: string
  jiraTicketDescription: string
  networkTypes: NetworkType[] = []
  statusOptions: { value: string; text: string }[] = [
    { value: 'pending.eligible', text: 'Pending' },
    { value: 'complete.eligible', text: 'Eligible' },
    { value: 'complete.ineligible', text: 'Ineligible' },
    { value: 'error.member.id', text: 'Member ID Incorrect' },
    { value: 'error.member.dob', text: 'Member DOB Incorrect' },
    { value: 'error.member.name', text: 'Member Name Incorrect' },
    { value: 'error.payer', text: 'Payer Error' },
    { value: 'error.payer.npi', text: 'Payer Error - NPI Not Recognised' },
   ]
  payerErrorOptions: { value: string; text: string }[] = [
    { value: 'UNABLE_TO_REACH_REPRESENTATIVE', text: 'Unable to Reach Representative / IVR issues' },
    { value: 'DISCONNECTIONS_LONG_HOLD_TIMES', text: 'Disconnections / Long Hold Times' },
    { value: 'MISROUTING', text: 'Misrouting' },
    { value: 'MEMBER_INFORMATION_ISSUES', text: 'Member Information Issues' },
    { value: 'PORTAL_ONLY_ACCESS', text: 'Portal-Only Access' },
    { value: 'DIAGNOSIS_CHIEF_COMPLAINT_REQUIRED', text: 'Diagnosis/Chief Complaint Required' },
    { value: 'TAXID_NPI_ISSUES', text: 'TAXID/NPI issues' },
    { value: 'REFUSAL_TO_PROVIDE_INFORMATION', text: 'Refusal to Provide Information' },
    { value: 'FAX_REQUESTS_AND_DELAYS', text: 'Fax Requests and Delays' },
    { value: 'PAYOR_CLOSED_OR_UNAVAILABLE', text: 'Payor Closed or Unavailable' },
    { value: 'TECHNICAL_ISSUES', text: 'Technical Issues' }
  ]
  isExternalUser = false
  changeHealthcareResponses: any[] = []
  recreateVerificationUrl: string = null
  adminUsers: any[] = []
  openAdminAssigneesModal = false

  constructor(
    private adminService: AdminService,
    private confirmationService: ConfirmationService,
    private relatedEntityModalService: RelatedEntityModalService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private noteService: NoteService,
    private authService: AuthService,
    private titleService: Title,
    public contextMenuService: ContextMenuService
  ) {}

  async ngOnInit() {
    this.titleService.setTitle("Verification")

    this.isExternalUser = await this.authService.hasGroup("external")

    this.confirmationService.getConfirmation().subscribe(data => {
      this.confirmationModal.show(data.onConfirm, data.question, data.actionButton);
    });

    this.relatedEntityModalService.get().subscribe(data => {
      this.relatedEntityModal.show(data)
    })

    const verificationId = this.route.snapshot.paramMap.get('id')
    this.verification = await this.adminService.getVerification(verificationId)

    this.recreateVerificationUrl = `https://app.soharhealth.com/create-patient?firstName=${this.verification.firstName}&lastName=${this.verification.lastName}&dateOfBirth=${this.verification.dateOfBirth}&state=${this.verification.state}&memberId=${this.verification.memberId}&payerId=${this.verification.payerId}&npi=${this.verification.npi}&taxonomyCode=${this.verification.taxonomyCode}&placeOfServiceCode=${this.verification.placeOfServiceCode}`

    this.form = this.formBuilder.group({
      coverageStartDate: ['', []],
      coverageEndDate: ['', []],
      innCopay: ['', []],
      innCoinsurance: ['', [Validators.min(0), Validators.max(1)]],
      innTotalDeductible: ['', []],
      innRemainingDeductible: ['', []],
      innOutOfPocket: ['', []],
      innOutOfPocketRemaining: ['', []],
      innPriorAuthRequired: ['', []],
      oonCopay: ['', []],
      oonCoinsurance: ['', [Validators.min(0), Validators.max(1)]],
      oonTotalDeductible: ['', []],
      oonRemainingDeductible: ['', []],
      oonOutOfPocket: ['', []],
      oonOutOfPocketRemaining: ['', []],
      oonPriorAuthRequired: ['', []],
      status: ['', [Validators.required]],
      planName: ['', []],
      resolutionMethod: ['', [Validators.required]],
      payerError: ['', [Validators.required]],
      carveOutPayerId: ['', []],
      networkType: ['', []]
    })

    const inn = this.verification.benefits.filter((benefit) => benefit.networkStatus == "inn" && (benefit.tier == 1 || benefit.tier == null))[0]
    const oon = this.verification.benefits.filter((benefit) => benefit.networkStatus == "oon")[0]

    this.updateForm(
      this.verification.coverageStartDate,
      this.verification.coverageEndDate,
      inn.copay,
      inn.coinsurance,
      inn.totalDeductible,
      inn.remainingDeductible,
      inn.outOfPocket,
      inn.outOfPocketRemaining,
      inn.priorAuthRequired,
      oon.copay,
      oon.coinsurance,
      oon.totalDeductible,
      oon.remainingDeductible,
      oon.outOfPocket,
      oon.outOfPocketRemaining,
      oon.priorAuthRequired,
      this.verification.status,
      this.verification.planName,
      this.verification.carveOut?.payerId,
      this.verification.networkType,
      this.verification.resolutionMethod,
      this.verification.payerError
    )

    const data = await this.adminService.getData()

    this.networkTypes = data.networkTypes
    this.adminUsers = data.adminUsers

    this.getCarveOutPayers()
    this.getNotes()
  }

  async getNotes() {
    this.notes = await this.noteService.getNotes(this.verification.verificationId)
  }

  async getCarveOutPayers() {
    this.carveOutPayers = (await this.adminService.getPayers()).filter((payer: any) => {
      return payer.payerId != this.verification.payerId
    })
  }

  coverageEndDatePassed(): { result: boolean, message?: string } {
    if (this.form.value.status && this.form.value.coverageEndDate) {
      if (this.form.value.status.toLowerCase().includes('ineligible')) return { result: false };

      const currentDate = new Date()
      currentDate.setHours(0, 0, 0, 0)
      let coverageEndDate: Date;

      try {
        coverageEndDate = parseDate(this.form.value.coverageEndDate);
      } catch (error: any) {
        return { result: true, message: error.message };
      }

      if (coverageEndDate < currentDate) {
        return { result: true, message: "Coverage end date has passed" };
      }
    }
    return { result: false };
  }

  didClickOutOfNetworkBenefitsNotCovered() {
    this.form.controls["oonCopay"].setValue(0)
    this.form.controls["oonCoinsurance"].setValue(1.0)
    this.form.controls["oonTotalDeductible"].setValue(0)
    this.form.controls["oonRemainingDeductible"].setValue(0)
    this.form.controls["oonOutOfPocket"].setValue(0)
    this.form.controls["oonOutOfPocketRemaining"].setValue(0)
    this.form.controls["oonPriorAuthRequired"].setValue("true")
  }

  updateForm(coverageStartDate, coverageEndDate, innCopay, innCoinsurance, innTotalDeductible, innRemainingDeductible, innOutOfPocket, innOutOfPocketRemaining, innPriorAuthRequired, oonCopay, oonCoinsurance, oonTotalDeductible, oonRemainingDeductible, oonOutOfPocket, oonOutOfPocketRemaining, oonPriorAuthRequired, status, planName, carveOutPayerId: string|null, networkType: string|null = null, resolutionMethod: string|null = null, payerError: string|null = null) {
    this.form.controls["coverageStartDate"].setValue(coverageStartDate)
    this.form.controls["coverageEndDate"].setValue(coverageEndDate)
    this.form.controls["innCopay"].setValue(innCopay)
    this.form.controls["innCoinsurance"].setValue(innCoinsurance)
    this.form.controls["innTotalDeductible"].setValue(innTotalDeductible)
    this.form.controls["innRemainingDeductible"].setValue(innRemainingDeductible)
    this.form.controls["innOutOfPocket"].setValue(innOutOfPocket)
    this.form.controls["innOutOfPocketRemaining"].setValue(innOutOfPocketRemaining)
    if (innPriorAuthRequired != null) {
      if (innPriorAuthRequired) {
        this.form.controls["innPriorAuthRequired"].setValue("true")
      } else {
        this.form.controls["innPriorAuthRequired"].setValue("false")
      }
    } else {
      this.form.controls["innPriorAuthRequired"].setValue(null)
    }
    this.form.controls["oonCopay"].setValue(oonCopay)
    this.form.controls["oonCoinsurance"].setValue(oonCoinsurance)
    this.form.controls["oonTotalDeductible"].setValue(oonTotalDeductible)
    this.form.controls["oonRemainingDeductible"].setValue(oonRemainingDeductible)
    this.form.controls["oonOutOfPocket"].setValue(oonOutOfPocket)
    this.form.controls["oonOutOfPocketRemaining"].setValue(oonOutOfPocketRemaining)
    if (oonPriorAuthRequired != null) {
      if (oonPriorAuthRequired) {
        this.form.controls["oonPriorAuthRequired"].setValue("true")
      } else {
        this.form.controls["oonPriorAuthRequired"].setValue("false")
      }
    } else {
      this.form.controls["oonPriorAuthRequired"].setValue(null)
    }
    this.form.controls["status"].setValue(status)
    this.form.controls["planName"].setValue(planName)
    this.form.controls["carveOutPayerId"].setValue(carveOutPayerId)
    this.form.controls["networkType"].setValue(networkType)
    this.form.controls["resolutionMethod"].setValue(resolutionMethod)
    this.form.controls["payerError"].setValue(payerError)
  }

  isValidDate = (value: string|null): boolean => {
    if (!value) {
      return true
    } else if (value.length !== 10) {
      return false
    } else if (!/^\d{2}\/\d{2}\/\d{4}$/.test(value)) {
      return false
    }

    const [month, day, year] = value.split('/').map(Number)
    const date = new Date(year, month - 1, day)

    return date && date.getMonth() + 1 === month && date.getDate() === day && date.getFullYear() === year
  }

  didClickSelectAdminAssignee() {
    this.openAdminAssigneesModal = true
  }

  validateSubmit() {
    const statusValues = this.statusOptions.map(option => option.value);
    if (!this.form.value.status || !statusValues.includes(this.form.value.status)) {
      alert("status required")
      return false
    } else if (this.form.value.status === 'error.payer' && !this.form.value.payerError) {
      console.log(this.form.value.payerError)
      alert("payer error required")
      return false
    } else if (!this.form.value.resolutionMethod) {
      alert("resolution method required")
      return false
    } else if (!this.isValidDate(this.form.value.coverageStartDate)) {
      alert("coverageStartDate must be in MM/DD/YYYY format")
      return false
    } else if (!this.isValidDate(this.form.value.coverageEndDate)) {
      alert("coverageEndDate must be in MM/DD/YYYY format or left empty")
      return false
    }

    const endDatePassed = this.coverageEndDatePassed();
    if (endDatePassed.result) {
      alert(endDatePassed.message);
      return false;
    }

    // Validate remaining deductible
    if (this.form.value.innTotalDeductible != null &&
      this.form.value.innRemainingDeductible != null &&
      this.form.value.innRemainingDeductible > this.form.value.innTotalDeductible) {
      alert("In network remaining deductible cannot be greater than total deductible")
      return false
    } else if (this.form.value.oonTotalDeductible != null &&
      this.form.value.oonRemainingDeductible != null &&
      this.form.value.oonRemainingDeductible > this.form.value.oonTotalDeductible) {
      alert("Out of network remaining deductible cannot be greater than total deductible")
      return false
    }

    // Validate out of pocket remaining
    if (this.form.value.innOutOfPocket != null &&
      this.form.value.innOutOfPocketRemaining != null &&
      this.form.value.innOutOfPocketRemaining > this.form.value.innOutOfPocket) {
      alert("In network out of pocket remaining cannot be greater than out of pocket max")
      return false
    } else if (this.form.value.oonOutOfPocket != null &&
      this.form.value.oonOutOfPocketRemaining != null &&
      this.form.value.oonOutOfPocketRemaining > this.form.value.oonOutOfPocket) {
      alert("Out of network out of pocket remaining cannot be greater than out of pocket max")
      return false
    }

    // Validate coinsurance
    if (this.form.value.innCoinsurance != null &&
      this.form.value.innCoinsurance > 1.0) {
      alert("In network coinsurance cannot be greater than 1")
      return false
    } else if (this.form.value.oonCoinsurance != null &&
      this.form.value.oonCoinsurance > 1.0) {
      alert("Out of network coinsurance cannot be greater than 1")
      return false
    } else if (this.form.value.innCoinsurance != null &&
      (this.form.value.innCoinsurance * 100) % 5 != 0) {
      alert("In network coinsurance must be in 5% increments")
      return false
    } else if (this.form.value.oonCoinsurance != null &&
      (this.form.value.oonCoinsurance * 100) % 5 != 0) {
      alert("Out of network coinsurance must be in 5% increments")
      return false
    }

    return true;
  }

  async showConfirmation() {
    const isFormValid = this.validateSubmit();
    if (!isFormValid) {
      return;
    }
    const onConfirm = async () => {
      await this.onSubmit();
    };
    this.confirmationService.confirm(onConfirm, CONSTANTS.COMMON.CONFIRM_SUBMIT, CONSTANTS.COMMON.SUBMIT);
  }

  didClickShowRelatedEntityModal(relatedEntity: {
    name: string|undefined,
    phoneNumber: string|undefined
  }|undefined) {
    this.relatedEntityModalService.open(relatedEntity)
  }

  async onSubmit() {
    const verificationId: string = this.verification.verificationId
    const customerId: string = this.verification.customerId
    const patientId: string = this.verification.patientId
    const status: string|null = this.form.value.status
    const resolutionMethod: string|null = this.form.value.resolutionMethod
    const payerError: string|null = this.form.value.payerError

    const coverageStartDate: string|null = this.form.value.coverageStartDate || null
    const coverageEndDate: string|null = this.form.value.coverageEndDate || null
    const innCopay: number|null = this.form.value.innCopay
    const innCoinsurance: number|null = this.form.value.innCoinsurance
    const innTotalDeductible: number|null = this.form.value.innTotalDeductible
    const innRemainingDeductible: number|null = this.form.value.innRemainingDeductible
    const innOutOfPocket: number|null = this.form.value.innOutOfPocket
    const innOutOfPocketRemaining: number|null = this.form.value.innOutOfPocketRemaining
    const innPriorAuthRequired: boolean|null = this.form.value.innPriorAuthRequired ? this.form.value.innPriorAuthRequired == "true" : null
    const oonCopay = this.form.value.oonCopay
    const oonCoinsurance: number|null = this.form.value.oonCoinsurance
    const oonTotalDeductible: number|null = this.form.value.oonTotalDeductible
    const oonRemainingDeductible: number|null = this.form.value.oonRemainingDeductible
    const oonOutOfPocket: number|null = this.form.value.oonOutOfPocket
    const oonOutOfPocketRemaining: number|null = this.form.value.oonOutOfPocketRemaining
    const oonPriorAuthRequired: boolean|null = this.form.value.oonPriorAuthRequired ? this.form.value.oonPriorAuthRequired == "true" : null
    const planName: string|null = this.form.value.planName || null
    const carveOutPayerId: string|null = this.form.value.carveOutPayerId == "" ? null : (this.form.value.carveOutPayerId || null)
    const networkType: string|null = this.form.value.networkType || null;

    const res = await this.adminService.updateVerification(
      verificationId,
      customerId,
      patientId,
      coverageStartDate,
      coverageEndDate,
      innCopay,
      innCoinsurance,
      innTotalDeductible,
      innRemainingDeductible,
      innOutOfPocket,
      innOutOfPocketRemaining,
      innPriorAuthRequired,
      oonCopay,
      oonCoinsurance,
      oonTotalDeductible,
      oonRemainingDeductible,
      oonOutOfPocket,
      oonOutOfPocketRemaining,
      oonPriorAuthRequired,
      status,
      planName,
      resolutionMethod,
      carveOutPayerId,
      networkType,
      payerError
    )

    // Capture the current query parameters
    let currentQueryParams = {};
    this.route.queryParams.subscribe(params => {
      currentQueryParams = params;
    });

    this.router.navigate([""], { relativeTo: this.route, queryParams: currentQueryParams })
  }

  async getResponse(event: Event) {
    if (event.response) {
      event.response = null
      return
    }
    const verificationId = this.verification.verificationId
    const responseId = event.responseId
    event.response = await this.adminService.getResponse(verificationId, responseId)
  }

  async didClickSendNote() {
    if (
      !this.verification ||
      !this.content
    ) {
      alert("Something went wrong, please try again")
      return
    }

    await this.noteService.createNote(
      this.verification,
      this.content
    )

    this.content = ""

    this.getNotes()
  }

  async didClickCreateJiraTicket() {
    if (
      !this.verification ||
      !this.jiraTicketTitle ||
      !this.jiraTicketDescription
    ) {
      alert("Something went wrong, please try again")
      return
    }

    await this.noteService.createNote(
      this.verification,
      null,
      this.jiraTicketTitle,
      this.jiraTicketDescription
    )

    this.jiraTicketTitle = ""
    this.jiraTicketDescription = ""

    alert("Ticket created")
  }

  async didClickRetryVerification() {
    const verificationId = this.verification.verificationId

    if (!verificationId) {
      return
    }

    const confirmed = confirm(`Are you sure you want to retry this verification?`)

    if (!confirmed) {
      return
    }

    try {
      const res = await this.adminService.retryPendingVerification(verificationId)
      console.log(res)
      alert(`Verification retried`)
    } catch (error: any) {
      alert("Something went wrong")
    }
  }
}
