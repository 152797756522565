import { ENTITY_IDENTIFIERS } from "../constants/data"

const parseData = (data: string|null): {
	entityIdentifierCode: string|null,
	nameLastOrOrganizationName: string|null,
	patientState: string|null
}|null => {
	if (data == null) {
		return null
	}

	try {
		const json: {
			entityIdentifierCode: string|null|undefined,
			nameLastOrOrganizationName: string|null|undefined,
			patientState: string|null|undefined
		} = JSON.parse(data)

		return {
			entityIdentifierCode: json.entityIdentifierCode || null,
			nameLastOrOrganizationName: json.nameLastOrOrganizationName || null,
			patientState: json.patientState || null
		}
	} catch (error: any) {
		return null
	}
}

export const getEntityIdentifierCode = (data: string|null): string|null => {
	const json = parseData(data)

	if (json == null) {
		return null
	}

	const entityIdentifierCode = json.entityIdentifierCode

	if (entityIdentifierCode == null) {
		return null
	}

	const entityIdentifierCodes = ENTITY_IDENTIFIERS.map((entityIdentifier) => {
		return entityIdentifier.code
	})

	if (entityIdentifierCodes.includes(entityIdentifierCode) == false) {
		return null
	}

	return entityIdentifierCode
}
