import { Injectable } from '@angular/core';
import { NetworkService } from './network.service'
import { map } from 'rxjs/operators'
import { Verification } from "../models/verification"
import { VerificationsResponse } from "../models/verifications-response"
import { Payer, PendingPayer } from "../models/payer"
import { AdminUser } from '../models/admin-user';
import { Customer } from '../models/customer';
import { SpecialtyModel } from "../models/specialtyModel"
import { PayerMapping } from "../models/payer-mapping"

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  cache: {
    [url: string]: any
  } = {}

  constructor(
    private networkService: NetworkService
  ) {}

  async retryPendingVerifications(payerId: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.networkService.post(`admin/payers/${payerId}/verifications`, {}).pipe(
        map((res: any) => {
          return res.verifications
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async retryPendingVerification(verificationId: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.networkService.post(`admin/verifications/${verificationId}`, {}).pipe(
        map((res: any) => {
          return res.verification
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getVersion(): Promise<string> {
    if (this.cache[`version`]) {
      return this.cache[`version`]
    }
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/version`).pipe(
        map((res: any) => {
          const _version = res.version

          this.cache[`version`] = _version

          return _version
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getData(): Promise<{
    specialties: any[],
    networkTypes: any[],
    adminUsers: any[]
  }> {
    if (this.cache[`admin/data`]) {
      return this.cache[`admin/data`]
    }
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/data`).pipe(
        map((res: any) => {
          const _res = {
            specialties: res.specialties,
            networkTypes: res.networkTypes,
            adminUsers: res.adminUsers
          }

          this.cache[`admin/data`] = _res

          return _res
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getVerifications(status: string): Promise<VerificationsResponse> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/verifications?status=${status}`).pipe(
        map((res: any) => {
          return VerificationsResponse.fromJson(res)
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getVerification(verificationId: string): Promise<Verification> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/verifications/${verificationId}`).pipe(
        map((res: any) => {
          return Verification.fromJson(res.verification)
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getResponse(verificationId: string, responseId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/verifications/${verificationId}/responses/${responseId}`).pipe(
        map((res: any) => {
          if (!res || !res.response) {
            return null
          }

          return JSON.parse(res.response)
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async registerIntegrationApiKey(customerId: string, apiKey: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.post(`admin/customers/${customerId}/integrations`, {
        apiKey
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getVerificationPayerMappingRules(verificationId: string): Promise<PayerMapping[]> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/verifications/${verificationId}/mapping`).pipe(
        map((res: any) => {
          return res.mappingRules.map((data: any) => {
            return PayerMapping.fromJson(data)
          })
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getPayers(): Promise<Payer[]> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/payers`).pipe(
        map((res: any) => {
          return res.payers.map(data => Payer.fromJson(data))
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getPendingPayers(): Promise<PendingPayer[]> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/payers/pending`).pipe(
        map((res: any) => {
          return res.payers
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getDiscoveryRequests(
    hoursAgo: string
  ): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/discovery?hoursAgo=${hoursAgo}`).pipe(
        map((res: any) => {
          return res.discoveryRequests
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getDiscoveryRequest(discoveryId: string): Promise<any|null> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/discovery/${discoveryId}`).pipe(
        map((res: any) => {
          return res.discoveryRequest
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getDiscoveryResponse(
    discoveryId: string,
    responseId: number): Promise<any|null> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/discovery/${discoveryId}/responses/${responseId}`).pipe(
        map((res: any) => {
          return res.discoveryResponse
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getPayer(payerId: string): Promise<Payer|null> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/payers/${payerId}`).pipe(
        map((res: any) => {
          return Payer.fromJson(res.payer)
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getPayerSpecialty(
    payerId: string,
    specialtyId: string
  ): Promise<{
    payer: any,
    payerSpecialtyFilters: any[],
    payerConfigurations: any[],
    specialty: any
  }|null> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/payers/${payerId}/specialties/${specialtyId}`).pipe(
        map((res: any) => {
          return res
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async updatePayer(
    payerId: string,
    phoneNumber: string|null,
    payerName: string|null,
    changeHealthcarePayerId: string|null,
    availityPayerId: string|null,
    officeAllyPayerId: string|null,
    unitedHealthcarePayerId: string|null,
    clearingHouseIdentifier: number|null,
    providerType: string,
    type: string
  ) {
    return new Promise((resolve, reject) => {
      this.networkService.put(`admin/payers/${payerId}`, {
        phoneNumber,
        payerName,
        changeHealthcarePayerId,
        availityPayerId,
        officeAllyPayerId,
        unitedHealthcarePayerId,
        clearingHouseIdentifier,
        providerType,
        type
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async resetUserPassword(
    customerId: string,
    cognitoId: string
  ) {
    return new Promise((resolve, reject) => {
      this.networkService.put(`admin/customers/${customerId}/users/${cognitoId}`, {}).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async resetUserMfa(
    customerId: string,
    cognitoId: string
  ) {
    return new Promise((resolve, reject) => {
      this.networkService.put(`admin/customers/${customerId}/users/${cognitoId}/mfa`, {}).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async updateAssignees(
    verificationIds: string[],
    adminCognitoId: string
  ) {
    return new Promise((resolve, reject) => {
      this.networkService.put(`admin/verifications/assignees`, {
        verificationIds,
        adminCognitoId
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async updateStatuses(
    verificationIds: string[],
    status: string
  ) {
    return new Promise((resolve, reject) => {
      this.networkService.put(`admin/verifications/statuses`, {
        verificationIds,
        status
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getRelatedPayers(payerId: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/payers/${payerId}/related-payers`).pipe(
        map((res: any) => {
          return res.relatedPayers
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getPayerAuditRecords(payerId: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/payers/${payerId}/audit`).pipe(
        map((res: any) => {
          return res.auditRecords
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getTaxonomyCodes(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/taxonomy-codes`).pipe(
        map((res: any) => {
          return res.taxonomyCodes
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async updateTaxonomyCode(taxonomyCode: string, specialtyId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.networkService.put(`admin/taxonomy-codes/${taxonomyCode}`, {
        specialtyId
      }).pipe(
        map((res: any) => {
          return res.taxonomyCodes
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getSpecialties(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/specialties`).pipe(
        map((res: any) => {
          return res.specialties
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async createSpecialtyCPTCode(
    specialtyId: string,
    code: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.post(`admin/specialties/${specialtyId}/codes`, {
        code
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async createSpecialty(
    name: string,
    code: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.post(`admin/specialties`, {
        name,
        code
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getSpecialty(specialtyId: string): Promise<{
    specialty: any,
    models: SpecialtyModel[],
    codes: any[]
    taxonomyCodes: string[]
  }|null> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/specialties/${specialtyId}`).pipe(
        map((res: any) => {
          return {
            specialty: res.specialty,
            models: res.models,
            codes: res.codes,
            taxonomyCodes: res.taxonomyCodes
          }
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getSpecialtyModelPayerConfigs(specialtyId: string, modelId: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/specialties/${specialtyId}/models/${modelId}/payer-configs`).pipe(
        map((res: any) => res.configs)
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async updateSpecialtyModelPayerConfigStatus(specialtyId: string, modelId: string, payerIds: string[], status: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.put(`admin/specialties/${specialtyId}/models/${modelId}/payer-configs`, { payerIds, status })
        .subscribe({
          next: resolve,
          error: reject
        })
    })
  }

  async updateSpecialtyModel(
    specialtyId: string,
    modelId: string,
    status: number
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.put(`admin/specialties/${specialtyId}/models/${modelId}`, {
        status
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getTeam(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/data/team`).pipe(
        map((res: any) => {
          return res.admins
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async createAdminUser(
    adminUser: AdminUser
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      this.networkService.post(`admin/data/team`, {
        email: adminUser.email,
        firstName: adminUser.firstName,
        lastName: adminUser.lastName,
        groups: adminUser.groups,
        isActive: adminUser.active
      }).pipe(
        map((res: any) => {
          return res.password
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async createRelatedPayer(
    payerId: string,
    relatedPayerId: string,
    specialtyId: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.post(`admin/payers/${payerId}/related-payers`, {
        relatedPayerId,
        specialtyId
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async deleteRelatedPayer(payerId: string, id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.delete(`admin/payers/${payerId}/related-payers/${id}`).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async createPayer(payerName: string, changeHealthcarePayerId: string, standardPayerId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.post(`admin/payers`, {
        payerName,
        changeHealthcarePayerId,
        standardPayerId
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async createCustomer(
    emailAddress: string,
    customerName: string,
    maxProductionVerificationCount: number|null
  ): Promise<{
    emailAddress: string,
    password: string,
    productionApiKey: string,
    sandboxApiKey: string
  }> {
    return new Promise((resolve, reject) => {
      this.networkService.post(`admin/customers`, {
        emailAddress,
        customerName,
        maxProductionVerificationCount
      }).pipe(
        map((res: any) => {
          return {
            emailAddress: res.emailAddress,
            password: res.password,
            productionApiKey: res.productionApiKey,
            sandboxApiKey: res.sandboxApiKey
          }
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async updateCustomer(
    customerId: string,
    discoveryQuota: number | null,
    verificationQuota: number | null
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.put(`admin/customers/${customerId}`, {
        discoveryQuota,
        verificationQuota,
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async updateVerification(
    verificationId: string|null,
    customerId: string|null,
    patientId: string|null,
    coverageStartDate: string|null,
    coverageEndDate: string|null,
    innCopay: number|null,
    innCoinsurance: number|null,
    innTotalDeductible: number|null,
    innRemainingDeductible: number|null,
    innOutOfPocket: number|null,
    innOutOfPocketRemaining: number|null,
    innPriorAuthRequired: boolean|null,
    oonCopay: number|null,
    oonCoinsurance: number|null,
    oonTotalDeductible: number|null,
    oonRemainingDeductible: number|null,
    oonOutOfPocket: number|null,
    oonOutOfPocketRemaining: number|null,
    oonPriorAuthRequired: boolean|null,
    status: string,
    planName: string|null,
    resolutionMethod: string,
    networkType: string|null,
    payerError: string|null
  ): Promise<any> {

    return new Promise((resolve, reject) => {
      this.networkService.put(`admin/verifications/${verificationId}`, {
      customerId,
      patientId,
      resolutionMethod,
      status,
      planName,
      networkType,
      coverageStartDate,
      coverageEndDate,
      innCopay,
      innCoinsurance,
      innTotalDeductible,
      innRemainingDeductible,
      innOutOfPocket,
      innOutOfPocketRemaining,
      innPriorAuthRequired,
      oonCopay,
      oonCoinsurance,
      oonTotalDeductible,
      oonRemainingDeductible,
      oonOutOfPocket,
      oonOutOfPocketRemaining,
      oonPriorAuthRequired,
      payerError
    }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async getCustomers(): Promise<Customer[]> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/customers`)
        .pipe(
          map((res: any) => {
            return res.customers.map(data => Customer.fromJson(data))
          })
        )
        .subscribe({
          next: resolve,
          error: reject
        })
    })
  }

  async getCustomer(customerId: string): Promise<any|null> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/customers/${customerId}`)
        .subscribe({
          next: resolve,
          error: reject
        })
    })
  }

  async createCustomerProvider(
    customerId: string,
    payerId: string,
    state: string,
    npi: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.post(`admin/customers/${customerId}/providers`, {
        payerId,
        state,
        npi
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async createCustomerProviders(
    customerId: string,
    providers: {
      payerId: string,
      state: string,
      npi: string
    }[]
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.post(`admin/customers/${customerId}/providers/batch`, {
        providers
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async updateCustomerProvider(
    customerId: string,
    id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.put(`admin/customers/${customerId}/providers/${id}`, {}).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async deleteConfiguration(
    payerId: string,
    configurationId: number
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.delete(`admin/payers/${payerId}/configurations/${configurationId}`).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async deleteState(
    payerId: string,
    state: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.delete(`admin/payers/${payerId}/states/${state}`).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async createAliasPayerID(
    payerId: string,
    aliasPayerId: string,
    isStandardPayerId = false
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.put(`admin/payers/${payerId}`, {
        aliasPayerId,
        isStandardPayerId
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async createPayerState(
    payerId: string,
    state: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.put(`admin/payers/${payerId}`, {
        state
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async createConfiguration(
    payerId: string,
    specialtyId: string,
    serviceTypeCode: string,
    transactionSet: string,
    priority: number
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.post(`admin/payers/${payerId}/configurations`, {
        specialtyId,
        serviceTypeCode,
        transactionSet,
        priority
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async deleteFilterValue(
    payerId: string,
    specialtyId: string,
    id: number
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.delete(`admin/payers/${payerId}/specialties/${specialtyId}/filter-values/${id}`, {}).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async createFilterValue(
    payerId: string,
    specialtyId: string,
    filterValue: string,
    prioritise: boolean,
    exclude: boolean
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.networkService.post(`admin/payers/${payerId}/specialties/${specialtyId}/filter-values`, {
        filterValue,
        prioritise,
        exclude
      }).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

  async previewVerification(
    verificationId: string
  ): Promise<Verification> {
    return new Promise((resolve, reject) => {
      this.networkService.get(`admin/verifications/${verificationId}/preview`).pipe(
        map((res: any) => {
          return Verification.fromJson(res.verification)
        })
      ).subscribe({
        next: resolve,
        error: reject
      })
    })
  }

}
