import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { PayersComponent } from './payers/payers.component';
import { VerificationComponent } from './verification/verification.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { PayerComponent } from './payer/payer.component';
import { CreatePayerComponent } from './create-payer/create-payer.component';
import { EditPayerComponent } from './edit-payer/edit-payer.component';
import { CustomersComponent } from './customers/customers.component';
import { CreateCustomerComponent } from './create-customer/create-customer.component';
import { CustomerComponent } from './customer/customer.component';
import { DiscoveryComponent } from './discovery/discovery.component';
import { DiscoveryDetailComponent } from './discovery-detail/discovery-detail.component';
import { AuthenticatorComponent } from './authenticator/authenticator.component';
import { TotpComponent } from './totp/totp.component';
import { TaxonomyCodeComponent } from './taxonomy-code/taxonomy-code.component';
import { SpecialtiesComponent } from './specialties/specialties.component';
import { SpecialtyComponent } from './specialty/specialty.component';
import { TaxonomyCodesComponent } from './taxonomy-codes/taxonomy-codes.component';
import { QuotaFieldComponent } from './@shared/modules/quota-field/quota-field.component';
import { TeamComponent } from './team/team.component';
import { SpecialtyModelPayerComponent } from './specialty-model-payer-config/specialty-model-payer.component';
import { PayerRoutingComponent } from './payer-routing/payer-routing.component';
import { PayerDiscoveryComponent } from './payer-discovery/payer-discovery.component';
import { PayerMappingComponent } from './payer-mapping/payer-mapping.component';
import { VerificationEventsComponent } from './verification-events/verification-events.component';
import { VerificationDetailComponent } from './verification-detail/verification-detail.component';
import { VerificationNotesComponent } from './verification-notes/verification-notes.component';
import { SebMenuComponent } from './@shared/modules/sub-menu/sub-menu.component';
import { VerificationRelatedEntitiesComponent } from './verification-related-entities/verification-related-entities.component';
import { PayerMappingRulesComponent } from './@shared/modules/payer-mapping-rules/payer-mapping-rules.component';
import { PayersPendingComponent } from './payers-pending/payers-pending.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    HomeComponent,
    PayersComponent,
    PayersPendingComponent,
    VerificationComponent,
    ConfirmationModalComponent,
    PayerComponent,
    CreatePayerComponent,
    EditPayerComponent,
    CustomersComponent,
    CreateCustomerComponent,
    CustomerComponent,
    DiscoveryComponent,
    DiscoveryDetailComponent,
    AuthenticatorComponent,
    TotpComponent,
    TaxonomyCodeComponent,
    SpecialtiesComponent,
    SpecialtyComponent,
    SpecialtyModelPayerComponent,
    TaxonomyCodesComponent,
    QuotaFieldComponent,
    TeamComponent,
    PayerRoutingComponent,
    PayerDiscoveryComponent,
    PayerMappingComponent,
    VerificationEventsComponent,
    VerificationDetailComponent,
    VerificationNotesComponent,
    SebMenuComponent,
    VerificationRelatedEntitiesComponent,
    PayerMappingRulesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
