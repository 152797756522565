<div class="header">
	<h1>{{ payer?.payerName }}</h1>
	<div>
		<a class="button" [routerLink]="['/payers', payer?.payerId, 'edit']">Edit</a>
		<a class="button" (click)="didClickRetryVerifications()">Retry Pending Verifications</a>
	</div>
</div>
<ul class="tabs">
	<li [class.active]="tab == 'main'"><a (click)="navigateToTab('main')">Payer Information</a></li>
	<li [class.active]="tab == 'payerIds'"><a (click)="navigateToTab('payerIds')">Payer IDs</a></li>
	<li [class.active]="tab == 'states'"><a (click)="navigateToTab('states')">States</a></li>
	<li [class.active]="tab == 'specialties'"><a (click)="navigateToTab('specialties')">Specialties</a></li>
	<li [class.active]="tab == 'relatedPayers'"><a (click)="navigateToTab('relatedPayers')">Related Payers</a></li>
	<li [class.active]="tab == 'auditRecords'"><a (click)="navigateToTab('auditRecords')">Audit Records</a></li>
</ul>
<div *ngIf="payer">
	<div *ngIf="tab == 'main'">
		<table>
			<thead>
				<td>Change ID</td>
				<td>Availity ID</td>
				<td>Office Ally ID</td>
				<td>UnitedHealthcare ID</td>
				<td>Sohar ID</td>
				<td>Phone Number</td>
				<td>Type</td>
				<td>Clearing House</td>
				<td>Provider Type</td>
				<td>Public</td>
			</thead>
			<tbody>
				<tr>
					<td>{{ payer.changeHealthcarePayerId }}</td>
					<td>{{ payer.availityPayerId }}</td>
					<td>{{ payer.officeAllyPayerId }}</td>
					<td>{{ payer.unitedHealthcarePayerId }}</td>
					<td>{{ payer.payerId }}</td>
					<td>{{ payer.phoneNumber }}</td>
					<td>{{ payer.payerType }}</td>
					<td>{{ payer.clearingHouse }}</td>
					<td>{{ payer.providerType }}</td>
					<td>{{ payer.isCustomerPayer ? "Yes" : "No" }}</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div *ngIf="tab == 'payerIds'">
		<table>
			<thead>
				<td>Payer IDs</td>
				<td>Primary Payer ID</td>
			</thead>
			<tbody>
				<tr *ngFor="let alias of payer.aliases">
					<td>{{ alias.id }}</td>
					<td>{{ alias.isStandardPayerId ? "Yes" : "" }}</td>
				</tr>
				<tr>
					<td>
						<input type="text" placeholder="Add a new alias payer ID" [(ngModel)]="aliasPayerId" />
					</td>
					<td>
						<a class="button" (click)="didClickAddPayerAlias()">Add</a>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div *ngIf="tab == 'states'">
		<table>
			<thead>
				<td colspan="2">State</td>
			</thead>
			<tbody>
				<tr *ngFor="let state of payer.states">
					<td>{{ state }}</td>
					<td>
						<a class="button" (click)="didClickDeleteState(state)">Delete</a>
					</td>
				</tr>
				<tr>
					<td>
						<select [(ngModel)]="state">
							<option value="">
								Select a state
							</option>
							<option *ngFor="let _state of states" [value]="_state">
								{{ _state }}
							</option>
						</select>
					</td>
					<td>
						<a class="button" (click)="didClickAddState()">Add</a>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div *ngIf="tab == 'specialties'">
		<table>
			<thead>
				<td>Specialty</td>
			</thead>
			<tbody>
				<tr *ngFor="let specialty of specialties">
					<td>
						<a [routerLink]="['/payers', payer.payerId, 'specialties', specialty.specialtyId]">
							{{ specialty.name }}
						</a>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div *ngIf="tab == 'relatedPayers'">
		<table>
			<thead>
				<td>Payer</td>
				<td>Specialty</td>
				<td></td>
			</thead>
			<tbody>
				<tr *ngFor="let payer of relatedPayers">
					<td>{{ payer.payerName }}</td>
					<td>{{ payer.specialtyName }}</td>
					<td><a class="button" (click)="didClickDeleteRelatedPayer(payer.id)">Delete</a></td>
				</tr>
			</tbody>
		</table>
		<form [formGroup]="relatedPayerForm" (ngSubmit)="didSubmitCreateRelatedPayerForm()" class="inline">
			<select formControlName="relatedPayerId" required>
				<option value="null">Select a related payer</option>
				<option *ngFor="let payer of payers" [value]="payer.payerId">{{ payer.payerName }} - {{ payer.changeHealthcarePayerId }}</option>
			</select>
			<select formControlName="specialtyId" required>
				<option [value]="null">Select a specialty</option>
				<option *ngFor="let specialty of specialties" [value]="specialty.specialtyId">
					{{ specialty.name }}
				</option>
			</select>
			<button class="rel-pay-btn">Submit</button>
		</form>
	</div>
	<div *ngIf="tab == 'auditRecords'">
		<table>
			<thead>
				<td>Operation</td>
				<td>Author</td>
				<td>Specialty Name</td>
				<td>Description</td>
				<td>Timestamp</td>
			</thead>
			<tbody>
				<tr *ngFor="let record of auditRecords">
					<td>{{ record.name }}</td>
					<td>{{ record.firstName }} {{ record.lastName }}</td>
					<td>
						<a [routerLink]="['/specialties', record.specialtyId]">{{ record.specialtyName }}</a>
					</td>
					<td class="json-view">
						<pre [ngClass]="{'expanded': record.expanded}">{{ record.description | json }}</pre>
						<button class="button expand-btn" (click)="toggleExpanded(record)">{{ record.expanded ? 'Collapse' : 'Expand'}}</button>
					</td>
					<td>{{ record.created | date: 'MM/dd/yyyy, HH:mm:ss' }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>