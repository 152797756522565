import { Component } from '@angular/core'
import { AdminService } from "../@shared/services/admin.service"
import { Title } from "@angular/platform-browser"
import { Specialty } from "../@shared/models/specialty"

@Component({
  selector: 'app-specialties',
  templateUrl: './specialties.component.html'
})

export class SpecialtiesComponent {

  specialties: Specialty[] = []

  name: string|null = null
  code: string|null = null

  constructor(
    private adminService: AdminService,
    private titleService: Title
  ) {}

  get sortedSpecialties(): Specialty[] {
    return this.specialties.sort((a, b) => {
      return a.name.localeCompare(b.name)
    })
  }

  async ngOnInit() {
    this.titleService.setTitle("Specialties")

    this.specialties = await this.adminService.getSpecialties()
  }

  async didClickCreateSpecialty() {
    const name = this.name
    const code = this.code

    if (!name || !code) {
      return
    }

    try {
      const res = await this.adminService.createSpecialty(name, code)

      this.specialties.push({
        specialtyId: res.specialty.specialtyId,
        name: res.specialty.name,
        code: res.specialty.code,
        taxonomyCodeCount: 0,
        cptCodeCount: 0
      })
    } catch (error: any) {
      console.log(error)
      alert("Something went wrong")
    } finally {
      this.name = null
      this.code = null
    }
  }
}
