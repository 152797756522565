<div class="fixed top-0 left-[260px] w-[calc(100%-260px)] bg-white border-b border-gray-300 p-6 h-[80px] flex items-center justify-between z-10">
	<h1 class="text-xl font-bold">
		<span *ngIf="verification != null">{{ verification.memberId }} - {{ verification.status }}</span>
	</h1>
	<div class="flex" *ngIf="!isExternalUser">
		<a class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100 ml-4" (click)="didClickRetryVerification()">
			Retry Verification
		</a>
		<a class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100 ml-4" [href]="recreateVerificationUrl" target="_blank">
			Recreate Verification
		</a>
		<a class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100 ml-4" [routerLink]="['/create-payer']">
			<svg class="h-[14px]" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 512 512" xml:space="preserve"><g><path d="M480,224H288V32c0-17.673-14.327-32-32-32s-32,14.327-32,32v192H32c-17.673,0-32,14.327-32,32s14.327,32,32,32h192v192   c0,17.673,14.327,32,32,32s32-14.327,32-32V288h192c17.673,0,32-14.327,32-32S497.673,224,480,224z"/></g></svg>
			Create Payer
		</a>
	</div>
</div>

<div class="p-6 bg-white min-h-[calc(100vh-80px)]">

	<div class="tabs tabs-bordered inline-flex">
		<a class="tab h-[48px]" [ngClass]="{ 'tab-active': tab === 'detail' }" (click)="navigateToTab('detail')">Detail</a>
		<a class="tab h-[48px]" [ngClass]="{ 'tab-active': tab === 'events' }" (click)="navigateToTab('events')">Events</a>
		<a class="tab h-[48px]" [ngClass]="{ 'tab-active': tab === 'related-entities' }" (click)="navigateToTab('related-entities')">Related Entities</a>
		<a class="tab h-[48px]" [ngClass]="{ 'tab-active': tab === 'notes' }" (click)="navigateToTab('notes')">Notes</a>
	</div>

	<div *ngIf="verification && adminData" class="mt-6">
		<div *ngIf="tab == 'detail'">
			<app-verification-detail
				[verification]="verification"
				[data]="adminData"
				[payers]="payers"
			/>
		</div>
		<div *ngIf="tab == 'events'">
			<app-verification-events
				[verification]="verification"
			/>
		</div>
		<div *ngIf="tab == 'related-entities'">
			<app-verification-related-entities
				[verification]="verification"
				[payers]="payers"
				(verificationResolved)="didClickRetryVerification()"
			/>
		</div>
		<div *ngIf="tab == 'notes'">
			<app-verification-notes
				[verificationId]="verificationId"
			/>
		</div>
	</div>
</div>

<span *ngIf="isLoading" class="loading loading-spinner loading-lg fixed right-6 bottom-6 text-primary"></span>
