<div class="fixed top-0 left-[260px] w-[calc(100%-260px)] bg-white border-b border-gray-300 p-6 h-[80px] flex items-center justify-between z-10">
	<h1 class="text-xl font-bold">Specialties</h1>
</div>

<div class="p-6 bg-white min-h-screen">
	<div class="overflow-x-auto">
		<table class="table w-full">
			<thead class="bg-gray-200 rounded-lg">
				<tr class="rounded-lg border-b-0">
					<th class="rounded-l-lg">Specialty</th>
					<th>Specialty Code</th>
					<th>Taxonomy Codes</th>
					<th class="rounded-r-lg">CPT Codes</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let specialty of sortedSpecialties">
					<td>
						<a [routerLink]="['/specialties', specialty.specialtyId]">
							{{ specialty.name }}
						</a>
					</td>
					<td>{{ specialty.code }}</td>
					<td>{{ specialty.taxonomyCodeCount }}</td>
					<td>{{ specialty.cptCodeCount }}</td>
				</tr>
				<tr>
					<td>
						<input type="text" class="input input-bordered w-64 bg-white" placeholder="Specialty name" [(ngModel)]="name" />
					</td>
					<td colspan="2">
						<input type="text" class="input input-bordered w-64 bg-white" placeholder="Specialty code" [(ngModel)]="code" />
					</td>
					<td>
						<button class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100" (click)="didClickCreateSpecialty()">Create</button>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
