export class PayerMapping {

	public isOpen = false

	public get isMapped(): boolean {
		return this.relatedEntityPayerId != null && this.entityIdentifierCode != null
	}

	public get shortRelatedEntityId(): string|null {
		return this.relatedEntityId?.substring(0, 8) || null
	}

	public selectedRelatedEntityPayerId: string|null = this.relatedEntityPayerId || null
	public selectedEntityIdentifierCode: string|null = this.entityIdentifierCode || null

	public selectPayer = false

	public isEditing = false

	constructor(
		public id: number,
		public relatedEntityId: string|null,
		public payerId: string,
		public payerName: string|null,
		public relatedEntityPayerId: string|null,
		public relatedEntityPayerName: string|null,
		public entityIdentifierCode: string|null,
		public data: string|null
	) {}

	static fromJson(data: any): PayerMapping {
		return new PayerMapping(
			data.id,
			data.relatedEntityId,
			data.payerId,
			data.payerName,
			data.relatedEntityPayerId,
			data.relatedEntityPayerName,
			data.entityIdentifierCode,
			data.data || null)
	}
}
