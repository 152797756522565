import { Component } from '@angular/core';
import { AdminService } from "../@shared/services/admin.service"
import { Customer } from "../@shared/models/customer"
import { Title } from "@angular/platform-browser"
import { ActivatedRoute } from '@angular/router'
import { STATES } from "../@shared/constants/data"

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html'
})
export class CustomerComponent {

  customer: Customer|null = null
  customerId: string
  users: any[] = []
  providers: any[] = []
  payers: any[] = []
  states = STATES
  payerId = ""
  state = ""
  npi = null

  constructor(
    private adminService: AdminService,
    private route: ActivatedRoute,
    private titleService: Title
  ) {}

  async ngOnInit() {
    this.titleService.setTitle("Customer")
    this.customerId = this.route.snapshot.paramMap.get("id")
    this.getCustomer()
    this.getPayers()
  }

  async getPayers() {
    this.payers = await this.adminService.getPayers()
  }

  async getCustomer() {
    const res = await this.adminService.getCustomer(this.customerId)
    this.customer = res.customer
    this.users = res.users
    this.providers = res.providers
  }

  async didClickEnableProvider(id: number) {
    const customerId = this.customerId

    try {
      await this.adminService.updateCustomerProvider(customerId, id)

      this.getCustomer()
    } catch (error: any) {
      alert("Something went wrong")
      console.log(error)
    }
  }

  async didClickAddProvider() {
    const customerId = this.customerId
    const payerId = this.payerId
    const state = this.state
    const npi = this.npi

    if (payerId == "") {
      alert("Select a Payer")
      return
    } else if (state == "") {
      alert("Select a state")
      return
    } else if (npi == undefined ||
      npi == null ||
      npi == "") {
      alert("Enter an NPI")
      return
    }

    try {
      await this.adminService.createCustomerProvider(
        customerId,
        payerId,
        state,
        npi)

      this.payerId = ""
      this.state = ""
      this.npi = ""

      this.getCustomer()
    } catch (error: any) {
      alert("Something went wrong")
      console.log(error)
    }
  }

  async didClickResetUserPassword(user: any) {
    const customerId = this.customerId
    const cognitoId = user.cognitoId

    const confirmed = confirm(`Are you sure you want to reset the password for ${user.emailAddress}?`)

    if (!confirmed) {
      return
    }

    try {
      await this.adminService.resetUserPassword(
        customerId,
        cognitoId)

      alert("Password reset")
    } catch (error: any) {
      alert("Something went wrong")
      console.log(error)
    }
  }
}
