import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AdminService } from "../@shared/services/admin.service"
import { Router } from '@angular/router';
import { Title } from "@angular/platform-browser"

@Component({
  selector: 'app-create-payer',
  templateUrl: './create-payer.component.html'
})
export class CreatePayerComponent {

  form: UntypedFormGroup

  constructor(
    private adminService: AdminService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private titleService: Title
  ) {}

  async ngOnInit() {
    this.titleService.setTitle("Create Payer")

    this.form = this.formBuilder.group({
      payerName: ["", []],
      changeHealthcarePayerId: ["", []],
      standardPayerId: ["", []]
    })
  }

  didPastePayerName(event: ClipboardEvent) {
    const payerName = event.clipboardData?.getData('text') || '';

    if (payerName == null ||
      payerName == undefined) {
      return
    }

    const formattedPayerName = this.formatPayerName(payerName)

    if (payerName == formattedPayerName) {
      return
    }

    event.preventDefault()

    this.form.controls["payerName"].setValue(formattedPayerName)
  }

  formatPayerName(payerName: string): string {
    if (payerName != payerName.toUpperCase()) {
      return payerName
    }

    return payerName
      .toLowerCase() // Convert the entire string to lowercase
      .split(' ') // Split the string into words by spaces
      .map(word =>
        word
          .split('-') // Split hyphenated words
          .map(part => part.charAt(0).toUpperCase() + part.slice(1)) // Capitalize each part of a hyphenated word
          .join('-') // Rejoin the parts with a hyphen
      )
      .join(' '); // Rejoin the words with spaces
  }

  async didClickCreatePayer() {
    const payerName = this.form.value.payerName
    const changeHealthcarePayerId = this.form.value.changeHealthcarePayerId
    const standardPayerId = this.form.value.standardPayerId

    if (payerName == null ||
      payerName == undefined) {
      return
    }

    try {
      await this.adminService.createPayer(payerName, changeHealthcarePayerId, standardPayerId)
      alert("Payer created")
      this.router.navigate(["/payers"])
    } catch (error) {
      alert(error)
    }
  }
}
