import { Component } from '@angular/core';
import { AdminService } from "../@shared/services/admin.service"
import { Payer } from "../@shared/models/payer"
import { Title } from "@angular/platform-browser"

@Component({
  selector: 'app-payers',
  templateUrl: './payers.component.html'
})
export class PayersComponent {

  payers: Payer[] = []

  isLoading = false

  searchValue = ""

  constructor(
    private adminService: AdminService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Payers - Payer Intelligence")
    this.getPayers()
  }

  get filteredPayers(): Payer[] {
    const searchValue = this.searchValue.toUpperCase()

    return this.payers.filter((payer) => {
      return searchValue === "" ||
        payer.payerName.toUpperCase().includes(searchValue) ||
        payer.payerId.toUpperCase().includes(searchValue) ||
        (payer.standardPayerId || "").toUpperCase().includes(searchValue)
    })
  }

  async getPayers() {
    this.isLoading = true
    this.payers = []
    this.payers = await this.adminService.getPayers()
    this.isLoading = false
  }

  async submitPayer(payer: Payer) {
    if (payer.newStandardPayerId === null ||
      payer.newStandardPayerId === undefined ||
      payer.newStandardPayerId === "") {
      alert("Enter a payer ID")
      return
    }

    try {
      await this.adminService.createAliasPayerID(payer.payerId, payer.newStandardPayerId, true)

      payer.standardPayerId = payer.newStandardPayerId

      payer.newStandardPayerId = null
    } catch (error: any) {
      alert("Something went wrong - this payer ID may already be in use")
    }
  }
}
