<div class="header">
	<h1>{{ specialty?.name }}</h1>
</div>
<ul class="tabs">
	<li [class.active]="tab == 'main'"><a (click)="navigateToTab('main')">Specialty Information</a></li>
	<li [class.active]="tab == 'models'"><a (click)="navigateToTab('models')">Models</a></li>
	<li [class.active]="tab == 'codes'"><a (click)="navigateToTab('codes')">CPT Codes</a></li>
	<li [class.active]="tab == 'taxonomyCodes'"><a (click)="navigateToTab('taxonomyCodes')">Taxonomy Codes</a></li>
</ul>
<div *ngIf="specialty">
	<div *ngIf="tab == 'main'">
		<table>
			<thead>
				<td>ID</td>
				<td>Name</td>
				<td>Code</td>
			</thead>
			<tbody>
				<tr>
					<td>
						<a [routerLink]="['/specialties', specialty.specialtyId]">
							{{ specialty.specialtyId }}
						</a>
					</td>
					<td>{{ specialty.name }}</td>
					<td>{{ specialty.code }}</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div *ngIf="tab == 'models'">
		<table>
			<thead>
				<td>ID</td>
				<td>Place of Service Code</td>
				<td>Total</td>
				<td>Total Copay</td>
				<td>Total Coinsurance</td>
				<td>Created</td>
				<td></td>
			</thead>
			<tbody>
				<tr *ngFor="let model of models">
					<td>{{ model.modelId }}</td>
					<td>{{ model.placeOfServiceCode }}</td>
					<td>{{ model.total | number }}</td>
					<td>{{ model.totalCopay | number }}</td>
					<td>{{ model.totalCoinsurance | number }}</td>
					<td>{{ model.created | date }}</td>
					<td>
						<a class="button" (click)="didClickUpdateModel(model)">{{ model.status == 0 ? "Enable" : "Disable" }}</a>
						<a
							class="button"
							[ngClass]="{'disabled': model.status === 1}"
							[routerLink]="['/specialties', specialty?.specialtyId, 'models', model.modelId]"
						>
							Payer config
						</a>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div *ngIf="tab == 'codes'">
		<table>
			<thead>
				<td>CPT Code</td>
				<td></td>
			</thead>
			<tbody>
				<tr *ngFor="let code of codes">
					<td>{{ code.code }}</td>
					<td></td>
				</tr>
				<tr>
					<td>
						<input type="text" placeholder="CPT Code" [(ngModel)]="cptCode" />
					</td>
					<td>
						<a class="button" (click)="didClickAddCode()">Submit</a>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div *ngIf="tab == 'taxonomyCodes'">
		<table>
			<thead>
				<td>Taxonomy Code</td>
			</thead>
			<tbody>
				<tr *ngFor="let taxonomyCode of taxonomyCodes">
					<td>{{ taxonomyCode }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>