import { Component, OnDestroy } from '@angular/core'
import { AuthService } from "../@shared/services/auth.service"
import { Verification } from '../@shared/models/verification'
import { AdminService } from '../@shared/services/admin.service'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { TabManagerService } from '../@shared/services/tab-manager.service'
import { Payer } from '../@shared/models/payer'
import { AlertService } from "../@shared/services/alert.service"

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html'
})
export class VerificationComponent extends TabManagerService implements OnDestroy {

  verification: Verification | null = null
  adminData = null
  payers: Payer[] = []

  isExternalUser = false

  recreateVerificationUrl: string = null

  isLoading = false

  verificationId: string|null = null

  alertText: string|null = null

  constructor(
    private adminService: AdminService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService
  ) {
    super(router, 'detail')
  }

  async ngOnInit() {
    this.isExternalUser = await this.authService.hasGroup("external")

    this.verificationId = this.route.snapshot.paramMap.get("id") as string

    [this.verification, this.adminData, this.payers] = await Promise.all([
      this.adminService.getVerification(this.verificationId),
      this.adminService.getData(),
      this.adminService.getPayers()
    ])

    this.recreateVerificationUrl = `https://app.soharhealth.com/create-patient?firstName=${this.verification.firstName}&lastName=${this.verification.lastName}&dateOfBirth=${this.verification.dateOfBirth}&state=${this.verification.state}&memberId=${this.verification.memberId}&payerId=${this.verification.payerId}&npi=${this.verification.npi}&taxonomyCode=${this.verification.taxonomyCode}&placeOfServiceCode=${this.verification.placeOfServiceCode}`
  }

  ngOnDestroy() {
    this.destroy()
  }

  async didClickRetryVerification() {
    const verificationId = this.verification.verificationId

    if (verificationId === null ||
      verificationId === undefined ||
      this.isLoading === true) {
      return
    }

    this.isLoading = true

    try {
      const res = await this.adminService.retryPendingVerification(verificationId)

      await new Promise(resolve => setTimeout(resolve, 500))

      this.verification = await this.adminService.getVerification(this.verificationId)

      this.alertService.publish("Verification retried", "success")
    } catch (error: any) {
      this.alertService.publish("Something went wrong", "error")
    } finally {
      this.isLoading = false
    }
  }
}
