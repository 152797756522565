<div class="fixed top-0 left-[260px] w-[calc(100%-260px)] bg-white border-b border-gray-300 p-6 h-[80px] flex items-center justify-between z-10">
	<h1 class="text-xl font-bold">Payers</h1>
	<div class="flex">
		<a class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100 ml-4" [routerLink]="['/create-payer']">
			<svg class="h-[14px]" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 512 512" xml:space="preserve"><g><path d="M480,224H288V32c0-17.673-14.327-32-32-32s-32,14.327-32,32v192H32c-17.673,0-32,14.327-32,32s14.327,32,32,32h192v192   c0,17.673,14.327,32,32,32s32-14.327,32-32V288h192c17.673,0,32-14.327,32-32S497.673,224,480,224z"/></g></svg>
			Create Payer
		</a>
	</div>
</div>

<div class="p-6 bg-white min-h-[calc(100vh-80px)]">

	<div class="flex justify-between items-center mb-4">

		<div class="flex gap-4">
			<input type="text" [(ngModel)]="searchValue" placeholder="Search payers..." class="input input-bordered w-64 bg-white" />

			<a class="btn bg-white flex items-center hover:bg-gray-100" *ngIf="searchValue" (click)="searchValue = ''">Clear</a>
		</div>

		<span class="text-sm text-gray-600">Showing {{ filteredPayers.length | number }} of {{ payers.length | number }}</span>
	</div>

	<div class="overflow-x-auto">
		<table class="table w-full">
			<thead class="bg-gray-200 rounded-lg">
				<tr class="rounded-lg border-b-0">
					<th class="rounded-l-lg">Payer</th>
					<th>Sohar ID</th>
					<th>Payer ID</th>
					<th>Type</th>
					<th>Discovery</th>
					<th>Clearing House</th>
					<th>Discovery Clearing House</th>
					<th class="rounded-r-lg">Public</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngIf="isLoading" class="border-b-0">
					<td><div class="skeleton h-6 w-full"></div></td>
					<td><div class="skeleton h-6 w-full"></div></td>
					<td><div class="skeleton h-6 w-full"></div></td>
					<td><div class="skeleton h-6 w-full"></div></td>
					<td><div class="skeleton h-6 w-full"></div></td>
					<td><div class="skeleton h-6 w-full"></div></td>
					<td><div class="skeleton h-6 w-full"></div></td>
					<td><div class="skeleton h-6 w-full"></div></td>
				</tr>
				<tr *ngFor="let payer of filteredPayers">
					<td>
						<a [routerLink]="['/payers', payer.payerId]" class="font-bold">{{ payer.payerName }}</a>
					</td>
					<td>{{ payer.payerId }}</td>
					<td>
						<span *ngIf="payer.standardPayerId !== null">{{ payer.standardPayerId }}</span>
						<div *ngIf="payer.standardPayerId === null" class="flex gap-2 items-center">
							<input type="text" placeholder="Standard Payer ID" class="input input-bordered w-64 bg-white" [(ngModel)]="payer.newStandardPayerId" />
							<button class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100" (click)="submitPayer(payer)">Submit</button>
						</div>
					</td>
					<td>{{ payer.payerType }}</td>
					<td>{{ payer.discovery == true ? "Yes" : "No" }}</td>
					<td>{{ payer.clearingHouse }}</td>
					<td>{{ payer.discoveryClearingHouse }}</td>
					<td>{{ payer.isCustomerPayer ? "Yes" : "No" }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
