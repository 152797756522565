import { Component, Input, Output, EventEmitter } from '@angular/core'
import { PayerMapping } from "../../models/payer-mapping"
import { Payer } from "../../models/payer"
import { PayerIntelligenceService } from "../../services/payer-intelligence.service"
import { ENTITY_IDENTIFIERS } from "../../constants/data"
import { AuthService } from "../../services/auth.service"

@Component({
  selector: 'payer-mapping-rules',
  templateUrl: './payer-mapping-rules.component.html'
})
export class PayerMappingRulesComponent {

  @Input() verificationId: string|null|undefined = null

  @Input() rules: PayerMapping[] = []

  @Input() payers: Payer[] = []

  @Output() updateRule = new EventEmitter<number>()

  @Output() getRules = new EventEmitter()

  isExternalUser = false

  entityIdentifiers = ENTITY_IDENTIFIERS

  relatedEntityPayerId = ""
  entityIdentifierCode = ""

  constructor(
    private payerIntelligenceService: PayerIntelligenceService,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    this.isExternalUser = await this.authService.hasGroup("external")
  }

  async didClickOpen(rule: PayerMapping) {
    if (rule.isOpen) {
      rule.isOpen = false
      return
    }

    rule.isOpen = true

    if (rule.data != null) {
      return
    }

    this.updateRule.emit(rule.id)
  }

  async didClickSubmit(rule: PayerMapping) {
    if (rule.selectedRelatedEntityPayerId == null ||
      rule.selectedRelatedEntityPayerId == "") {
      alert("Select a payer")
      return
    } else if (rule.selectedEntityIdentifierCode == null ||
      rule.selectedEntityIdentifierCode == undefined ||
      rule.selectedEntityIdentifierCode == "") {
      alert("Select an entity identifier code")
      return
    }

    try {
      await this.payerIntelligenceService.updatePayerMappingRule(rule.id, rule.selectedRelatedEntityPayerId, rule.selectedEntityIdentifierCode)
      this.updateRule.emit(rule.id)
    } catch (error) {
      alert("Something went wrong")
    }
  }

  parseJSON(data: string): any {
    try {
      return JSON.parse(data);
    } catch (e) {
      console.error('Invalid JSON string:', data);
      return data; // Return the original string if parsing fails
    }
  }

  async didClickCreateRelatedEntity() {

    if (this.verificationId == null ||
      this.verificationId == undefined) {
      return
    }

    if (this.relatedEntityPayerId == null ||
      this.relatedEntityPayerId == undefined ||
      this.relatedEntityPayerId == "") {
      alert("Select a related entity")
      return
    } else if (this.entityIdentifierCode == null ||
      this.entityIdentifierCode == undefined ||
      this.entityIdentifierCode == "") {
      alert("Select an entity identifier code")
      return
    }

    try {
      await this.payerIntelligenceService.createRelatedEntity(
        this.verificationId,
        this.relatedEntityPayerId,
        this.entityIdentifierCode)

      this.getRules.emit()
    } catch (error) {
      alert("Something went wrong")
    } finally {
      this.relatedEntityPayerId = ""
      this.entityIdentifierCode = ""
    }
  }
}
