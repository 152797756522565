import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AdminService } from "../@shared/services/admin.service"
import { Title } from "@angular/platform-browser"

@Component({
  selector: 'app-create-customer',
  templateUrl: './create-customer.component.html'
})
export class CreateCustomerComponent {

  form: UntypedFormGroup
  emailAddress: string
  password: string
  productionApiKey: string
  sandboxApiKey: string

  constructor(
    private adminService: AdminService,
    private formBuilder: UntypedFormBuilder,
    private titleService: Title
  ) {}

  async ngOnInit() {
    this.titleService.setTitle("Create Customer")

    this.form = this.formBuilder.group({
      emailAddress: [null, []],
      customerName: [null, []],
      maxProductionVerificationCount: []
    })
  }

  async didClickCreateSubmit() {
    const formEmailAddress = this.form.value.emailAddress
    const customerName = this.form.value.customerName
    const maxProductionVerificationCount = this.form.value.maxProductionVerificationCount

    if (!formEmailAddress ||
      !customerName) {
      return
    }

    try {
      const {
        emailAddress,
        password,
        productionApiKey,
        sandboxApiKey
      } = await this.adminService.createCustomer(
        formEmailAddress,
        customerName,
        maxProductionVerificationCount
      )

      this.emailAddress = emailAddress
      this.password = password
      this.productionApiKey = productionApiKey
      this.sandboxApiKey = sandboxApiKey
    } catch (error) {
      alert("Something went wrong")
    }
  }
}
