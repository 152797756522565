<div class="fixed top-0 left-[260px] w-[calc(100%-260px)] bg-white border-b border-gray-300 p-6 h-[80px] flex items-center justify-between z-10">
	<h1 class="text-xl font-bold">Discovery</h1>
</div>

<div class="grid grid-cols-1 md:grid-cols-4 gap-6 p-6">
	<div class="stats border bg-white">
		<div class="stat">
			<div class="stat-title">Requests</div>
			<div class="stat-value mt-2 mb-2">{{ filteredDiscoveryRequests.length | number }}</div>
			<div class="stat-desc">{{ completePercentage | percent }} Complete</div>
		</div>
	</div>
	<div class="stats border bg-white">
		<div class="stat">
			<div class="stat-title">Pending</div>
			<div class="stat-value mt-2 mb-2">{{ pendingCount | number }}</div>
			<div class="stat-desc">{{ pendingPercentage | percent }}</div>
		</div>
	</div>
	<div class="stats border bg-white">
		<div class="stat">
			<div class="stat-title">Successful</div>
			<div class="stat-value mt-2 mb-2">{{ successfulCount | number }}</div>
			<div class="stat-desc">{{ successfulPercentage | percent }}</div>
		</div>
	</div>
	<div class="stats border bg-white">
		<div class="stat">
			<div class="stat-title">P90</div>
			<div class="stat-value mt-2 mb-2">{{ P90 | number }}</div>
			<div class="stat-desc">P50 {{ P50 | number }}</div>
		</div>
	</div>
</div>

<div class="p-6 border rounded-lg m-6 bg-white">
	<div class="flex justify-between items-center mb-4">
		<div class="flex gap-4">
			<select class="select select-bordered bg-white min-w-[180px]" [(ngModel)]="filterTimestamp" (change)="didChangeHoursAgoSelection($event)">
				<option value="">
					All Time
				</option>
				<option value="168-hours">
					Past 7 Days
				</option>
				<option value="24-hours">
					Past 24 Hours
				</option>
				<option value="12-hours">
					Past 12 Hours
				</option>
				<option value="1-hour">
					Past Hour
				</option>
			</select>

			<select class="select select-bordered bg-white" [(ngModel)]="filterStatus">
				<option value="">
					All Statuses
				</option>
				<option [value]="0">
					Created
				</option>
				<option [value]="3">
					In Progress
				</option>
				<option [value]="1">
					Pending
				</option>
				<option [value]="2">
					Complete
				</option>
				<option [value]="5">
					Error
				</option>
			</select>

			<select class="select select-bordered bg-white" [(ngModel)]="filterCustomerId">
				<option value="">
					All Customers
				</option>
				<option *ngFor="let customer of customers" [value]="customer.customerId">
					{{ customer.name }}
				</option>
			</select>
		</div>
	</div>

	<div class="overflow-x-auto">
		<table class="table w-full min-h-940">
			<thead class="bg-gray-200 rounded-lg">
				<tr class="rounded-lg border-b-0">
					<th class="rounded-l-lg">Customer</th>
					<th>Patient</th>
					<th>State</th>
					<th>Payer</th>
					<th>Requests</th>
					<th>Status</th>
					<th>Created</th>
					<th>Latency</th>
					<th class="rounded-r-lg"></th>
				</tr>
			</thead>
			<tbody>
				<tr *ngIf="isLoading === true" class="border-b-0">
					<td><div class="skeleton h-6 w-full"></div></td>
					<td><div class="skeleton h-6 w-full"></div></td>
					<td><div class="skeleton h-6 w-full"></div></td>
					<td><div class="skeleton h-6 w-full"></div></td>
					<td><div class="skeleton h-6 w-full"></div></td>
					<td><div class="skeleton h-6 w-full"></div></td>
					<td><div class="skeleton h-6 w-full"></div></td>
					<td><div class="skeleton h-6 w-full"></div></td>
					<td><div class="skeleton h-6 w-full"></div></td>
				</tr>
				<tr *ngIf="isLoading !== true && filteredDiscoveryRequests.length === 0" class="border-b-0">
					<td colspan="8">
						<p class="text-center text-sm text-gray-500 h-[48px] leading-[48px]">No results found</p>
					</td>
				</tr>
				<tr *ngFor="let discoveryRequest of filteredDiscoveryRequests">
					<td>{{ discoveryRequest.name }}</td>
					<td>{{ discoveryRequest.firstName }} {{ discoveryRequest.lastName }}</td>
					<td>{{ discoveryRequest.state }}</td>
					<td>{{ discoveryRequest.payerName }}</td>
					<td>{{ discoveryRequest.count }}</td>
					<td>
						<span class="badge badge-outline text-sm py-3"
							[ngClass]="{
								'badge-success': discoveryRequest.status === 2,
								'badge-neutral': discoveryRequest.status === 0 || discoveryRequest.status === 3,
								'badge-warning': discoveryRequest.status === 1,
								'badge-error': discoveryRequest.status === 4
							}">
							{{ statusMap[discoveryRequest.status] }}
						</span>
					</td>
					<td>{{ discoveryRequest.timestamp | date: 'MMM dd, yyyy, HH:mm:ss' }}</td>
					<td>{{ discoveryRequest.seconds }}</td>
					<td>
						<a class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100" [routerLink]="['/discovery', discoveryRequest.discoveryId]">Detail</a>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
