import { Component } from '@angular/core';
import { AdminService } from "../@shared/services/admin.service"
import { PendingPayer } from "../@shared/models/payer"
import { Title } from "@angular/platform-browser"

@Component({
  selector: 'app-payers-pending',
  templateUrl: './payers-pending.component.html'
})
export class PayersPendingComponent {

  payers: PendingPayer[] = []

  constructor(
    private adminService: AdminService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Pending Payers - Payer Intelligence")
    this.getPendingPayers()
  }

  async getPendingPayers() {
    this.payers = await this.adminService.getPendingPayers()
  }

  async submitPayer(id: string, standardPayerId: string) {
    await this.adminService.createAliasPayerID(id, standardPayerId, true)
    this.getPendingPayers()
  }
}
