<div class="fixed top-0 left-[260px] w-[calc(100%-260px)] bg-white border-b border-gray-300 p-6 h-[80px] flex items-center justify-between z-10">
	<div class="flex">
		<div class="filters-button" [class.open]="isFilterMenuOpen">
			<a class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100 h-[48px] leading-[46px] pl-6 pr-6" (click)="isFilterMenuOpen = !isFilterMenuOpen">Filter</a>
			<div class="dropdowns-container bg-white shadow mt-4 rounded-lg p-4 w-[360px] max-h-[440px] overflow-scroll border">
				<div *ngFor="let filter of filtersArr">
					<!-- Single status selector -->
					<ng-container *ngIf="filter.value == 'status'">
						<h3 class="text-md font-bold mb-2">{{ filter.name }}</h3>
						<ul>
							<li class="text-sm mb-2" *ngFor="let filterValue of filterValues[filter.value] || []" (click)="didSelectFilter(filter.value, filterValue.value)">
								<input class="mr-2" type="checkbox" [checked]="filters[filter.value] == filterValue.value" /> {{ filterValue.name }}
							</li>
						</ul>
					</ng-container>
					<!-- Multiple selector for other filters -->
					<ng-container *ngIf="filter.value != 'status'">
						<h3 class="text-md font-bold mt-4 mb-2">{{ filter.name }}</h3>
						<ul>
							<li class="text-sm mb-2" (click)="toggleSelectAll(filter.value)" >
								<input class="mr-2" type="checkbox" [checked]="isAllSelected(filter.value)"/> Select All
							</li>
							<li class="text-sm mb-2" *ngFor="let filterValue of filterValues[filter.value] || []" (click)="didSelectFilter(filter.value, filterValue.value)">
								<input class="mr-2" type="checkbox" [checked]="filters[filter.value] != undefined && filters[filter.value].includes(filterValue.value)" /> {{ filterValue.name }}
							</li>
						</ul>
					</ng-container>
				</div>
			</div>
		</div>

		<div class="flex gap-2 ml-2">

			<div *ngIf="filters['payerName'] && filters['payerName'].length" class="flex gap-2 items-center">
				<span *ngFor="let item of filters['payerName']" class="bg-gray-100 rounded-lg items-center flex gap-2 px-4 py-2 text-sm font-semibold">
					<span>{{ item }}</span>
					<a (click)="didSelectFilter('payerName', item)">
						<svg class="h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M14.121,12,18,8.117A1.5,1.5,0,0,0,15.883,6L12,9.879,8.11,5.988A1.5,1.5,0,1,0,5.988,8.11L9.879,12,6,15.882A1.5,1.5,0,1,0,8.118,18L12,14.121,15.878,18A1.5,1.5,0,0,0,18,15.878Z"/></svg>
					</a>
				</span>
			</div>

			<div *ngIf="filters['customerName'] && filters['customerName'].length" class="flex gap-2 items-center">
				<span *ngFor="let item of filters['customerName']" class="bg-gray-100 rounded-lg items-center flex gap-2 px-4 py-2 text-sm font-semibold">
					<span>{{ item }}</span>
					<a (click)="didSelectFilter('customerName', item)">
						<svg class="h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M14.121,12,18,8.117A1.5,1.5,0,0,0,15.883,6L12,9.879,8.11,5.988A1.5,1.5,0,1,0,5.988,8.11L9.879,12,6,15.882A1.5,1.5,0,1,0,8.118,18L12,14.121,15.878,18A1.5,1.5,0,0,0,18,15.878Z"/></svg>
					</a>
				</span>
			</div>

		</div>

		<select class="select select-bordered bg-white ml-6" *ngIf="selectedVerifications().length > 0 && !isExternalUser" (change)="didChangeAssigneeSelection($event)">
			<option value="">Assign {{ selectedVerifications().length }} to</option>
			<option *ngFor="let adminUser of adminUsers" [value]="adminUser.adminCognitoId">{{ adminUser.firstName + " " + adminUser.lastName }}</option>
		</select>

		<select class="select select-bordered bg-white ml-6" *ngIf="selectedVerifications().length > 0 && !isExternalUser" (change)="didChangeStatusSelection($event)">
			<option value="">Set {{ selectedVerifications().length }} to</option>
			<option value="error.payer">error.payer</option>
		</select>

	</div>

	<div class="flex gap-4 items-center">
		<span class="badge badge-outline text-sm py-3"
			[ngClass]="{
				'badge-success': filters['status'].startsWith('complete'),
				'badge-neutral': filters['status'].startsWith('pending'),
				'badge-error': filters['status'].startsWith('error')
			}">
			{{ filters['status'] }}
		</span>

		<span class="text-sm text-gray-600">Showing {{ filteredVerifications.length | number }} of {{ verifications.length | number }}</span>
	</div>
</div>

<div class="p-6 bg-white min-h-[calc(100vh-80px)]">
	<div class="overflow-x-auto">
		<table class="table w-full">
			<thead class="bg-gray-200 rounded-lg">
				<tr class="rounded-lg border-b-0">
					<th class="rounded-l-lg pr-2" *ngIf="!isExternalUser">
						<a class="checkbox" (click)="didClickSelectAll()" [class.selected]="verifications.length > 0 && selectedVerifications().length > 0"></a>
					</th>
					<th class="pl-2 pr-2">Member ID</th>
					<th class="pl-2 pr-2">
						<a (click)="didClickSortBy('customerName')">
							Customer <span *ngIf="sortValues['customerName'] != undefined" class="arrow" [class.rotate]="sortValues['customerName'] == false"></span>
						</a>
					</th>
					<th class="pl-2 pr-2">Name</th>
					<th class="pl-2 pr-2">
						<a (click)="didClickSortBy('payerName')">
							Payer <span *ngIf="sortValues['payerName'] != undefined" class="arrow" [class.rotate]="sortValues['payerName'] == false"></span>
						</a>
					</th>
					<th class="pl-2 pr-2">
						<a (click)="didClickSortBy('status')">
							Status <span *ngIf="sortValues['status'] != undefined" class="arrow" [class.rotate]="sortValues['status'] == false"></span>
						</a>
					</th>
					<th class="pl-2 pr-2">
						<a (click)="didClickSortBy('timestamp')">
							Created <span *ngIf="sortValues['timestamp'] != undefined" class="arrow" [class.rotate]="sortValues['timestamp'] == false"></span>
						</a>
					</th>
					<th class="rounded-r-lg pl-2">
						<a (click)="didClickSortBy('adminFullName')">
							Assignee <span *ngIf="sortValues['adminFullName'] != undefined" class="arrow" [class.rotate]="sortValues['adminFullName'] == false"></span>
						</a>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngIf="isLoading" class="border-b-0">
					<td class="pr-2"><div class="skeleton h-6 w-full"></div></td>
					<td class="pl-2 pr-2"><div class="skeleton h-6 w-full"></div></td>
					<td class="pl-2 pr-2"><div class="skeleton h-6 w-full"></div></td>
					<td class="pl-2 pr-2"><div class="skeleton h-6 w-full"></div></td>
					<td class="pl-2 pr-2"><div class="skeleton h-6 w-full"></div></td>
					<td class="pl-2 pr-2"><div class="skeleton h-6 w-full"></div></td>
					<td class="pl-2 pr-2"><div class="skeleton h-6 w-full"></div></td>
					<td class="pl-2"><div class="skeleton h-6 w-full"></div></td>
				</tr>
				<tr *ngIf="isLoading !== true && filteredVerifications.length === 0" class="border-b-0">
					<td colspan="8">
						<p class="text-center text-sm text-gray-500 h-[48px] leading-[48px]">No results found</p>
					</td>
				</tr>
				<tr *ngFor="let verification of filteredVerifications">
					<td class="pr-2" *ngIf="!isExternalUser" (click)="$event.stopPropagation()">
						<a class="checkbox" (click)="verification.isSelected = !verification.isSelected" [class.selected]="verification.isSelected"></a>
					</td>
					<td class="pl-2 pr-2">
						<a class="font-bold" [routerLink]="['/verification', verification.verificationId]"
							[queryParams]="{status: filters['status'], payer: filters['payerName'], customer: filters['customerName']}">
							{{ verification.memberId }}
						</a>
					</td>
					<td class="pl-2 pr-2">
						<a class="font-bold" *ngIf="!isExternalUser" [routerLink]="['/customers', verification.customerId]">{{ verification.customerName }}</a>
						<span *ngIf="isExternalUser">{{ verification.customerName }}</span>
					</td>
					<td class="pl-2 pr-2">{{ verification.firstName }} {{ verification.lastName }}</td>
					<td class="pl-2 pr-2 group">
						<span *ngIf="!isExternalUser" class="flex items-center">
							<a [routerLink]="['/payers', verification.payerId]" class="font-bold">{{ verification.payerName }}</a>
							<div class="flex w-[30px] h-[30px]">
								<a class="btn btn-sm bg-white hover:bg-gray-100 ml-4 hidden group-hover:flex px-3" (click)="didSelectFilter('payerName', verification.payerName)">
									<svg class="h-[12px] text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="currentColor" d="M24,3.5c0,.83-.67,1.5-1.5,1.5H1.5c-.83,0-1.5-.67-1.5-1.5s.67-1.5,1.5-1.5H22.5c.83,0,1.5,.67,1.5,1.5ZM14.5,20h-5c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5h5c.83,0,1.5-.67,1.5-1.5s-.67-1.5-1.5-1.5Zm4-9H5.5c-.83,0-1.5,.67-1.5,1.5s.67,1.5,1.5,1.5h13c.83,0,1.5-.67,1.5-1.5s-.67-1.5-1.5-1.5Z"/></svg>
								</a>
							</div>
						</span>
						<span *ngIf="isExternalUser">{{ verification.payerName }}</span>
					</td>
					<td class="pl-2 pr-2">
						<span class="badge badge-outline text-sm py-3"
							[ngClass]="{
								'badge-success': verification.status.startsWith('complete'),
								'badge-neutral': verification.status.startsWith('pending'),
								'badge-error': verification.status.startsWith('error'),
								'badge-warning': verification.status == 'pending' || verification.status == 'pending.timeout' || verification.status == 'pending.payer.unavailable'
							}">
							{{ verification.status }}
						</span>
					</td>
					<td class="pl-2 pr-2 text-sm min-w-[160px]"[class.text-gray-600]="verification.serviceLevelAgreementWarning == false" [class.text-error]="verification.serviceLevelAgreementWarning == true">{{ verification.timestamp | date: 'MM/dd/yyyy, HH:mm' }}</td>
					<td class="pl-2">
						<div
							class="circle"
							[ngClass]="verification.adminInitials ? 'circle-initials' : 'circle-human-figure'"
							[ngStyle]="{'background-color': verification.adminInitials ? getAdminInitialsColor(verification.adminCognitoId) : ''}"
							[title]="verification.adminFullName">
							<span *ngIf="verification.adminInitials">{{ verification.adminInitials }}</span>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
