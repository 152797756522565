<div class="bg-bgblue h-screen flex align-center justify-center">
	<div class="w-[400px] my-auto mx-auto bg-white border rounded-lg p-6">
		<form [formGroup]="form" (ngSubmit)="onSubmit()">
			<input
				class="input input-bordered w-full bg-white"
				type="email"
				formControlName="email"
				placeholder="Email Address"
				autocomplete="username"
				required
				autofocus
				/>
			<input
				class="input input-bordered w-full bg-white mt-4"
				type="password"
				formControlName="password"
				placeholder="Password"
				autocomplete="current-password"
				required
				/>
			<button class="btn btn-accent text-white mt-8 w-full">Sign In</button>
		</form>
	</div>
</div>
