<div class="fixed top-0 left-[260px] w-[calc(100%-260px)] bg-white border-b border-gray-300 p-6 h-[80px] flex items-center justify-between z-10">
  <h1 class="text-xl font-bold">Team</h1>
</div>

<table>
	<thead>
		<td>Cognito Id</td>
		<td>Email</td>
		<td>First Name</td>
		<td>Last Name</td>
		<td>Active</td>
		<td>Groups</td>
	</thead>
	<tbody>
		<tr *ngFor="let admin of admins">
			<td>{{ admin.adminCognitoId }}</td>
			<td>{{ admin.email }}</td>
			<td>{{ admin.firstName }}</td>
			<td>{{ admin.lastName }}</td>
			<td>{{ admin.active }}</td>
			<td>{{ admin.groups }}</td>
		</tr>
		<tr>
			<td colspan="2">
				<input type="text" placeholder="Email" [(ngModel)]="newAdmin.email" />
			</td>
			<td>
				<input type="text" placeholder="First Name" [(ngModel)]="newAdmin.firstName" />
			</td>
			<td >
				<input type="text" placeholder="Last Name" [(ngModel)]="newAdmin.lastName" />
			</td>
			<td >
				<select [(ngModel)]="newAdmin.groups">
					<option *ngFor="let group of groups" [value]="group">{{ group }}</option>
				</select>
			</td>
			<td>
				<a class="button" (click)="didClickCreateAdmin()">Create</a>
			</td>
		</tr>
	</tbody>
</table>
