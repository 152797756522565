import { Component } from '@angular/core';
import { AuthService } from "../@shared/services/auth.service"
import { AdminService } from '../@shared/services/admin.service';
import { AlertService } from "../@shared/services/alert.service"
import { Alert } from "../@shared/models/alert"

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent {

  isExternalUser: boolean = false
  isAdmin: boolean = false
  version: string | null = null;
  alerts: Alert[] = []

  constructor(
    private authService: AuthService,
    private adminService: AdminService,
    private alertService: AlertService
  ) { }

  async ngOnInit() {
    this.isExternalUser = await this.authService.hasGroup("external")
    this.isAdmin = await this.authService.hasGroup("admin")
    this.version = await this.adminService.getVersion()

    this.alertService.subscribe(this)
  }

  ngOnDestroy() {
    this.alertService.unsubcribe(this)
  }

  handleAlerts(alerts: Alert[]) {
    this.alerts = alerts
  }
}
