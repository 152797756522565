import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PayerIntelligenceService } from "../@shared/services/payer-intelligence.service"
import { Title } from "@angular/platform-browser"
import { PayerMapping } from "../@shared/models/payer-mapping"
import { AdminService } from "../@shared/services/admin.service"
import { Payer } from "../@shared/models/payer"
import { getEntityIdentifierCode, getPayerId } from "../@shared/helpers/relatedEntities"

@Component({
  selector: 'app-payer-mapping',
  templateUrl: './payer-mapping.component.html'
})
export class PayerMappingComponent {

  rules: PayerMapping[] = []

  payers: Payer[] = []

  state: string = ""

  get filteredRules(): PayerMapping[] {
    return this.rules.filter((rule) => {
      if (this.state == "mapped") {
        return rule.relatedEntityPayerId != null && rule.entityIdentifierCode != null
      } else if (this.state == "pending") {
        return rule.relatedEntityPayerId == null || rule.entityIdentifierCode == null
      }

      return true
    })
  }

  constructor(
    private payerIntelligenceService: PayerIntelligenceService,
    private titleService: Title,
    private adminService: AdminService
  ) {}

  async ngOnInit() {
    this.titleService.setTitle("Mapping - Payer Intelligence")

    this.rules = await this.payerIntelligenceService.getPayerMappingRules()

    this.payers = await this.adminService.getPayers()
  }

  async getRuleForId(id: number) {
    const rule = await this.payerIntelligenceService.getPayerMappingRule(id)

    if (rule == null) {
      return
    }

    let index = -1

    for (let i = 0; i < this.rules.length; i++) {
      if (rule.id == this.rules[i].id) {
        index = i
        break
      }
    }

    const existingRule = this.rules[index]

    rule.isOpen = existingRule.relatedEntityPayerId == null && rule.relatedEntityPayerId != null ? false : existingRule.isOpen

    if (rule.relatedEntityPayerId == null &&
      rule.selectedRelatedEntityPayerId == null) {
      const selectedRelatedEntityPayerId = getPayerId(rule.data, rule.payerId, this.payers)

      if (selectedRelatedEntityPayerId != null) {
        rule.selectPayer = true
        rule.selectedRelatedEntityPayerId = selectedRelatedEntityPayerId
      }
    }

    if (rule.entityIdentifierCode == null &&
      rule.selectedEntityIdentifierCode == null) {
      rule.selectedEntityIdentifierCode = getEntityIdentifierCode(rule.data)
    }

    this.rules[index] = rule
  }
}
