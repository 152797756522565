import { PayerDefault } from  "./payer-default"
import { ConfigurationGroup } from "./configuration-group"

export class Payer {
	constructor(
		public payerId: string,
		public payerName: string,
		public changeHealthcarePayerId: string,
		public availityPayerId: string,
		public officeAllyPayerId: string,
		public phoneNumber: string,
		public standardPayerId: string,
		public defaults: PayerDefault[],
		public configurations: ConfigurationGroup[],
		public clearingHouseIdentifier: number,
		public aliases: any[],
		public states: string[],
		public discovery: boolean,
		public providerType: string,
		public payerType: string) {}

	static fromJson(data: any): Payer {
		return new Payer(
			data.payerId,
			data.payerName,
			data.changeHealthcarePayerId,
			data.availityPayerId,
			data.officeAllyPayerId,
			data.phoneNumber,
			data.standardPayerId,
			data.defaults ? data.defaults.map(d => PayerDefault.fromJson(d)) : [],
			data.configurations ? data.configurations.map(c => ConfigurationGroup.fromJson(c)) : [],
			data.clearingHouseIdentifier,
			data.aliases || [],
			data.states || [],
			data.discovery == 1,
			data.providerType,
			data.payerType)
	}

	get clearingHouse(): string {
		const clearingHouses = ["Change Healthcare", "Availity"]
		return clearingHouses[this.clearingHouseIdentifier]
	}
}
