<table class="table w-full">
	<thead class="bg-gray-200 rounded-lg">
		<tr class="rounded-lg border-b-0">
			<th class="rounded-l-lg"></th>
			<th>ID</th>
			<th>Payer</th>
			<th>Related Entity</th>
			<th>Entity Identifier Code</th>
			<th class="rounded-r-lg"></th>
		</tr>
	</thead>
	<tbody *ngFor="let rule of rules">
		<tr *ngIf="!isExternalUser">
			<td>
				<a *ngIf="rule.relatedEntityId" (click)="didClickOpen(rule)">
					<svg [ngClass]="{ 'rotate-90': rule.isOpen }" class="h-[24px] w-[24px]" xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="24" height="24"><g id="_01_align_center" data-name="01 align center"><path d="M10.811,18.707,9.4,17.293,14.689,12,9.4,6.707l1.415-1.414L16.1,10.586a2,2,0,0,1,0,2.828Z"/></g></svg>
				</a>
			</td>
			<td>
				{{ rule.shortRelatedEntityId || "-" }}
			</td>
			<td>
				<a [routerLink]="['/payers', rule.payerId]">
					{{ rule.payerName || "-" }}
				</a>
			</td>
			<td>
				<span *ngIf="rule.isMapped == true && rule.isEditing == false">
					<a [routerLink]="['/payers', rule.relatedEntityPayerId]">
						{{ rule.relatedEntityPayerName }}
					</a>
				</span>
				<a class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100" *ngIf="(rule.isMapped == false || rule.isEditing == true) && rule.selectPayer == false" (click)="rule.selectPayer = true">Select Payer</a>
				<select class="select select-bordered bg-white w-full" *ngIf="(rule.isMapped == false || rule.isEditing == true) && rule.selectPayer == true" [(ngModel)]="rule.selectedRelatedEntityPayerId">
					<option *ngFor="let payer of payers" [value]="payer.payerId">
						{{ payer.payerName }}
					</option>
				</select>
			</td>
			<td>
				<span *ngIf="rule.isMapped == true && rule.isEditing == false">
					{{ rule.entityIdentifierCode }}
				</span>
				<select class="select select-bordered bg-white w-full min-w-[300px]" *ngIf="rule.isMapped == false || rule.isEditing == true" [(ngModel)]="rule.selectedEntityIdentifierCode">
					<option *ngFor="let entityIdentifier of entityIdentifiers" [value]="entityIdentifier.code">
						{{ entityIdentifier.label }}
					</option>
				</select>
			</td>
			<td>
				<a class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100" *ngIf="rule.isMapped == false || rule.isEditing == true" (click)="didClickSubmit(rule)">Submit</a>
				<a class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100" *ngIf="rule.isMapped == true && rule.isEditing == false" (click)="didClickEdit(rule)">Edit</a>
			</td>
		</tr>
		<tr *ngIf="rule.isOpen" class="verification json-view">
			<td colspan="6">
				<pre class="expanded">{{ parseJSON(rule.data) | json }}</pre>
			</td>
		</tr>
	</tbody>
	<tbody *ngIf="verificationId">
		<tr class="border-b-0">
			<td>
			</td>
			<td>
			</td>
			<td>
				<select class="select select-bordered bg-white w-full" [(ngModel)]="relatedEntityPayerId">
					<option value="">
						Select a related entity
					</option>
					<option *ngFor="let payer of payers" [value]="payer.payerId">
						{{ payer.payerName }}
					</option>
				</select>
			</td>
			<td>
				<select class="select select-bordered bg-white" [(ngModel)]="entityIdentifierCode">
					<option value="">
						Select an entity identifier code
					</option>
					<option *ngFor="let entityIdentifier of entityIdentifiers" [value]="entityIdentifier.code">
						{{ entityIdentifier.label }}
					</option>
				</select>
			</td>
			<td>
				<a class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100" (click)="didClickCreateRelatedEntity()">Create</a>
			</td>
		</tr>
	</tbody>
</table>