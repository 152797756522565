<div class="header">
	<h1>Pending Payers</h1>
</div>
<div class="payers">
	<table>
		<thead>
			<td>Payer</td>
			<td>Sohar ID</td>
			<td>Payer ID</td>
			<td></td>
		</thead>
		<tbody *ngFor="let payer of payers">
			<tr>
				<td>
					<a [routerLink]="['/payers', payer.payerId]">{{ payer.payerName }}</a>
				</td>
				<td>{{ payer.payerId }}</td>
				<td>
					<input type="text" [(ngModel)]="payer.standardPayerId"  />
				</td>
				<td>
					<a class="button" (click)="submitPayer(payer.payerId, payer.standardPayerId)">Submit</a>
				</td>
			</tr>
		</tbody>
	</table>
</div>