<div class="flex gap-4" *ngIf="verification !== null">

	<div class="w-full" *ngIf="!verification.status.includes('pending')">
		<div class="card border rounded-lg bg-white">
			<div class="card-body p-6 grid grid-cols-1 md:grid-cols-2 gap-6">
				<div>
					<h2 class="card-title text-sm">Plan Name</h2>
					<p>{{ verification.planName }}</p>
				</div>
				<div>
					<h2 class="card-title text-sm">Insurance Type Code</h2>
					<p>{{ verification.networkType || "None" }}</p>
				</div>
			</div>
		</div>
		<div class="card border rounded-lg bg-white mt-4">
			<div class="card-body p-6 grid grid-cols-1 md:grid-cols-2 gap-6">
				<div>
					<h2 class="card-title text-sm">Start Date</h2>
					<p>{{ verification.coverageStartDate || "None" }}</p>
				</div>
				<div>
					<h2 class="card-title text-sm">End Date</h2>
					<p>{{ verification.coverageEndDate || "None" }}</p>
				</div>
			</div>
		</div>
		<div class="card border rounded-lg bg-white mt-4">
			<div class="card-body p-6 grid grid-cols-1 md:grid-cols-2 gap-6">
				<div>
					<h2 class="card-title text-sm">Group Name</h2>
					<p>{{ verification.groupName || "None" }}</p>
				</div>
				<div>
					<h2 class="card-title text-sm">Group Number</h2>
					<p>{{ verification.groupNumber || "None" }}</p>
				</div>
			</div>
		</div>
		<div class="card border rounded-lg bg-white mt-4">
			<div class="card-body p-6">
				<h2 class="card-title text-sm">Service Type Code</h2>
				<p>{{ verification.serviceTypeCode || "None" }}</p>
			</div>
		</div>

		<table class="table w-full mt-8">
			<thead class="bg-gray-200 rounded-lg">
				<tr class="rounded-lg border-b-0">
					<th class="rounded-l-lg">Network</th>
					<th>Tier</th>
					<th>Copay</th>
					<th>Coinsurance</th>
					<th>Deductible</th>
					<th>Out of Pocket</th>
					<th class="rounded-r-lg">Coverage Level Code</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let benefit of verification.benefits" class="section">
					<td>{{ benefit.networkStatus == "inn" ? "INN" : "OON" }}</td>
					<td>{{ benefit.tier }}</td>
					<td>{{ benefit.copay | currency }}</td>
					<td>{{ benefit.coinsurance | percent }}</td>
					<td>{{ benefit.deductible }}</td>
					<td>{{ benefit.oop }}</td>
					<td>{{ benefit.coverageLevelCode }}</td>
				</tr>
			</tbody>
		</table>
	</div>

	<div class="bg-white border rounded-lg p-6 w-full" *ngIf="verification.status.includes('pending')">
		<form [formGroup]="form" (ngSubmit)="didClickSubmit()">

			<h3 class="text-lg font-bold">Plan Information</h3>

			<div class="flex gap-4 mt-4">

				<label class="form-control w-full">
					<div class="label">
						<span class="label-text">Coverage Status</span>
					</div>
					<select class="select select-bordered bg-white" formControlName="status" required>
						<option *ngFor="let option of statusOptions" [value]="option.value">
							{{ option.text }}
						</option>
					</select>
				</label>

			</div>

			<div class="flex gap-4 mt-4">

				<label class="form-control w-full">
					<div class="label">
						<span class="label-text">Start Date</span>
					</div>
					<input type="text" class="input input-bordered w-full bg-white"  formControlName="coverageStartDate" placeholder="MM/DD/YYYY" required />
				</label>

				<label class="form-control w-full">
					<div class="label">
						<span class="label-text">End Date</span>
					</div>
					<input type="text" class="input input-bordered w-full bg-white"  formControlName="coverageEndDate" placeholder="MM/DD/YYYY" />
				</label>

			</div>

			<div class="flex gap-4 mt-4">

				<label class="form-control w-full">
					<div class="label">
						<span class="label-text">Plan Name</span>
					</div>
					<input type="text" class="input input-bordered w-full bg-white"  formControlName="planName" required />
				</label>

				<label class="form-control w-full">
					<div class="label">
						<span class="label-text">Insurance Type Code</span>
					</div>
					<select class="select select-bordered bg-white" formControlName="networkType" required>
						<option value="">Select Network Type</option>
						<option *ngFor="let networkType of networkTypes" [value]="networkType.networkType">
							{{ networkType.networkTypeName }}
						</option>
					</select>
				</label>

			</div>

			<h3 class="text-lg font-bold mt-8">In Network Benefits</h3>

			<div class="flex gap-4 mt-4">

				<label class="form-control w-full">
					<div class="label">
						<span class="label-text">Total Deductible</span>
					</div>
					<input type="number" class="input input-bordered w-full bg-white"  formControlName="innTotalDeductible" required />
				</label>

				<label class="form-control w-full">
					<div class="label">
						<span class="label-text">Remaining Deductible</span>
					</div>
					<input type="number" class="input input-bordered w-full bg-white"  formControlName="innRemainingDeductible" />
				</label>

			</div>

			<div class="flex gap-4 mt-4">

				<label class="form-control w-full">
					<div class="label">
						<span class="label-text">Out of Pocket Max</span>
					</div>
					<input type="number" class="input input-bordered w-full bg-white"  formControlName="innOutOfPocket" />
				</label>

				<label class="form-control w-full">
					<div class="label">
						<span class="label-text">Out of Pocket Remaining</span>
					</div>
					<input type="number" class="input input-bordered w-full bg-white"  formControlName="innOutOfPocketRemaining" />
				</label>

			</div>

			<div class="flex gap-4 mt-4">

				<label class="form-control w-full">
					<div class="label">
						<span class="label-text">Copay</span>
					</div>
					<input type="number" class="input input-bordered w-full bg-white"  formControlName="innCopay" required />
				</label>

				<label class="form-control w-full">
					<div class="label">
						<span class="label-text flex items-center">
							<span>Coinsurance</span>
							<div class="tooltip ml-2" data-tip="Please enter coinsurance value of the patient responsibility in a decimal value e.g. 20% = .2">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="h-4 w-4 shrink-0 stroke-current">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
								</svg>
							</div>
						</span>
					</div>
					<input type="number" class="input input-bordered w-full bg-white"  formControlName="innCoinsurance" required />
				</label>

			</div>

			<div class="flex items-center justify-between mt-8">
				<h3 class="text-lg font-bold">Out Of Network Benefits</h3>
				<a class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100" (click)="didClickOutOfNetworkBenefitsNotCovered()">Not Covered</a>
			</div>

			<div class="flex gap-4 mt-4">

				<label class="form-control w-full">
					<div class="label">
						<span class="label-text">Total Deductible</span>
					</div>
					<input type="number" class="input input-bordered w-full bg-white"  formControlName="oonTotalDeductible" />
				</label>

				<label class="form-control w-full">
					<div class="label">
						<span class="label-text">Remaining Deductible</span>
					</div>
					<input type="number" class="input input-bordered w-full bg-white"  formControlName="oonRemainingDeductible" />
				</label>

			</div>

			<div class="flex gap-4 mt-4">

				<label class="form-control w-full">
					<div class="label">
						<span class="label-text">Out of Pocket Max</span>
					</div>
					<input type="number" class="input input-bordered w-full bg-white"  formControlName="oonOutOfPocket" />
				</label>

				<label class="form-control w-full">
					<div class="label">
						<span class="label-text">Out of Pocket Remaining</span>
					</div>
					<input type="number" class="input input-bordered w-full bg-white"  formControlName="oonOutOfPocketRemaining" />
				</label>

			</div>

			<div class="flex gap-4 mt-4">

				<label class="form-control w-full">
					<div class="label">
						<span class="label-text">Copay</span>
					</div>
					<input type="number" class="input input-bordered w-full bg-white"  formControlName="oonCopay" />
				</label>

				<label class="form-control w-full">
					<div class="label">
						<span class="label-text flex items-center">
							<span>Coinsurance</span>
							<div class="tooltip ml-2" data-tip="Please enter coinsurance value of the patient responsibility in a decimal value e.g. 20% = .2">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="h-4 w-4 shrink-0 stroke-current">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
								</svg>
							</div>
						</span>
					</div>
					<input type="number" class="input input-bordered w-full bg-white"  formControlName="oonCoinsurance" />
				</label>

			</div>

			<h3 class="text-lg font-bold mt-8">Additional Information</h3>

			<div class="flex gap-4 mt-4">

				<label class="form-control w-full">
					<div class="label">
						<span class="label-text">Resolution Method</span>
					</div>
					<select class="select select-bordered bg-white" formControlName="resolutionMethod" required>
						<option value="database">Database</option>
						<option value="portal">Provider Portal</option>
						<option value="phone">Phone Call</option>
					</select>
				</label>

				<label class="form-control w-full" *ngIf="form.controls['status'].value === 'error.payer'">
					<div class="label">
						<span class="label-text">Payer Error</span>
					</div>
					<select class="select select-bordered bg-white" formControlName="payerError" required>
						<option *ngFor="let option of payerErrorOptions" [value]="option.value">
							{{ option.text }}
						</option>
					</select>
				</label>

			</div>

			<button class="btn btn-accent text-white mt-8 min-w-[180px]">Submit</button>
		</form>
	</div>

	<div class="pl-2 w-[400px] min-w-[400px]">

		<div class="card border rounded-lg bg-white">
			<div class="card-body p-6">
				<h2 class="card-title text-sm">Status</h2>
				<p>{{ verification.status }}</p>
				<div *ngIf="verification.status === 'error.payer'">
					<h2 class="card-title text-sm">Payer Error</h2>
					<p >{{ verification.payerError }}</p>
				</div>
				<h2 class="card-title text-sm mt-2">Created</h2>
				<p>{{ verification.timestamp | date: 'MM/dd/yyyy, HH:mm:ss' }}</p>
				<h2 class="card-title text-sm mt-2">Updated</h2>
				<p>{{ verification.updated | date: 'MM/dd/yyyy, HH:mm:ss' }}</p>
				<h2 class="card-title text-sm mt-2">Resolved</h2>
				<p>{{ verification.resolved | date: 'MM/dd/yyyy, HH:mm:ss' }}</p>
				<h2 class="card-title text-sm mt-2">Resolution Method</h2>
				<p>{{ verification.resolutionMethod || "None" }}</p>
				<h2 class="card-title text-sm mt-2">Assignee</h2>
				<p *ngIf="isExternalUser">{{ verification.adminFullName || "None" }}</p>
				<p *ngIf="!isExternalUser">
					<a (click)="didClickSelectAdminAssignee()">{{ verification.adminFullName || "None" }}</a>
				</p>
			</div>
		</div>

		<div class="card border rounded-lg bg-white mt-6">
			<div class="card-body p-6">
				<h2 class="card-title text-sm">Name</h2>
				<p>{{ verification.fullName }}</p>
				<h2 class="card-title text-sm mt-2">Member ID</h2>
				<p>{{ verification.memberId }}</p>
				<h2 class="card-title text-sm mt-2">Date of Birth</h2>
				<p>{{ verification.dateOfBirth }}</p>
				<h2 class="card-title text-sm mt-2">State</h2>
				<p>{{ verification.state }}</p>
				<h2 class="card-title text-sm mt-2">Address</h2>
				<p>{{ verification.patientAddress }}</p>
			</div>
		</div>

		<div class="card border rounded-lg bg-white mt-6">
			<div class="card-body p-6">
				<h2 class="card-title text-sm">Payer</h2>
				<p *ngIf="isExternalUser">{{ verification.payerName }}</p>
				<p *ngIf="!isExternalUser"><a [routerLink]="['/payers', verification.payerId]">{{ verification.payerName }}</a></p>
				<h2 class="card-title text-sm mt-2">Phone Number</h2>
				<p>{{ verification.phoneNumber || "None" }}</p>
			</div>
		</div>

		<div class="card border rounded-lg bg-white mt-6">
			<div class="card-body p-6">
				<h2 class="card-title text-sm">Customer</h2>
				<p>{{ verification.customerName }}</p>
				<h2 class="card-title text-sm mt-2">Provider</h2>
				<p>{{ verification.organizationName }}</p>
				<h2 class="card-title text-sm mt-2">NPI</h2>
				<p>{{ verification.npi }}</p>
				<h2 class="card-title text-sm mt-2">Specialty</h2>
				<p *ngIf="isExternalUser">{{ verification.specialtyName || "None" }}</p>
				<p *ngIf="!isExternalUser">
					<a [routerLink]="['/specialties', verification.specialtyId]">{{ verification.specialtyName || "None" }}</a>
				</p>
				<h2 class="card-title text-sm mt-2">Place of Service</h2>
				<p>{{ verification.placeOfServiceCode || "None" }}</p>
				<h2 class="card-title text-sm mt-2">Tax ID</h2>
				<p>{{ verification.taxId || "None" }}</p>
			</div>
		</div>
	</div>

</div>
