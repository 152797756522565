<div class="filters fixed">
	<div class="filters-button" [class.open]="isFilterMenuOpen">
		<a class="button" (click)="isFilterMenuOpen = !isFilterMenuOpen">Filter</a>
		<div class="dropdowns-container">
			<section class="dropdowns">
				<div *ngFor="let filter of filtersArr">
					<h3>{{ filter.name }}</h3>
					<ul>
						<li *ngFor="let filterValue of filterValues[filter.value] || []" (click)="didSelectFilter(filter.value, filterValue.value)">
							<input type="checkbox" [checked]="filters[filter.value] == filterValue.value" /> {{ filterValue.name }}
						</li>
					</ul>
				</div>
			</section>
		</div>
	</div>
	<div *ngFor="let filter of filtersArr" class="filter-value">
		<div *ngIf="filters[filter.value]">
			<span [class.filter-value-status]="filter.value == 'status'">
				{{ filter.name }}: {{ filters[filter.value] }}
			</span>
			<a *ngIf="filter.value != 'status'" (click)="didSelectFilter(filter.value, '')">X</a>
		</div>
	</div>
	<select *ngIf="selectedVerifications().length > 0 && !isExternalUser" (change)="didChangeAssigneeSelection($event)">
		<option value="">Assign {{ selectedVerifications().length }} to</option>
		<option *ngFor="let adminUser of adminUsers" [value]="adminUser.adminCognitoId">{{ adminUser.firstName + " " + adminUser.lastName }}</option>
	</select>
	<select *ngIf="selectedVerifications().length > 0 && !isExternalUser" (change)="didChangeStatusSelection($event)">
		<option value="">Set {{ selectedVerifications().length }} to</option>
		<option value="error.payer">error.payer</option>
	</select>
	<span class="total">Showing {{ filteredVerifications.length | number }} of {{ verifications.length | number }}</span>
</div>
<div class="top-padded">
	<table>
		<thead>
			<td *ngIf="!isExternalUser">
				<a class="checkbox" (click)="didClickSelectAll()" [class.selected]="verifications.length > 0 && selectedVerifications().length > 0"></a>
			</td>
			<td>Member ID</td>
			<td>Customer</td>
			<td>Name</td>
			<td>
				<a (click)="didClickSortBy('payerName')">
					Payer <span *ngIf="sortValues['payerName'] != undefined" class="arrow" [class.rotate]="sortValues['payerName'] == false"></span>
				</a>
			</td>
			<td>Status</td>
			<td>Related Entity</td>
			<td>
				<a (click)="didClickSortBy('timestamp')">
					Created <span *ngIf="sortValues['timestamp'] != undefined" class="arrow" [class.rotate]="sortValues['timestamp'] == false"></span>
				</a>
			</td>
			<td>Assignee</td>
		</thead>
		<tbody>
			<tr *ngFor="let verification of filteredVerifications">
				<td *ngIf="!isExternalUser" (click)="$event.stopPropagation()">
					<a class="checkbox" (click)="verification.isSelected = !verification.isSelected" [class.selected]="verification.isSelected"></a>
				</td>
				<td>
					<a [routerLink]="['/verification', verification.verificationId]"
						[queryParams]="{status: filters['status'], payer: filters['payerName'], customer: filters['customerName']}">
						{{ verification.memberId }}
					</a>
				</td>
				<td>
					<a *ngIf="!isExternalUser" [routerLink]="['/customers', verification.customerId]">{{ verification.customerName }}</a>
					<span *ngIf="isExternalUser">{{ verification.customerName }}</span>
				</td>
				<td>{{ verification.firstName }} {{ verification.lastName }}</td>
				<td>
					<a *ngIf="!isExternalUser" [routerLink]="['/payers', verification.payerId]">{{ verification.payerName }}</a>
					<span *ngIf="isExternalUser">{{ verification.payerName }}</span>
				</td>
				<td>{{ verification.status }}</td>
				<td>{{ verification.benefitsRelatedEntity.name }}</td>
				<td [class.warning]="verification.serviceLevelAgreementWarning">{{ verification.timestamp | date: 'MM/dd/yyyy, HH:mm:ss' }}</td>
				<td>
					<div
						class="circle"
						[ngClass]="verification.adminInitials ? 'circle-initials' : 'circle-human-figure'"
						[ngStyle]="{'background-color': verification.adminInitials ? getAdminInitialsColor(verification.adminCognitoId) : ''}"
						[title]="verification.adminFullName">
						<span *ngIf="verification.adminInitials">{{ verification.adminInitials }}</span>
					</div>
				</td>
			</tr>
		</tbody>
	</table>
</div>
<div class="loading-indicator" *ngIf="isLoading">Loading...</div>