<div class="header">
	<h1>{{ payer?.payerName }}</h1>
	<div>
		<a class="button" [routerLink]="['/payers', payer?.payerId, 'edit']">Edit</a>
		<a class="button" (click)="didClickRetryVerifications()">Retry Pending Verifications</a>
	</div>
</div>
<ul class="tabs">
	<li [class.active]="tab == 'main'"><a (click)="tab = 'main'">Payer Information</a></li>
	<li [class.active]="tab == 'payerIds'"><a (click)="tab = 'payerIds'">Payer IDs</a></li>
	<li [class.active]="tab == 'states'"><a (click)="tab = 'states'">States</a></li>
	<li [class.active]="tab == 'specialties'"><a (click)="tab = 'specialties'">Specialties</a></li>
	<li [class.active]="tab == 'relatedPayers'"><a (click)="tab = 'relatedPayers'">Related Payers</a></li>
</ul>
<div *ngIf="payer">
	<div *ngIf="tab == 'main'">
		<table>
			<thead>
				<td>Change ID</td>
				<td>Availity ID</td>
				<td>Office Ally ID</td>
				<td>Sohar ID</td>
				<td>Phone Number</td>
				<td>Type</td>
				<td>Clearing House</td>
				<td>Provider Type</td>
			</thead>
			<tbody>
				<tr>
					<td>{{ payer.changeHealthcarePayerId }}</td>
					<td>{{ payer.availityPayerId }}</td>
					<td>{{ payer.officeAllyPayerId }}</td>
					<td>{{ payer.payerId }}</td>
					<td>{{ payer.phoneNumber }}</td>
					<td>{{ payer.payerType }}</td>
					<td>{{ payer.clearingHouse }}</td>
					<td>{{ payer.providerType }}</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div *ngIf="tab == 'payerIds'">
		<table>
			<thead>
				<td>Payer IDs</td>
				<td>Primary Payer ID</td>
			</thead>
			<tbody>
				<tr *ngFor="let alias of payer.aliases">
					<td>{{ alias.id }}</td>
					<td>{{ alias.isStandardPayerId ? "Yes" : "" }}</td>
				</tr>
				<tr>
					<td>
						<input type="text" placeholder="Add a new alias payer ID" [(ngModel)]="aliasPayerId" />
					</td>
					<td>
						<a class="button" (click)="didClickAddPayerAlias()">Add</a>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div *ngIf="tab == 'states'">
		<table>
			<thead>
				<td colspan="2">State</td>
			</thead>
			<tbody>
				<tr *ngFor="let state of payer.states">
					<td>{{ state }}</td>
					<td>
						<a class="button" (click)="didClickDeleteState(state)">Delete</a>
					</td>
				</tr>
				<tr>
					<td>
						<select [(ngModel)]="state">
							<option value="">
								Select a state
							</option>
							<option *ngFor="let _state of states" [value]="_state">
								{{ _state }}
							</option>
						</select>
					</td>
					<td>
						<a class="button" (click)="didClickAddState()">Add</a>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div *ngIf="tab == 'specialties'">
		<table>
			<thead>
				<td>Specialty</td>
			</thead>
			<tbody>
				<tr *ngFor="let specialty of specialties">
					<td>
						<a [routerLink]="['/payers', payer.payerId, 'specialties', specialty.specialtyId]">
							{{ specialty.name }}
						</a>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div *ngIf="tab == 'relatedPayers'">
		<table>
			<thead>
				<td>Payer</td>
				<td>Specialty</td>
				<td></td>
			</thead>
			<tbody>
				<tr *ngFor="let payer of relatedPayers">
					<td>{{ payer.payerName }}</td>
					<td>{{ payer.specialtyName }}</td>
					<td><a class="button" (click)="didClickDeleteRelatedPayer(payer.id)">Delete</a></td>
				</tr>
			</tbody>
		</table>
		<form [formGroup]="relatedPayerForm" (ngSubmit)="didSubmitCreateRelatedPayerForm()" class="inline">
			<select formControlName="relatedPayerId" required>
				<option value="null">Select a related payer</option>
				<option *ngFor="let payer of payers" [value]="payer.payerId">{{ payer.payerName }} - {{ payer.changeHealthcarePayerId }}</option>
			</select>
			<select formControlName="specialtyId" required>
				<option [value]="null">Select a specialty</option>
				<option *ngFor="let specialty of specialties" [value]="specialty.specialtyId">
					{{ specialty.name }}
				</option>
			</select>
			<button>Submit</button>
		</form>
	</div>
</div>