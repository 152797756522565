import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AdminService } from "../@shared/services/admin.service"
import { Verification } from "../@shared/models/verification"
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as CONSTANTS from '../@shared/constants';
import { parseDate } from '../@shared/helpers/date';
import { NetworkType } from '../@shared/models/network-type';
import { AuthService } from "../@shared/services/auth.service"
import { Title } from "@angular/platform-browser"
import { ContextMenuService } from '../@shared/services/context-menu.service';
import { Payer } from '../@shared/models/payer';

@Component({
  selector: 'app-verification-detail',
  templateUrl: './verification-detail.component.html'
})
export class VerificationDetailComponent {

  @Input()
  verification: Verification|null
  @Input()
  data: any
  @Input()
  payers: Payer[]

  form: UntypedFormGroup
  networkTypes: NetworkType[] = []
  statusOptions: { value: string; text: string }[] = [
    { value: 'pending.eligible', text: 'Pending' },
    { value: 'complete.eligible', text: 'Eligible' },
    { value: 'complete.ineligible', text: 'Ineligible' },
    { value: 'error.member.id', text: 'Member ID Incorrect' },
    { value: 'error.member.dob', text: 'Member DOB Incorrect' },
    { value: 'error.member.name', text: 'Member Name Incorrect' },
    { value: 'error.payer', text: 'Payer Error' },
    { value: 'error.payer.npi', text: 'Payer Error - NPI Not Recognised' },
   ]
  payerErrorOptions: { value: string; text: string }[] = [
    { value: 'UNABLE_TO_REACH_REPRESENTATIVE', text: 'Unable to Reach Representative / IVR issues' },
    { value: 'DISCONNECTIONS_LONG_HOLD_TIMES', text: 'Disconnections / Long Hold Times' },
    { value: 'MISROUTING', text: 'Misrouting' },
    { value: 'MEMBER_INFORMATION_ISSUES', text: 'Member Information Issues' },
    { value: 'PORTAL_ONLY_ACCESS', text: 'Portal-Only Access' },
    { value: 'DIAGNOSIS_CHIEF_COMPLAINT_REQUIRED', text: 'Diagnosis/Chief Complaint Required' },
    { value: 'TAXID_NPI_ISSUES', text: 'TAXID/NPI issues' },
    { value: 'REFUSAL_TO_PROVIDE_INFORMATION', text: 'Refusal to Provide Information' },
    { value: 'FAX_REQUESTS_AND_DELAYS', text: 'Fax Requests and Delays' },
    { value: 'PAYOR_CLOSED_OR_UNAVAILABLE', text: 'Payor Closed or Unavailable' },
    { value: 'TECHNICAL_ISSUES', text: 'Technical Issues' }
  ]
  isExternalUser = false
  changeHealthcareResponses: any[] = []
  adminUsers: any[] = []
  openAdminAssigneesModal = false

  constructor(
    private adminService: AdminService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private titleService: Title,
    public contextMenuService: ContextMenuService
  ) {
    this.form = this.formBuilder.group({
      coverageStartDate: ['', []],
      coverageEndDate: ['', []],
      innCopay: ['', []],
      innCoinsurance: ['', [Validators.min(0), Validators.max(1)]],
      innTotalDeductible: ['', []],
      innRemainingDeductible: ['', []],
      innOutOfPocket: ['', []],
      innOutOfPocketRemaining: ['', []],
      innPriorAuthRequired: ['', []],
      oonCopay: ['', []],
      oonCoinsurance: ['', [Validators.min(0), Validators.max(1)]],
      oonTotalDeductible: ['', []],
      oonRemainingDeductible: ['', []],
      oonOutOfPocket: ['', []],
      oonOutOfPocketRemaining: ['', []],
      oonPriorAuthRequired: ['', []],
      status: ['', [Validators.required]],
      planName: ['', []],
      resolutionMethod: ['', [Validators.required]],
      payerError: ['', [Validators.required]],
      networkType: ['', []]
    })
  }

  async ngOnInit() {
    this.titleService.setTitle("Detail - Verification")

    this.isExternalUser = await this.authService.hasGroup("external")

    const inn = this.verification.benefits.filter((benefit) => benefit.networkStatus == "inn" && (benefit.tier == 1 || benefit.tier == null))[0]
    const oon = this.verification.benefits.filter((benefit) => benefit.networkStatus == "oon")[0]

    this.updateForm(
      this.verification.coverageStartDate,
      this.verification.coverageEndDate,
      inn.copay,
      inn.coinsurance,
      inn.totalDeductible,
      inn.remainingDeductible,
      inn.outOfPocket,
      inn.outOfPocketRemaining,
      inn.priorAuthRequired,
      oon.copay,
      oon.coinsurance,
      oon.totalDeductible,
      oon.remainingDeductible,
      oon.outOfPocket,
      oon.outOfPocketRemaining,
      oon.priorAuthRequired,
      this.verification.status,
      this.verification.planName,
      this.verification.networkType,
      this.verification.resolutionMethod,
      this.verification.payerError)

    this.networkTypes = this.data.networkTypes
    this.adminUsers = this.data.adminUsers
  }

  coverageEndDatePassed(): { result: boolean, message?: string } {
    if (this.form.value.status && this.form.value.coverageEndDate) {
      if (this.form.value.status.toLowerCase().includes('ineligible')) return { result: false };

      const currentDate = new Date()
      currentDate.setHours(0, 0, 0, 0)
      let coverageEndDate: Date;

      try {
        coverageEndDate = parseDate(this.form.value.coverageEndDate);
      } catch (error: any) {
        return { result: true, message: error.message };
      }

      if (coverageEndDate < currentDate) {
        return { result: true, message: "Coverage end date has passed" };
      }
    }
    return { result: false };
  }

  didClickOutOfNetworkBenefitsNotCovered() {
    this.form.controls["oonCopay"].setValue(0)
    this.form.controls["oonCoinsurance"].setValue(1.0)
    this.form.controls["oonTotalDeductible"].setValue(0)
    this.form.controls["oonRemainingDeductible"].setValue(0)
    this.form.controls["oonOutOfPocket"].setValue(0)
    this.form.controls["oonOutOfPocketRemaining"].setValue(0)
    this.form.controls["oonPriorAuthRequired"].setValue("true")
  }

  updateForm(coverageStartDate, coverageEndDate, innCopay, innCoinsurance, innTotalDeductible, innRemainingDeductible, innOutOfPocket, innOutOfPocketRemaining, innPriorAuthRequired, oonCopay, oonCoinsurance, oonTotalDeductible, oonRemainingDeductible, oonOutOfPocket, oonOutOfPocketRemaining, oonPriorAuthRequired, status, planName, networkType: string|null = null, resolutionMethod: string|null = null, payerError: string|null = null) {
    this.form.controls["coverageStartDate"].setValue(coverageStartDate)
    this.form.controls["coverageEndDate"].setValue(coverageEndDate)
    this.form.controls["innCopay"].setValue(innCopay)
    this.form.controls["innCoinsurance"].setValue(innCoinsurance)
    this.form.controls["innTotalDeductible"].setValue(innTotalDeductible)
    this.form.controls["innRemainingDeductible"].setValue(innRemainingDeductible)
    this.form.controls["innOutOfPocket"].setValue(innOutOfPocket)
    this.form.controls["innOutOfPocketRemaining"].setValue(innOutOfPocketRemaining)
    if (innPriorAuthRequired != null) {
      if (innPriorAuthRequired) {
        this.form.controls["innPriorAuthRequired"].setValue("true")
      } else {
        this.form.controls["innPriorAuthRequired"].setValue("false")
      }
    } else {
      this.form.controls["innPriorAuthRequired"].setValue(null)
    }
    this.form.controls["oonCopay"].setValue(oonCopay)
    this.form.controls["oonCoinsurance"].setValue(oonCoinsurance)
    this.form.controls["oonTotalDeductible"].setValue(oonTotalDeductible)
    this.form.controls["oonRemainingDeductible"].setValue(oonRemainingDeductible)
    this.form.controls["oonOutOfPocket"].setValue(oonOutOfPocket)
    this.form.controls["oonOutOfPocketRemaining"].setValue(oonOutOfPocketRemaining)
    if (oonPriorAuthRequired != null) {
      if (oonPriorAuthRequired) {
        this.form.controls["oonPriorAuthRequired"].setValue("true")
      } else {
        this.form.controls["oonPriorAuthRequired"].setValue("false")
      }
    } else {
      this.form.controls["oonPriorAuthRequired"].setValue(null)
    }
    this.form.controls["status"].setValue(status)
    this.form.controls["planName"].setValue(planName)
    this.form.controls["networkType"].setValue(networkType)
    this.form.controls["resolutionMethod"].setValue(resolutionMethod)
    this.form.controls["payerError"].setValue(payerError)
  }

  isValidDate = (value: string|null): boolean => {
    if (!value) {
      return false
    } else if (value.length !== 10) {
      return false
    } else if (!/^\d{2}\/\d{2}\/\d{4}$/.test(value)) {
      return false
    }

    const [month, day, year] = value.split('/').map(Number)
    const date = new Date(year, month - 1, day)

    return date && date.getMonth() + 1 === month && date.getDate() === day && date.getFullYear() === year
  }

  didClickSelectAdminAssignee() {
    this.openAdminAssigneesModal = true
  }

  validateSubmit() {
    const statusValues = this.statusOptions.map(option => option.value)

    const status = this.form.value.status

    if (status === undefined ||
      status === null ||
      status.startsWith("pending") ||
      !statusValues.includes(status)) {
      alert("Coverage Status required")
      return false
    } else if (status === 'error.payer' &&
      !this.form.value.payerError) {
      alert("Payer Error required")
      return false
    } else if (!this.form.value.resolutionMethod) {
      alert("Resolution Method required")
      return false
    }

    if (status === "complete.ineligible" ||
      status.startsWith("error")) {
      return true
    }

    if (!this.isValidDate(this.form.value.coverageStartDate)) {
      alert("Start Date must be in MM/DD/YYYY format")
      return false
    } else if (this.form.value.coverageEndDate !== null &&
      this.form.value.coverageEndDate !== undefined &&
      this.form.value.coverageEndDate !== "" &&
      this.isValidDate(this.form.value.coverageEndDate) === false) {
      alert("End Date must be in MM/DD/YYYY format or left empty")
      return false
    }

    const endDatePassed = this.coverageEndDatePassed()

    if (endDatePassed.result) {
      alert(endDatePassed.message)
      return false
    }

    if (this.form.value.planName === null ||
      this.form.value.planName === undefined ||
      this.form.value.planName === "") {
      alert("Plan Name is required")
      return false
    } else if (this.form.value.networkType === null ||
      this.form.value.networkType === undefined ||
      this.form.value.networkType === "") {
      alert("Insurance Type Code is required")
      return false
    }

    if (this.form.value.innTotalDeductible === null ||
      this.form.value.innTotalDeductible === undefined ||
      this.form.value.innTotalDeductible === "") {
      alert("In Network Total Deductible is required")
      return false
    } else if (this.form.value.innOutOfPocket === null ||
      this.form.value.innOutOfPocket === undefined ||
      this.form.value.innOutOfPocket === "") {
      alert("In Network Out of Pocket Max is required")
      return false
    } else if (this.form.value.innCopay === null ||
      this.form.value.innCopay === undefined ||
      this.form.value.innCopay === "") {
      alert("In Network Copay is required")
      return false
    } else if (this.form.value.innCoinsurance === null ||
      this.form.value.innCoinsurance === undefined ||
      this.form.value.innCoinsurance === "") {
      alert("In Network Coinsurance is required")
      return false
    }

    if (this.form.value.oonTotalDeductible === null ||
      this.form.value.oonTotalDeductible === undefined ||
      this.form.value.oonTotalDeductible === "") {
      alert("Out of Network Total Deductible is required")
      return false
    } else if (this.form.value.oonOutOfPocket === null ||
      this.form.value.oonOutOfPocket === undefined ||
      this.form.value.oonOutOfPocket === "") {
      alert("Out of Network Out of Pocket Max is required")
      return false
    } else if (this.form.value.oonCopay === null ||
      this.form.value.oonCopay === undefined ||
      this.form.value.oonCopay === "") {
      alert("Out of Network Copay is required")
      return false
    } else if (this.form.value.oonCoinsurance === null ||
      this.form.value.oonCoinsurance === undefined ||
      this.form.value.oonCoinsurance === "") {
      alert("Out of Network Coinsurance is required")
      return false
    }

    // Validate remaining deductible
    if (this.form.value.innTotalDeductible != null &&
      this.form.value.innRemainingDeductible != null &&
      this.form.value.innRemainingDeductible > this.form.value.innTotalDeductible) {
      alert("In network remaining deductible cannot be greater than total deductible")
      return false
    } else if (this.form.value.oonTotalDeductible != null &&
      this.form.value.oonRemainingDeductible != null &&
      this.form.value.oonRemainingDeductible > this.form.value.oonTotalDeductible) {
      alert("Out of network remaining deductible cannot be greater than total deductible")
      return false
    }

    // Validate out of pocket remaining
    if (this.form.value.innOutOfPocket != null &&
      this.form.value.innOutOfPocketRemaining != null &&
      this.form.value.innOutOfPocketRemaining > this.form.value.innOutOfPocket) {
      alert("In network out of pocket remaining cannot be greater than out of pocket max")
      return false
    } else if (this.form.value.oonOutOfPocket != null &&
      this.form.value.oonOutOfPocketRemaining != null &&
      this.form.value.oonOutOfPocketRemaining > this.form.value.oonOutOfPocket) {
      alert("Out of network out of pocket remaining cannot be greater than out of pocket max")
      return false
    }

    const innCopay = this.form.value.innCopay
    const innCoinsurance = this.form.value.innCoinsurance
    const oonCopay = this.form.value.oonCopay
    const oonCoinsurance = this.form.value.oonCoinsurance

    // Validate coinsurance
    if (innCoinsurance != null &&
      innCoinsurance > 1.0) {
      alert("In network coinsurance cannot be greater than 1")
      return false
    } else if (oonCoinsurance != null &&
      oonCoinsurance > 1.0) {
      alert("Out of network coinsurance cannot be greater than 1")
      return false
    } else if (innCoinsurance != null &&
      (innCoinsurance * 100) % 5 != 0) {
      alert("In network coinsurance must be in 5% increments")
      return false
    } else if (oonCoinsurance != null &&
      (oonCoinsurance * 100) % 5 != 0) {
      alert("Out of network coinsurance must be in 5% increments")
      return false
    }

    if (innCoinsurance != null &&
      innCoinsurance != 0 &&
      innCopay != null &&
      innCopay != 0) {
      alert("In network benefits can't have both copay and coinsurance")
      return false
    }

    if (oonCoinsurance != null &&
      oonCoinsurance != 0 &&
      oonCopay != null &&
      oonCopay != 0) {
      alert("Out of network benefits can't have both copay and coinsurance")
      return false
    }

    return true;
  }

  async didClickSubmit() {
    const isFormValid = this.validateSubmit()

    if (isFormValid === false) {
      return
    }

    const verificationId: string = this.verification.verificationId
    const customerId: string = this.verification.customerId
    const patientId: string = this.verification.patientId
    const status: string|null = this.form.value.status
    const resolutionMethod: string|null = this.form.value.resolutionMethod
    const payerError: string|null = this.form.value.payerError

    const coverageStartDate: string|null = this.form.value.coverageStartDate || null
    const coverageEndDate: string|null = this.form.value.coverageEndDate || null
    const innCopay: number|null = this.form.value.innCopay
    const innCoinsurance: number|null = this.form.value.innCoinsurance
    const innTotalDeductible: number|null = this.form.value.innTotalDeductible
    const innRemainingDeductible: number|null = this.form.value.innRemainingDeductible
    const innOutOfPocket: number|null = this.form.value.innOutOfPocket
    const innOutOfPocketRemaining: number|null = this.form.value.innOutOfPocketRemaining
    const innPriorAuthRequired: boolean|null = this.form.value.innPriorAuthRequired ? this.form.value.innPriorAuthRequired == "true" : null
    const oonCopay = this.form.value.oonCopay
    const oonCoinsurance: number|null = this.form.value.oonCoinsurance
    const oonTotalDeductible: number|null = this.form.value.oonTotalDeductible
    const oonRemainingDeductible: number|null = this.form.value.oonRemainingDeductible
    const oonOutOfPocket: number|null = this.form.value.oonOutOfPocket
    const oonOutOfPocketRemaining: number|null = this.form.value.oonOutOfPocketRemaining
    const oonPriorAuthRequired: boolean|null = this.form.value.oonPriorAuthRequired ? this.form.value.oonPriorAuthRequired == "true" : null
    const planName: string|null = this.form.value.planName || null
    const networkType: string|null = this.form.value.networkType || null;

    const res = await this.adminService.updateVerification(
      verificationId,
      customerId,
      patientId,
      coverageStartDate,
      coverageEndDate,
      innCopay,
      innCoinsurance,
      innTotalDeductible,
      innRemainingDeductible,
      innOutOfPocket,
      innOutOfPocketRemaining,
      innPriorAuthRequired,
      oonCopay,
      oonCoinsurance,
      oonTotalDeductible,
      oonRemainingDeductible,
      oonOutOfPocket,
      oonOutOfPocketRemaining,
      oonPriorAuthRequired,
      status,
      planName,
      resolutionMethod,
      networkType,
      payerError)

    this.router.navigate([""], { relativeTo: this.route })
  }

  async didClickRetryVerification() {
    const verificationId = this.verification.verificationId

    if (!verificationId) {
      return
    }

    const confirmed = confirm(`Are you sure you want to retry this verification?`)

    if (!confirmed) {
      return
    }

    try {
      const res = await this.adminService.retryPendingVerification(verificationId)
      console.log(res)
      alert(`Verification retried`)
    } catch (error: any) {
      alert("Something went wrong")
    }
  }
}