import { Component } from '@angular/core';
import { AdminService } from "../@shared/services/admin.service"
import { Title } from "@angular/platform-browser"
import { DiscoveryRequest, DiscoveryResponse } from "../@shared/models/discoveryRequest"
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-discovery-detail',
  templateUrl: './discovery-detail.component.html'
})
export class DiscoveryDetailComponent {

  discoveryRequest: DiscoveryRequest|null = null

  requestStatusMap = {
    0: "Created",
    1: "Pending",
    2: "Complete",
    3: "In Progress",
    4: "Error"
  }

  responseStatusMap = {
    0: "Not Found",
    1: "Unavailable",
    2: "Found",
    3: "Inactive"
  }

  recreateVerificationUrl: string = null

  constructor(
    private adminService: AdminService,
    private titleService: Title,
    private route: ActivatedRoute) {}

  async ngOnInit() {
    const discoveryId = this.route.snapshot.paramMap.get("id")
    this.titleService.setTitle("Discovery")
    this.discoveryRequest = await this.adminService.getDiscoveryRequest(discoveryId)

    this.recreateVerificationUrl = `https://app.soharhealth.com/create-patient?firstName=${this.discoveryRequest.firstName}&lastName=${this.discoveryRequest.lastName}&dateOfBirth=${this.discoveryRequest.dateOfBirth}&state=${this.discoveryRequest.state}`
  }

  async getResponse(discoveryResponse: DiscoveryResponse) {

    if (discoveryResponse.res !== null &&
      discoveryResponse.res !== undefined) {
      discoveryResponse.res = null
      return
    }

    const discoveryId = discoveryResponse.discoveryId
    const responseId = discoveryResponse.responseId

    try {
      const _discoveryResponse = await this.adminService.getDiscoveryResponse(
        discoveryId,
        responseId)

      if (!_discoveryResponse ||
        !_discoveryResponse.res) {
        return
      }

      discoveryResponse.res = JSON.parse(_discoveryResponse.res)
    } catch (error: any) {
      console.log(error)
    }
  }
}
