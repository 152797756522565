import { Component } from '@angular/core';
import { AuthService } from "../@shared/services/auth.service"
import { SubMenuClickedEvent, SubMenuInputConfig, SubMenuName } from '../@shared/modules/sub-menu/sub-menu.component';
import { AdminService } from '../@shared/services/admin.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: [`
    .caret-image {
      float: right;
    }
    .version-info {
      position: absolute;
      bottom: 10px;
      left: 10px;
      font-size: 0.6rem;
      color: grey;
      z-index: 9999;
    }
  `]
})
export class DashboardComponent {

  isExternalUser: boolean = false
  isAdmin: boolean = false
  showPayersMenu: boolean = false;
  showSpecialtiesMenu: boolean = false;
  version: string | null = null;

  payersSubmenuConfig: SubMenuInputConfig = {
    name: SubMenuName.Payers,
    elements: [
      {
        name: "Payers",
        link: "/payers"
      },
      {
        name: "Routing",
        link: "/payer-intelligence/routing"
      },
      {
        name: "Discovery",
        link: "/payer-intelligence/discovery"
      },
      {
        name: "Mapping",
        link: "/payer-intelligence/mapping"
      },
      {
        name: "Pending Payers",
        link: "/payers-pending"
      }
    ]
};

  specialtiesSubmenuConfig: SubMenuInputConfig = {
    name: SubMenuName.Specialties,
    elements: [
      {
        name: "Specialties",
        link: "/specialties"
      },
      {
        name: "Taxonomy Codes",
        link: "/taxonomy-codes"
      }
    ]
};

  constructor(
    private authService: AuthService,
    private adminService: AdminService
  ) { }

  async ngOnInit() {
    this.isExternalUser = await this.authService.hasGroup("external")
    this.isAdmin = await this.authService.hasGroup("admin")
    this.version = await this.adminService.getVersion()
  }

  toggleMenu(val: string) {
    if (val === SubMenuName.Payers) {
      this.showPayersMenu = !this.showPayersMenu;
    } else if (val === SubMenuName.Specialties) {
      this.showSpecialtiesMenu = !this.showSpecialtiesMenu;
    }
  }

  resetMenus() {
    this.showSpecialtiesMenu = false;
    this.showPayersMenu = false;
  }

  handleElementClicked(subMenuEvent: SubMenuClickedEvent) {
    const subMenuName = subMenuEvent.name
    if (subMenuName === SubMenuName.Payers) {
      this.showSpecialtiesMenu = false;
    } else if (subMenuName === SubMenuName.Specialties) {
      this.showPayersMenu = false;
    }
  }
}
