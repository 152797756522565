<div class="header">
	<h1>Customer</h1>
	<div>
		<label class="button" for="file-upload">Upload Providers<input id="file-upload" type="file" (change)="didClickAddProviders($event)" accept=".csv"></label>
	</div>
</div>
<ul class="tabs">
	<li [class.active]="tab == 'main'"><a (click)="tab = 'main'">Customer Information</a></li>
	<li [class.active]="tab == 'quota'"><a (click)="tab = 'quota'">Quotas</a></li>
	<li [class.active]="tab == 'providers'"><a (click)="tab = 'providers'">Providers</a></li>
	<li [class.active]="tab == 'users'"><a (click)="tab = 'users'">Users</a></li>
</ul>

<div *ngIf="tab == 'main'">
	<table *ngIf="customer">
		<thead>
			<td>Name</td>
			<td>Created</td>
		</thead>
		<tbody>
			<tr>
				<td>{{ customer.name }}</td>
				<td>{{ customer.timestamp| date: 'MM/dd/yyyy' }}</td>
			</tr>
		</tbody>
	</table>
</div>

<div *ngIf="tab == 'quota'">
	<table *ngIf="customer">
		<thead>
			<td style="width: 25%;">Discovery Quota Limit</td>
			<td style="width: 25%;">Verification Quota Limit</td>
			<td></td>
		</thead>
		<tbody style="vertical-align: top;">
			<tr>
				<td>
					<quota-field
						[inputQuotaValue]="discoveryQuota"
						(quotaUpdated)="onQuotaChange($event, 'discovery')"
						(editChanged)="onEditChanged($event, 'discovery')"
					>
					</quota-field>
				</td>
				<td>
					<quota-field
						[inputQuotaValue]="verificationQuota"
						(quotaUpdated)="onQuotaChange($event, 'verification')"
						(editChanged)="onEditChanged($event, 'verification')"
					>
					</quota-field>
				</td>
				<td>
					<a
						[ngClass]="{'invalid': hasQuotaChanged(), disabled: !hasQuotaChanged() || isEditingDiscovery || isEditingVerification }"
						class="button"
						(click)="submitQuota()"
					>
						Submit
					</a>
				</td>
			</tr>
		</tbody>
	</table>
</div>

<div *ngIf="tab == 'providers'">
	<table>
		<thead>
			<td>Payer</td>
			<td>State</td>
			<td>NPI</td>
			<td>Provider Type</td>
			<td>Active</td>
			<td>Created</td>
			<td></td>
		</thead>
		<tbody>
			<tr *ngFor="let provider of providers">
				<td>{{ provider.payerName }}</td>
				<td>{{ provider.state }}</td>
				<td>{{ provider.npi }}</td>
				<td>{{ provider.providerType }}</td>
				<td>{{ provider.active == 1 ? "Yes" : "No" }}</td>
				<td>{{ provider.created | date }}</td>
				<td>
					<a class="button" (click)="didClickEnableProvider(provider.id)">{{ provider.active == 1 ? "Disable" : "Enable" }}</a>
				</td>
			</tr>
			<tr>
				<td>
					<select [(ngModel)]="payerId">
						<option value="">Select a Payer</option>
						<option *ngFor="let payer of payers" [value]="payer.payerId">{{ payer.payerName }}</option>
					</select>
				</td>
				<td>
					<select [(ngModel)]="state">
						<option value="">Select a State</option>
						<option *ngFor="let state of states" [value]="state">{{ state }}</option>
					</select>
				</td>
				<td>
					<input type="text" placeholder="NPI" [(ngModel)]="npi" />
				</td>
				<td>
					<a class="button" (click)="didClickAddProvider()">Submit</a>
				</td>
			</tr>
		</tbody>
	</table>
</div>

<div *ngIf="tab == 'users'">
	<table>
		<thead>
			<td>Email Address</td>
			<td>Role</td>
			<td></td>
		</thead>
		<tbody>
			<tr *ngFor="let user of users">
				<td>{{ user.emailAddress }}</td>
				<td>{{ user.role }}</td>
				<td>
					<a class="button" (click)="didClickResetUserPassword(user)">Reset Password</a>
					<a class="button" (click)="didClickResetMfa(user)">Reset MFA</a>
				</td>
			</tr>
		</tbody>
	</table>
</div>
