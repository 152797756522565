import { PayerDefault } from  "./payer-default"
import { ConfigurationGroup } from "./configuration-group"
import { getClearingHouseName } from "../helpers/clearingHouseName"

export class Payer {

	newStandardPayerId: string|null = null

	constructor(
		public payerId: string,
		public payerName: string,
		public changeHealthcarePayerId: string,
		public availityPayerId: string,
		public officeAllyPayerId: string,
		public unitedHealthcarePayerId: string,
		public stediPayerId: string|null,
		public phoneNumber: string,
		public configurations: string|null,
		public clearingHouseIdentifier: number,
		public discoveryClearingHouseIdentifier: number,
		public aliases: any[],
		public states: string[],
		public discovery: boolean,
		public providerType: string,
		public payerType: string,
		public standardPayerId: string|null) {}

	static fromJson(data: any): Payer {
		return new Payer(
			data.payerId,
			data.payerName,
			data.changeHealthcarePayerId,
			data.availityPayerId,
			data.officeAllyPayerId,
			data.unitedHealthcarePayerId,
			data.stediPayerId,
			data.phoneNumber,
			data.configurations ? JSON.parse(data.configurations) : null,
			data.clearingHouseIdentifier,
			data.discoveryClearingHouseIdentifier,
			data.aliases || [],
			data.states || [],
			data.discovery == 1,
			data.providerType,
			data.payerType,
			data.standardPayerId)
	}

	public get isCustomerPayer(): boolean {
		return this.changeHealthcarePayerId != null ||
			this.availityPayerId != null ||
			this.officeAllyPayerId != null
	}

	public get clearingHouse(): string|null {
		return getClearingHouseName(this.clearingHouseIdentifier)
	}

	public get discoveryClearingHouse(): string|null {
		return getClearingHouseName(this.discoveryClearingHouseIdentifier)
	}
}

export type PendingPayer = {
	standardPayerId: string|null
	payerId: string
	payerName: string
}
