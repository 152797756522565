<div class="header">
	<h1>Taxonomy Codes</h1>
</div>
<table>
	<thead>
		<td>Taxonomy Code</td>
		<td>Grouping</td>
		<td>Classification</td>
		<td>Specialization</td>
		<td>Display Name</td>
		<td>Specialty</td>
	</thead>
	<tbody>
		<tr *ngFor="let taxonomyCode of taxonomyCodes">
			<td>{{ taxonomyCode.taxonomyCode }}</td>
			<td>{{ taxonomyCode.grouping }}</td>
			<td>{{ taxonomyCode.classification }}</td>
			<td>{{ taxonomyCode.specialization }}</td>
			<td>{{ taxonomyCode.displayName }}</td>
			<td>
				<select [(ngModel)]="taxonomyCode.specialtyId" (change)="didChangeSpecialty(taxonomyCode.taxonomyCode, $event)">
					<option *ngFor="let specialty of specialties" [value]="specialty.specialtyId">
						{{ specialty.name }}
					</option>
				</select>
			</td>
		</tr>
	</tbody>
</table>
