<div class="fixed top-0 left-[260px] w-[calc(100%-260px)] bg-white border-b border-gray-300 p-6 h-[80px] flex items-center justify-between z-10">
	<h1 class="text-xl font-bold">Create Payer</h1>
</div>

<div class="mt-[60px] p-6">
	<div class="w-[600px] mx-auto bg-white border rounded-lg p-6">
		<form [formGroup]="form" (ngSubmit)="didClickCreatePayer()">
			<label class="form-control w-full">
				<div class="label">
					<span class="label-text">Payer Name</span>
				</div>
				<input type="text" class="input input-bordered w-full bg-white"  formControlName="payerName" placeholder="Enter payer name" (paste)="didPastePayerName($event)">
			</label>

			<label class="form-control w-full mt-4">
				<div class="label">
					<span class="label-text">Change Healthcare Payer ID</span>
				</div>
				<input type="text" class="input input-bordered w-full bg-white" formControlName="changeHealthcarePayerId" placeholder="Enter Change Healthcare payer ID">
			</label>

			<label class="form-control w-full mt-4">
				<div class="label">
					<span class="label-text">Payer ID</span>
				</div>
				<input type="text" class="input input-bordered w-full bg-white" formControlName="standardPayerId" placeholder="Enter the standard payer ID">
			</label>

			<p class="text-gray-500 text-sm mt-4">If the payer is for payer mapping but we don't want to include it in our customer-facing payer list, leave the Change Healthcare payer ID and standard payer ID empty.</p>

			<button class="btn btn-accent text-white mt-8 min-w-[180px]">Submit</button>
		</form>
	</div>
</div>
