<div class="flex h-screen">
	<div class="w-[260px] side-bar z-50 p-6 flex flex-col h-screen fixed left-0 top-0">
		<div class="text-left pb-6">
			<img src="assets/logo-white.svg" class="h-[24px] mb-2" />
		</div>
		<ul class="menu p-0 border-t border-gray-700 pt-6">
			<li>
				<a class="p-3" [routerLink]="['/']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
					<svg class="h-4" viewBox="0 0 28 33" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M-8.15804e-07 16.6628L27.9951 0.49977L27.9951 32.8257L-8.15804e-07 16.6628Z" stroke="white" stroke-width="2" />
					</svg>
					Verification
				</a>
			</li>

			<li *ngIf="!isExternalUser">
				<a class="p-3 mt-2" [routerLink]="['/discovery']" routerLinkActive="active-link">
					<svg class="h-[16px]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 28 28">
						<circle stroke="white" stroke-width="2" cx="14" cy="14" r="13"></circle>
					</svg>
					Discovery
				</a>
			</li>

			<div *ngIf="!isExternalUser" class="mt-4">
				<li class="text-gray-400 font-bold">Payer Intelligence</li>
				<li><a class="p-3 mt-2 ml-2" [routerLink]="['/payers']" routerLinkActive="active-link"><svg class="h-4" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="m4 13h3v2h-3zm5 2h3v-2h-3zm-5 4h3v-2h-3zm5 0h3v-2h-3zm-5-12h3v-2h-3zm5 0h3v-2h-3zm-5 4h3v-2h-3zm5 0h3v-2h-3zm15-3v16h-24v-21a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v2h5a3 3 0 0 1 3 3zm-10-5a1 1 0 0 0 -1-1h-10a1 1 0 0 0 -1 1v19h12zm8 5a1 1 0 0 0 -1-1h-5v15h6zm-4 7h2v-2h-2zm0 4h2v-2h-2zm0-8h2v-2h-2z"/></svg>Payers</a></li>
				<li><a class="p-3 mt-2 ml-2" [routerLink]="['/payer-intelligence/routing']" routerLinkActive="active-link"><svg class="h-4" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24"><path fill="currentColor" d="M19,24H5c-2.757,0-5-2.243-5-5V9.724c0-1.665,.824-3.215,2.204-4.145L9.203,.855c1.699-1.146,3.895-1.146,5.594,0l7,4.724c1.379,.93,2.203,2.479,2.203,4.145v9.276c0,2.757-2.243,5-5,5ZM12,1.997c-.584,0-1.168,.172-1.678,.517L3.322,7.237c-.828,.558-1.322,1.487-1.322,2.486v9.276c0,1.654,1.346,3,3,3h14c1.654,0,3-1.346,3-3V9.724c0-.999-.494-1.929-1.321-2.486L13.678,2.514c-.51-.345-1.094-.517-1.678-.517Z"/></svg>Routing</a></li>
				<li><a class="p-3 mt-2 ml-2" [routerLink]="['/payer-intelligence/mapping']" routerLinkActive="active-link"><svg class="h-4" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24"><path fill="currentColor" d="M19,24H5c-2.757,0-5-2.243-5-5V9.724c0-1.665,.824-3.215,2.204-4.145L9.203,.855c1.699-1.146,3.895-1.146,5.594,0l7,4.724c1.379,.93,2.203,2.479,2.203,4.145v9.276c0,2.757-2.243,5-5,5ZM12,1.997c-.584,0-1.168,.172-1.678,.517L3.322,7.237c-.828,.558-1.322,1.487-1.322,2.486v9.276c0,1.654,1.346,3,3,3h14c1.654,0,3-1.346,3-3V9.724c0-.999-.494-1.929-1.321-2.486L13.678,2.514c-.51-.345-1.094-.517-1.678-.517Z"/></svg>Mapping</a></li>
				<li><a class="p-3 mt-2 ml-2" [routerLink]="['/payer-intelligence/discovery']" routerLinkActive="active-link"><svg class="h-4" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24"><path fill="currentColor" d="M19,24H5c-2.757,0-5-2.243-5-5V9.724c0-1.665,.824-3.215,2.204-4.145L9.203,.855c1.699-1.146,3.895-1.146,5.594,0l7,4.724c1.379,.93,2.203,2.479,2.203,4.145v9.276c0,2.757-2.243,5-5,5ZM12,1.997c-.584,0-1.168,.172-1.678,.517L3.322,7.237c-.828,.558-1.322,1.487-1.322,2.486v9.276c0,1.654,1.346,3,3,3h14c1.654,0,3-1.346,3-3V9.724c0-.999-.494-1.929-1.321-2.486L13.678,2.514c-.51-.345-1.094-.517-1.678-.517Z"/></svg>Discovery</a></li>
			</div>

			<div *ngIf="!isExternalUser" class="mt-4">
				<li class="text-gray-400 font-bold">Specialties</li>
				<li><a class="p-3 mt-2 ml-2" [routerLink]="['/specialties']" routerLinkActive="active-link"><svg class="h-4" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24"><path fill="currentColor" d="M19,24H5c-2.757,0-5-2.243-5-5V9.724c0-1.665,.824-3.215,2.204-4.145L9.203,.855c1.699-1.146,3.895-1.146,5.594,0l7,4.724c1.379,.93,2.203,2.479,2.203,4.145v9.276c0,2.757-2.243,5-5,5ZM12,1.997c-.584,0-1.168,.172-1.678,.517L3.322,7.237c-.828,.558-1.322,1.487-1.322,2.486v9.276c0,1.654,1.346,3,3,3h14c1.654,0,3-1.346,3-3V9.724c0-.999-.494-1.929-1.321-2.486L13.678,2.514c-.51-.345-1.094-.517-1.678-.517Z"/></svg>Specialties</a></li>
				<li><a class="p-3 mt-2 ml-2" [routerLink]="['/taxonomy-codes']" routerLinkActive="active-link"><svg class="h-4" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24"><path fill="currentColor" d="M19,24H5c-2.757,0-5-2.243-5-5V9.724c0-1.665,.824-3.215,2.204-4.145L9.203,.855c1.699-1.146,3.895-1.146,5.594,0l7,4.724c1.379,.93,2.203,2.479,2.203,4.145v9.276c0,2.757-2.243,5-5,5ZM12,1.997c-.584,0-1.168,.172-1.678,.517L3.322,7.237c-.828,.558-1.322,1.487-1.322,2.486v9.276c0,1.654,1.346,3,3,3h14c1.654,0,3-1.346,3-3V9.724c0-.999-.494-1.929-1.321-2.486L13.678,2.514c-.51-.345-1.094-.517-1.678-.517Z"/></svg>Taxonomy Codes</a></li>
			</div>

			<div *ngIf="!isExternalUser" class="mt-4">
				<li class="text-gray-400 font-bold">Settings</li>
				<li><a class="p-3 mt-2 ml-2" [routerLink]="['/customers']" routerLinkActive="active-link"><svg class="h-4" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24"><path fill="currentColor" d="M19,24H5c-2.757,0-5-2.243-5-5V9.724c0-1.665,.824-3.215,2.204-4.145L9.203,.855c1.699-1.146,3.895-1.146,5.594,0l7,4.724c1.379,.93,2.203,2.479,2.203,4.145v9.276c0,2.757-2.243,5-5,5ZM12,1.997c-.584,0-1.168,.172-1.678,.517L3.322,7.237c-.828,.558-1.322,1.487-1.322,2.486v9.276c0,1.654,1.346,3,3,3h14c1.654,0,3-1.346,3-3V9.724c0-.999-.494-1.929-1.321-2.486L13.678,2.514c-.51-.345-1.094-.517-1.678-.517Z"/></svg>Customers</a></li>
				<li *ngIf="isAdmin"><a class="p-3 mt-2 ml-2" [routerLink]="['/team']" routerLinkActive="active-link"><svg class="h-4" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24"><path fill="currentColor" d="M19,24H5c-2.757,0-5-2.243-5-5V9.724c0-1.665,.824-3.215,2.204-4.145L9.203,.855c1.699-1.146,3.895-1.146,5.594,0l7,4.724c1.379,.93,2.203,2.479,2.203,4.145v9.276c0,2.757-2.243,5-5,5ZM12,1.997c-.584,0-1.168,.172-1.678,.517L3.322,7.237c-.828,.558-1.322,1.487-1.322,2.486v9.276c0,1.654,1.346,3,3,3h14c1.654,0,3-1.346,3-3V9.724c0-.999-.494-1.929-1.321-2.486L13.678,2.514c-.51-.345-1.094-.517-1.678-.517Z"/></svg>Team</a></li>
			</div>
		</ul>
		<div *ngIf="version" class="version-info">
			{{ version }}
		</div>
	</div>
	<div class="flex-1 ml-[260px] mt-[80px] overflow-visible min-h-[calc(100vh-80px)]">
	    <router-outlet></router-outlet>
	</div>

	<div class="toast">
		<div class="alert" [class.alert-success]="alert.type == 'success'" [class.alert-error]="alert.type == 'error'" *ngFor="let alert of alerts">
			<span>{{ alert.message }}</span>
		</div>
	</div>
</div>