<div class="fixed top-0 left-[260px] w-[calc(100%-260px)] bg-white border-b border-gray-300 p-6 h-[80px] flex items-center justify-between z-10">
	<h1 class="text-xl font-bold">Taxonomy Codes</h1>
</div>

<div class="p-6 bg-white min-h-screen">
	<div class="overflow-x-auto">
		<table class="table w-full">
			<thead class="bg-gray-200 rounded-lg">
				<tr class="rounded-lg border-b-0">
					<th class="rounded-l-lg">Taxonomy Code</th>
					<th>Grouping</th>
					<th>Classification</th>
					<th>Specialization</th>
					<th>Display Name</th>
					<th class="rounded-r-lg">Specialty</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let taxonomyCode of taxonomyCodes">
					<td>{{ taxonomyCode.taxonomyCode }}</td>
					<td>{{ taxonomyCode.grouping }}</td>
					<td>{{ taxonomyCode.classification }}</td>
					<td>{{ taxonomyCode.specialization }}</td>
					<td>{{ taxonomyCode.displayName }}</td>
					<td>
						<select class="select select-bordered bg-white w-full min-w-[260px]" [(ngModel)]="taxonomyCode.specialtyId" (change)="didChangeSpecialty(taxonomyCode.taxonomyCode, $event)">
							<option *ngFor="let specialty of specialties" [value]="specialty.specialtyId">
								{{ specialty.name }}
							</option>
						</select>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
