import { Component } from '@angular/core'
import { AdminService } from "../@shared/services/admin.service"
import { Payer } from "../@shared/models/payer"
import { ActivatedRoute, Router } from '@angular/router'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { Title } from "@angular/platform-browser"

@Component({
  selector: 'app-edit-payer',
  templateUrl: './edit-payer.component.html'
})
export class EditPayerComponent {

  payerId: string|null
  payer: Payer|null = null
  form: UntypedFormGroup

  constructor(
    private adminService: AdminService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private titleService: Title
  ) {}

  async ngOnInit() {
    this.titleService.setTitle("Edit Payer")

    this.form = this.formBuilder.group({
      payerName: [null, [Validators.required]],
      changeHealthcarePayerId: [null, []],
      availityPayerId: [null, []],
      officeAllyPayerId: [null, []],
      unitedHealthcarePayerId: [null, []],
      stediPayerId: [null, []],
      clearingHouseIdentifier: [null, []],
      discoveryClearingHouseIdentifier: [null, []],
      providerType: [null, []],
      payerType: [null, []],
      discovery: [null, []]
    })

    this.payerId = this.route.snapshot.paramMap.get("id")
    this.payer = await this.adminService.getPayer(this.payerId)

    this.form.controls["payerName"].setValue(this.payer.payerName)
    this.form.controls["changeHealthcarePayerId"].setValue(this.payer.changeHealthcarePayerId)
    this.form.controls["availityPayerId"].setValue(this.payer.availityPayerId)
    this.form.controls["officeAllyPayerId"].setValue(this.payer.officeAllyPayerId)
    this.form.controls["unitedHealthcarePayerId"].setValue(this.payer.unitedHealthcarePayerId)
    this.form.controls["stediPayerId"].setValue(this.payer.stediPayerId)
    this.form.controls["clearingHouseIdentifier"].setValue(this.payer.clearingHouseIdentifier)
    this.form.controls["discoveryClearingHouseIdentifier"].setValue(this.payer.discoveryClearingHouseIdentifier)
    this.form.controls["providerType"].setValue(this.payer.providerType)
    this.form.controls["payerType"].setValue(this.payer.payerType)
    this.form.controls["discovery"].setValue(this.payer.discovery ? "true" : "false")
  }

  async onSubmit() {
    const payerName = this.form.value["payerName"]
    const changeHealthcarePayerId = this.form.value["changeHealthcarePayerId"]
    const availityPayerId = this.form.value["availityPayerId"]
    const officeAllyPayerId = this.form.value["officeAllyPayerId"]
    const unitedHealthcarePayerId = this.form.value["unitedHealthcarePayerId"]
    const stediPayerId = this.form.value["stediPayerId"]
    const clearingHouseIdentifier = this.form.value["clearingHouseIdentifier"]
    const discoveryClearingHouseIdentifier = this.form.value["discoveryClearingHouseIdentifier"]
    const providerType = this.form.value["providerType"]
    const payerType = this.form.value["payerType"]
    const discovery: string = this.form.value["discovery"]

    if (!payerName) {
      alert("Payer name required")
      return
    }

    try {
      await this.adminService.updatePayer(
        this.payerId,
        null,
        payerName,
        changeHealthcarePayerId,
        availityPayerId,
        officeAllyPayerId,
        unitedHealthcarePayerId,
        stediPayerId,
        clearingHouseIdentifier,
        discoveryClearingHouseIdentifier,
        providerType,
        payerType,
        discovery === "true")

      this.router.navigate(["payers", this.payerId])
    } catch (error) {
      alert("Something went wrong")
    }
  }
}
