<div class="fixed top-0 left-[260px] w-[calc(100%-260px)] bg-white border-b border-gray-300 p-6 h-[80px] flex items-center justify-between z-10">
	<h1 class="text-xl font-bold">Customers</h1>
	<div class="flex">
		<a class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100 ml-4" [routerLink]="['/create-customer']">
			<svg class="h-[14px]" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 512 512" xml:space="preserve"><g><path d="M480,224H288V32c0-17.673-14.327-32-32-32s-32,14.327-32,32v192H32c-17.673,0-32,14.327-32,32s14.327,32,32,32h192v192   c0,17.673,14.327,32,32,32s32-14.327,32-32V288h192c17.673,0,32-14.327,32-32S497.673,224,480,224z"/></g></svg>
			Create Customer
		</a>
	</div>
</div>

<div class="p-6 bg-white min-h-screen">
	<div class="overflow-x-auto">
		<table class="table w-full">
			<thead class="bg-gray-200 rounded-lg">
				<tr class="rounded-lg border-b-0">
					<th class="rounded-l-lg">Name</th>
					<th class="rounded-r-lg">Created</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let customer of customers">
					<td>
						<a [routerLink]="['/customers', customer.customerId]">{{ customer.name }}</a>
					</td>
					<td>{{ customer.timestamp| date: 'MM/dd/yyyy' }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
