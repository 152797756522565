import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RelatedEntityModalService {
  private subject = new Subject<{
    name: string|undefined,
    phoneNumber: string|undefined
  }|undefined>();

  get() {
    return this.subject.asObservable()
  }

  open(relatedEntity: {
    name: string|undefined,
    phoneNumber: string|undefined
  }|undefined) {
    this.subject.next(relatedEntity)
  }
}
