<related-entity-modal />
<confirmation-modal />
<div class="header">
	<h1>Benefits Information</h1>
</div>
<div class="admin row verification" *ngIf="verification">
	<section>
		<div *ngIf="!verification.status.includes('pending')">
			<div class="section">
				<h4>Plan</h4>
				<h5>Name</h5>
				<p>{{ verification.planName }}</p>
				<h5>Insurance Type Code</h5>
				<p [class.empty]="!verification.networkType">{{ verification.networkType || "None" }}</p>
				<h5>Coverage Start Date</h5>
				<p [class.empty]="!verification.coverageStartDate">{{ verification.coverageStartDate || "None" }}</p>
				<h5>Coverage End Date</h5>
				<p [class.empty]="!verification.coverageEndDate">{{ verification.coverageEndDate || "None" }}</p>
				<h5>Group Number</h5>
				<p [class.empty]="!verification.groupNumber">{{ verification.groupNumber || "None" }}</p>
				<h5>Plan Number</h5>
				<p [class.empty]="!verification.planNumber">{{ verification.planNumber || "None" }}</p>
				<h5>Service Type Code</h5>
				<p [class.empty]="!verification.serviceTypeCode">{{ verification.serviceTypeCode || "None" }}</p>
			</div>
			<div *ngFor="let benefit of verification.benefits" class="section">
				<h4>{{ benefit.networkStatus == "inn" ? "In Network" : "Out of Network" }}</h4>
				<h5 *ngIf="benefit.tier">Tier</h5>
				<p *ngIf="benefit.tier">{{ benefit.tier }}</p>
				<h5>Copay</h5>
				<p>{{ benefit.copay | currency }}</p>
				<h5>Coinsurance</h5>
				<p>{{ benefit.coinsurance | percent }}</p>
				<h5>Deductible</h5>
				<p>{{ benefit.deductible }}</p>
				<h5>Out of Pocket</h5>
				<p>{{ benefit.oop }}</p>
			</div>
			<div class="section">
				<h4>Carve Out</h4>
				<h5>Name</h5>
				<p [class.empty]="!verification.carveOut?.payerName">{{ verification.carveOut?.payerName || "None" }}</p>
			</div>
		</div>
		<form *ngIf="verification.status.includes('pending')" [formGroup]="form" (ngSubmit)="showConfirmation()" class="admin-form">
			<div class="flex header" *ngIf="!isExternalUser">
				<a class="button" (click)="didClickRetryVerification()">Retry Verification</a>
			</div>
			<div class="flex">
				<div>
					<label>Coverage Start Date</label>
					<input type="text" formControlName="coverageStartDate" placeholder="MM/DD/YYYY" required />
				</div>
				<div>
					<label>Coverage End Date</label>
					<input type="text" formControlName="coverageEndDate" placeholder="MM/DD/YYYY" />
				</div>
			</div>
			<div class="flex">
				<div>
					<label>Plan</label>
					<input type="text" formControlName="planName" required />
				</div>
				<div>
					<label>Coverage Status</label>
					<select formControlName="status" required>
						<option *ngFor="let option of statusOptions" [value]="option.value">
							{{ option.text }}
						</option>
					</select>
				</div>
				<div>
					<label>Network Type</label>
					<select formControlName="networkType">
						<option value="">Select Network Type</option>
						<option *ngFor="let networkType of networkTypes" [value]="networkType.networkType">
							{{ networkType.networkTypeName }}
						</option>
					</select>
				</div>
			</div>
			<h3>In Network</h3>
			<div class="flex">
				<div>
					<label>Total Deductible</label>
					<input type="number" formControlName="innTotalDeductible" required />
				</div>
				<div>
					<label>Remaining Deductible</label>
					<input type="number" formControlName="innRemainingDeductible" />
				</div>
			</div>
			<div class="flex">
				<div>
					<label>Out of Pocket Max</label>
					<input type="number" formControlName="innOutOfPocket" />
				</div>
				<div>
					<label>Out of Pocket Remaining</label>
					<input type="number" formControlName="innOutOfPocketRemaining" />
				</div>
			</div>
			<div class="flex">
				<div>
					<label>Copay</label>
					<input type="number" formControlName="innCopay" required />
				</div>
				<div>
					<label>Coinsurance<span class="info"><img src="assets/info-circle.svg" /><p>Please enter coinsurance value of the patient responsibility in a decimal value e.g. 20% = .2</p></span></label>
					<input type="number" formControlName="innCoinsurance" required />
				</div>
			</div>
			<div class="flex header">
				<h3>Out Of Network</h3>
				<a class="button" (click)="didClickOutOfNetworkBenefitsNotCovered()">Not Covered</a>
			</div>
			<div class="flex">
				<div>
					<label>Total Deductible</label>
					<input type="number" formControlName="oonTotalDeductible" />
				</div>
				<div>
					<label>Remaining Deductible</label>
					<input type="number" formControlName="oonRemainingDeductible" />
				</div>
			</div>
			<div class="flex">
				<div>
					<label>Out of Pocket Max</label>
					<input type="number" formControlName="oonOutOfPocket" />
				</div>
				<div>
					<label>Out of Pocket Remaining</label>
					<input type="number" formControlName="oonOutOfPocketRemaining" />
				</div>
			</div>
			<div class="flex">
				<div>
					<label>Copay</label>
					<input type="number" formControlName="oonCopay" />
				</div>
				<div>
					<label>Coinsurance<span class="info"><img src="assets/info-circle.svg" /><p>Please enter coinsurance value of the patient responsibility in a decimal value e.g. 20% = .2</p></span></label>
					<input type="number" formControlName="oonCoinsurance" />
				</div>
			</div>
			<h3>Additional Information</h3>
			<div class="flex">
				<div>
					<label>Carve Out Payer</label>
					<select formControlName="carveOutPayerId">
						<option value="">Select A Payer</option>
						<option [value]="payer.payerId" *ngFor="let payer of carveOutPayers">{{ payer.payerName }}</option>
					</select>
				</div>
				<div>
					<label>Resolution Method</label>
					<select formControlName="resolutionMethod" required>
						<option value="database">Database</option>
						<option value="portal">Provider Portal</option>
						<option value="phone">Phone Call</option>
					</select>
				</div>
				<div *ngIf="form.controls['status'].value === 'error.payer'">
					<label>Payer Error</label>
					<select formControlName="payerError" required>
						<option *ngFor="let option of payerErrorOptions" [value]="option.value">
							{{ option.text }}
						</option>
					</select>
				</div>
			</div>
			<button>Submit</button>
		</form>
		<div *ngIf="!isExternalUser">
			<h3>Timeline</h3>
			<div class="events">
				<div class="event">
					<div class="dot"><div></div></div>
					<div class="line"><div></div></div>
					<div class="title">
						<div><b>Created</b></div>
						<div class="date">{{ verification.timestamp | date: 'MM/dd/yyyy, HH:mm:ss' }}</div>
					</div>
				</div>
				<div class="event" *ngFor="let event of verification.events">
					<div class="dot"><div></div></div>
					<div class="line"><div></div></div>
					<div class="title">
						<div>
							<a [routerLink]="['/payers', event.payerId]">
								{{ event.payerName }}
							</a>
						</div>
						<div class="date">{{ event.timestamp | date: 'MM/dd/yyyy, HH:mm:ss' }}</div>
					</div>
					<div class="metadata">
						<div>{{ event.clearingHouse }}</div>
						<div>{{ event.clearingHousePayerId }}</div>
						<div>{{ event.serviceTypeCode }}</div>
						<div>{{ event.statusCodeMessage }}</div>
						<div>
							<a [routerLink]="['/payers', event.payerId, 'specialties', verification.specialtyId]">
								{{ verification.specialtyName }}
							</a>
						</div>
						<div class="action">
							<a class="button" (click)="getResponse(event)">{{ event.response ? "Hide" : "Show" }}</a>
						</div>
					</div>
					<pre *ngIf="event.response && event.statusCode != '408'">{{ event.response | json }}</pre>
				</div>
			</div>
		</div>
	</section>
</div>
<div class="details" *ngIf="verification">
	<div class="section" *ngIf="!isExternalUser">
		<a class="button" [href]="recreateVerificationUrl" target="_blank">Recreate Verification</a>
	</div>
	<div class="section">
		<h4>Details</h4>
		<h5>Status</h5>
		<p [class.empty]="!verification.status">{{ verification.status }}</p>
		<div *ngIf="verification.status === 'error.payer'">
			<h5>Payer Error</h5>
			<p [class.empty]="!verification.payerError">{{ verification.payerError }}</p>
		</div>
		<h5>Created</h5>
		<p>{{ verification.timestamp | date: 'MM/dd/yyyy, HH:mm:ss' }}</p>
		<h5>Updated</h5>
		<p [class.empty]="!verification.updated">{{ verification.updated | date: 'MM/dd/yyyy, HH:mm:ss' }}</p>
		<h5>Resolved</h5>
		<p [class.empty]="!verification.resolved">{{ verification.resolved | date: 'MM/dd/yyyy, HH:mm:ss' }}</p>
		<h5>Resolution Method</h5>
		<p [class.empty]="!verification.resolutionMethod">{{ verification.resolutionMethod || "None" }}</p>
	</div>
	<div class="section">
		<h4>Member</h4>
		<h5>Name</h5>
		<p [class.empty]="!verification.fullName">{{ verification.fullName }}</p>
		<h5>Member ID</h5>
		<p [class.empty]="!verification.memberId">{{ verification.memberId }}</p>
		<h5>Date of Birth</h5>
		<p [class.empty]="!verification.dateOfBirth">{{ verification.dateOfBirth }}</p>
		<h5>State</h5>
		<p [class.empty]="!verification.state">{{ verification?.state }}</p>
		<h5>Address</h5>
		<p [class.empty]="!verification.patientAddress">{{ verification.patientAddress || "None" }}</p>
	</div>
	<div class="section">
		<h4>Provider</h4>
		<h5>Customer Name</h5>
		<p [class.empty]="!verification.customerName">{{ verification.customerName }}</p>
		<h5>Provider Name</h5>
		<p [class.empty]="!verification.organizationName">{{ verification.organizationName }}</p>
		<h5>NPI</h5>
		<p [class.empty]="!verification.npi">{{ verification.npi }}</p>
		<h5>Specialty</h5>
		<p *ngIf="isExternalUser" [class.empty]="!verification.specialtyName">{{ verification.specialtyName || "None" }}</p>
		<p *ngIf="!isExternalUser" [class.empty]="!verification.specialtyName">
			<a [routerLink]="['/specialties', verification.specialtyId]">{{ verification.specialtyName || "None" }}</a>
		</p>
		<h5>Place of Service</h5>
		<p [class.empty]="!verification.placeOfServiceCode">{{ verification.placeOfServiceCode || "None" }}</p>
		<h5>Tax ID</h5>
		<p [class.empty]="!verification.taxId">{{ verification.taxId || "None" }}</p>
	</div>
	<div class="section">
		<h4>Payer</h4>
		<h5>Name</h5>
		<p *ngIf="isExternalUser">{{ verification.payerName }}</p>
		<p *ngIf="!isExternalUser"><a [routerLink]="['/payers', verification.payerId]">{{ verification.payerName }}</a></p>
		<h5>Phone Number</h5>
		<p [class.empty]="!verification.phoneNumber">{{ verification.phoneNumber || "None" }}</p>
		<h5>Related Entity</h5>
		<p [class.empty]="!verification.benefitsRelatedEntity.name">{{ verification.benefitsRelatedEntity?.name || "None" }}</p>
		<h5>Related Entity Phone Number</h5>
		<p [class.empty]="!verification.benefitsRelatedEntity.phoneNumber">{{ verification.benefitsRelatedEntity?.phoneNumber || "None" }}</p>
		<a class="button" (click)="didClickShowRelatedEntityModal(verification.benefitsRelatedEntity)">Add Related Entity</a>
		<h5>Related Payer</h5>
		<p [class.empty]="!verification.relatedPayerName">{{ verification.relatedPayerName || "None" }}</p>
		<h5>Related Payer Phone Number</h5>
		<p [class.empty]="!verification.relatedPayerPhoneNumber">{{ verification.relatedPayerPhoneNumber || "None" }}</p>
		<h5>Related Payer Entity Identifier Code</h5>
		<p [class.empty]="!verification.relatedPayerEntityIdentifierCode">{{ verification.relatedPayerEntityIdentifierCode || "None" }}</p>
	</div>
	<div class="section">
		<h4>Details</h4>
		<h5>Assignee</h5>
		<p *ngIf="isExternalUser" [class.empty]="!verification.adminFullName">{{ verification.adminFullName || "None" }}</p>
		<p *ngIf="!isExternalUser" [class.empty]="!verification.adminFullName">
			<a (click)="didClickSelectAdminAssignee()">{{ verification.adminFullName || "None" }}</a>
		</p>
	</div>
	<div class="section notes">
		<h4>Notes</h4>
		<div class="note" *ngFor="let note of notes">
			<div class="avatar" [ngStyle]="{'background-color': note.avatarBackgroundColor }">
				{{ note.initials }}
			</div>
			<div class="content-container">
				<div class="meta"><span>{{ note.fullName }}</span>{{ note.created | date: 'MM/dd/yyyy, HH:mm:ss' }}</div>
				<div class="content">{{ note.content }}</div>
			</div>
		</div>
		<div class="form">
			<input type="text" [(ngModel)]="content" (keyup.enter)="didClickSendNote()" />
			<div class="flex">
				<a class="button" (click)="didClickSendNote()">Send</a>
			</div>
		</div>
	</div>
	<div class="section notes" *ngIf="!isExternalUser">
		<h4>Jira Ticket</h4>
		<div class="form">
			<input type="text" [(ngModel)]="jiraTicketTitle" placeholder="Title" />
			<input type="text" [(ngModel)]="jiraTicketDescription" (keyup.enter)="didClickCreateJiraTicket()" placeholder="Description" />
			<div class="flex">
				<a class="button" (click)="didClickCreateJiraTicket()">Create</a>
			</div>
		</div>
	</div>
</div>