import { Component } from '@angular/core';
import { AdminService } from "../@shared/services/admin.service"
import { Title } from "@angular/platform-browser"
import { DiscoveryRequest } from "../@shared/models/discoveryRequest"

@Component({
  selector: 'app-discovery',
  templateUrl: './discovery.component.html'
})
export class DiscoveryComponent {

  private discoveryRequests: DiscoveryRequest[] = []

  filterTimestamp: string = "24-hours"
  filterStatus: string = ""
  filterCustomerId: string = ""

  statusMap = {
    0: "Created",
    1: "Pending",
    2: "Complete",
    3: "In Progress",
    4: "Error"
  }

  isLoading = false

  customers: {
    customerId: string,
    name: string
  }[] = []

  get completeCount(): number {
    return this.filteredDiscoveryRequests.filter(discoveryRequest => {
      return discoveryRequest.status == 2
    }).length
  }

  get successfulCount(): number {
    return this.filteredDiscoveryRequests.filter(discoveryRequest => {
      return discoveryRequest.status == 2 &&
        discoveryRequest.memberId != null
    }).length
  }

  get successfulPercentage(): number {
    if (!this.filteredDiscoveryRequests.length) {
      return 0
    }

    return this.successfulCount / this.filteredDiscoveryRequests.length
  }

  get completePercentage(): number {
    if (this.filteredDiscoveryRequests.length === 0) {
      return 0
    }

    return this.completeCount / this.filteredDiscoveryRequests.length
  }

  get pendingPercentage(): number {
    if (this.filteredDiscoveryRequests.length === 0) {
      return 0
    }

    return this.pendingCount / this.filteredDiscoveryRequests.length
  }

  get pendingCount(): number {
    return this.filteredDiscoveryRequests.filter(discoveryRequest => {
      return discoveryRequest.status == 1
    }).length
  }

  get averageSeconds(): number {
    if (!this.filteredDiscoveryRequests.length) {
      return 0
    }

    const total = this.filteredDiscoveryRequests.reduce((acc, discoveryRequest) => {
      return acc + discoveryRequest.seconds
    }, 0)

    return Math.round(total / this.filteredDiscoveryRequests.length * 100) / 100
  }

  get P90(): number {
    return this.P(0.9)
  }

  get P50(): number {
    return this.P(0.5)
  }

  P(percentile: number): number {
    if (!this.filteredDiscoveryRequests.length) {
      return 0
    }

    const times = this.filteredDiscoveryRequests.map(item => item.seconds)

    times.sort((a, b) => a - b)

    const position = percentile * (times.length - 1)
    const base = Math.floor(position)
    const rest = position - base

    if (times[base + 1] !== undefined) {
      return times[base] + rest * (times[base + 1] - times[base])
    } else {
      return times[base]
    }
  }

  get filteredDiscoveryRequests(): DiscoveryRequest[] {
    return this.discoveryRequests.filter(discoveryRequest => {
      const filterStatusValue = this.filterStatus === "" || parseInt(this.filterStatus) === discoveryRequest.status
      const filterCustomerIdValue = this.filterCustomerId === "" || this.filterCustomerId === discoveryRequest.customerId

      return filterStatusValue && filterCustomerIdValue
    })
  }

  constructor(
    private adminService: AdminService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle("Discovery")
    this.getDiscoveryRequests()
  }

  async getDiscoveryRequests() {
    this.isLoading = true

    this.discoveryRequests = []

    const hoursAgo = this.filterTimestamp.split("-")[0] || null

    this.discoveryRequests = await this.adminService.getDiscoveryRequests(hoursAgo)

    this.isLoading = false

    const customerIds: string[] = []

    for (const discoveryRequest of this.discoveryRequests) {
      if (customerIds.includes(discoveryRequest.customerId) === true) {
        continue
      }

      customerIds.push(discoveryRequest.customerId)

      this.customers.push({
        customerId: discoveryRequest.customerId,
        name: discoveryRequest.name
      })
    }
  }

  async didChangeHoursAgoSelection(event: any) {
    this.getDiscoveryRequests()
  }
}
