export class Customer {
	constructor(
		public customerId: string,
		public name: string | null,
		public timestamp: Date,
		public priceEstimate: number | null,
		public npi: string | null,
		public organizationName: string | null,
		public taxId: string | null,
		public category: string | null,
		public refreshInterval: number,
		public description: string | null,
		public maxProductionDiscoveryCount: number | null,
		public maxProductionVerificationCount: number | null,
		public healthieApiKey: string|null,
		public healthieWebhookSecret: string|null
	) { }

	static fromJson(data: any): Customer {
		return new Customer(
			data.customerId,
			data.name,
			data.timestamp,
			data.priceEstimate,
			data.npi,
			data.organizationName,
			data.taxId,
			data.category,
			data.refreshInterval,
			data.description,
			data.maxProductionDiscoveryCount,
			data.maxProductionVerificationCount,
			data.healthieApiKey,
			data.healthieWebhookSecret
		);
	}
}
