import { Component } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { AdminService } from "../@shared/services/admin.service"
import { Payer } from "../@shared/models/payer"

@Component({
  selector: 'related-entity-modal',
  templateUrl: './related-entity-modal.component.html',
  styleUrls: ['./related-entity-modal.component.less']
})
export class RelatedEntityModalComponent {

  isVisible = false

  form: UntypedFormGroup

  payers: Payer[] = []

  relatedEntity: {
    name: string|undefined,
    phoneNumber: string|undefined
  }|undefined = undefined

  constructor(
    private adminService: AdminService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      type: [null, [Validators.required]],
      payerId: [null, []]
    })
  }

  async didClickSubmit() {

    const name = this.relatedEntity?.name
    const phoneNumber = this.relatedEntity?.phoneNumber || null
    const type = this.form.value.type
    const payerId = this.form.value.payerId

    if (type == null) {
      alert("Select a type")
      return
    } else if (payerId == null) {
      alert("Select a payer")
      return
    }

    try {
      await this.adminService.createRelatedEntity(
        name,
        phoneNumber,
        type,
        payerId,
        null)

      alert("Related entity created")
      this.isVisible = false
    } catch (error) {
      alert("Something went wrong")
    }
  }

  didClickCancel() {
    this.isVisible = false
  }

  async show(relatedEntity: {
    name: string|undefined,
    phoneNumber: string|undefined
  }|undefined) {
    if (relatedEntity == undefined ||
      relatedEntity.name == undefined) {
      return
    }

    this.relatedEntity = relatedEntity

    this.isVisible = true

    this.payers = await this.adminService.getPayers()
  }
}
