<div class="fixed top-0 left-[260px] w-[calc(100%-260px)] bg-white border-b border-gray-300 p-6 h-[80px] flex items-center justify-between z-10">
	<h1 class="text-xl font-bold">Discovery</h1>

	<a class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100 ml-4" [href]="recreateVerificationUrl" target="_blank">
		Create Verification
	</a>
</div>

<div class="p-6 bg-white min-h-[calc(100vh-80px)]" *ngIf="discoveryRequest">
	<div class="overflow-x-auto">
		<table class="table w-full">
			<thead class="bg-gray-200 rounded-lg">
				<tr class="rounded-lg border-b-0">
					<th class="rounded-l-lg">Discovery ID</th>
					<th>Customer</th>
					<th>Status</th>
					<th>Created</th>
					<th>Updated</th>
					<th class="rounded-r-lg">Latency</th>
				</tr>
			</thead>
			<tbody>
				<tr class="border-b-0">
					<td>{{ discoveryRequest.discoveryId }}</td>
					<td>{{ discoveryRequest.name }}</td>
					<td>
						<span class="badge badge-outline text-sm py-3"
							[ngClass]="{
								'badge-success': discoveryRequest.status === 2,
								'badge-neutral': discoveryRequest.status === 0 || discoveryRequest.status === 3,
								'badge-warning': discoveryRequest.status === 1,
								'badge-error': discoveryRequest.status === 4
							}">
							{{ requestStatusMap[discoveryRequest.status] }}
						</span>
					</td>
					<td>{{ discoveryRequest.timestamp | date: 'MMM dd, yyyy, HH:mm:ss' }}</td>
					<td>{{ discoveryRequest.updated | date: 'MMM dd, yyyy, HH:mm:ss' }}</td>
					<td>{{ discoveryRequest.seconds }}s</td>
				</tr>
			</tbody>
		</table>

		<table class="table w-full mt-4">
			<thead class="bg-gray-200 rounded-lg">
				<tr class="rounded-lg border-b-0">
					<th class="rounded-l-lg">First Name</th>
					<th>Last Name</th>
					<th>Date of Birth</th>
					<th>State</th>
					<th>Payer</th>
					<th class="rounded-r-lg">Member ID</th>
				</tr>
			</thead>
			<tbody>
				<tr class="border-b-0">
					<td>{{ discoveryRequest.firstName }}</td>
					<td>{{ discoveryRequest.lastName }}</td>
					<td>{{ discoveryRequest.dateOfBirth }}</td>
					<td>{{ discoveryRequest.state }}</td>
					<td>{{ discoveryRequest.payerName }}</td>
					<td>{{ discoveryRequest.memberId }}</td>
				</tr>
			</tbody>
		</table>

		<table class="table w-full mt-4">
			<thead class="bg-gray-200 rounded-lg">
				<tr class="rounded-lg border-b-0">
					<th class="rounded-l-lg">Payer</th>
					<th>Status</th>
					<th>Created</th>
					<th class="rounded-r-lg"></th>
				</tr>
			</thead>
			<tbody *ngFor="let discoveryResponse of discoveryRequest.discoveryResponses || []">
				<tr>
					<td>
						<a [routerLink]="['/payers', discoveryResponse.payerId]" class="font-bold">{{ discoveryResponse.payerName }}</a>
					</td>
					<td>
						<span class="badge badge-outline text-sm py-3"
							[ngClass]="{
								'badge-success': discoveryResponse.status === 2,
								'badge-neutral': discoveryResponse.status === 0,
								'badge-warning': discoveryResponse.status === 1,
								'badge-error': discoveryResponse.status === 3
							}">
							{{ responseStatusMap[discoveryResponse.status] }}
						</span>
					</td>
					<td>{{ discoveryResponse.timestamp | date: 'MMM dd, yyyy, HH:mm:ss' }}</td>
					<td>
						<a *ngIf="discoveryResponse.hasRes" class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100" (click)="getResponse(discoveryResponse)">{{ discoveryResponse.res == null ? "Show" : "Hide" }}</a>
					</td>
				</tr>
				<tr *ngIf="discoveryResponse.res != null || discoveryResponse.res != undefined">
					<td colspan="4">
						<pre class="p-4 text-xs bg-gray-100 rounded-lg max-w-[800px]">{{ discoveryResponse.res | json }}</pre>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
