import { Component } from '@angular/core'
import { AdminService } from "../@shared/services/admin.service"
import { Title } from "@angular/platform-browser"

@Component({
  selector: 'app-taxonomy-codes',
  templateUrl: './taxonomy-codes.component.html'
})
export class TaxonomyCodesComponent {

  taxonomyCodes: any[] = []
  specialties: any[] = []

  constructor(
    private adminService: AdminService,
    private titleService: Title
  ) {}

  async ngOnInit() {
    this.titleService.setTitle("Taxonomy Codes")

    this.taxonomyCodes = await this.adminService.getTaxonomyCodes()
    this.specialties = await this.adminService.getSpecialties()
  }

  async didChangeSpecialty(taxonomyCode: string, event: any) {
    if (taxonomyCode == null ||
      taxonomyCode == undefined ||
      taxonomyCode == "") {
      return
    }

    const element = event.target as HTMLSelectElement

    const specialtyId = element.value

    if (specialtyId == null ||
      specialtyId == undefined ||
      specialtyId == "") {
      return
    }

    try {
      await this.adminService.updateTaxonomyCode(taxonomyCode, specialtyId)

      this.taxonomyCodes = await this.adminService.getTaxonomyCodes()
    } catch (error: any) {
      console.log(error)
      alert("Something went wrong")
    }
  }
}
