<div class="fixed top-0 left-[260px] w-[calc(100%-260px)] bg-white border-b border-gray-300 p-6 h-[80px] flex items-center justify-between z-10">
	<h1 class="text-xl font-bold">{{ payer?.payerName }}</h1>
	<div class="flex">
		<a class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100 ml-4" [routerLink]="['/payers', payer?.payerId, 'edit']">
			Edit Payer
		</a>
		<a class="btn btn-sm bg-white flex items-center gap-2 hover:bg-gray-100 ml-4" (click)="didClickRetryVerifications()">
			Retry Pending Verifications
		</a>
	</div>
</div>

<div class="p-6 bg-white min-h-[calc(100vh-80px)]">

	<div class="tabs tabs-bordered inline-flex">
		<a class="tab h-[48px]" [ngClass]="{ 'tab-active': tab === 'main' }" (click)="navigateToTab('main')">Payer Information</a>
		<a class="tab h-[48px]" [ngClass]="{ 'tab-active': tab === 'payerIds' }" (click)="navigateToTab('payerIds')">Payer IDs</a>
		<a class="tab h-[48px]" [ngClass]="{ 'tab-active': tab === 'states' }" (click)="navigateToTab('states')">States</a>
		<a class="tab h-[48px]" [ngClass]="{ 'tab-active': tab === 'specialties' }" (click)="navigateToTab('specialties')">Specialties</a>
		<a class="tab h-[48px]" [ngClass]="{ 'tab-active': tab === 'relatedPayers' }" (click)="navigateToTab('relatedPayers')">Related Payers</a>
		<a class="tab h-[48px]" [ngClass]="{ 'tab-active': tab === 'auditRecords' }" (click)="navigateToTab('auditRecords')">Audit Records</a>
		<a class="tab h-[48px]" [ngClass]="{ 'tab-active': tab === 'configurations' }" (click)="navigateToTab('configurations')">Configurations</a>
	</div>

	<div *ngIf="payer" class="mt-6">
		<div *ngIf="tab == 'main'">
			<table class="table w-full">
				<thead class="bg-gray-200 rounded-lg">
					<tr class="rounded-lg border-b-0">
						<th class="rounded-l-lg">Sohar ID</th>
						<th>Phone Number</th>
						<th>Type</th>
						<th>Clearing House</th>
						<th>Discovery Clearing House</th>
						<th>Provider Type</th>
						<th>Discovery Enabled</th>
						<th class="rounded-r-lg">Public</th>
					</tr>
				</thead>
				<tbody>
					<tr class="border-b-0">
						<td>{{ payer.payerId }}</td>
						<td>{{ payer.phoneNumber }}</td>
						<td>{{ payer.payerType }}</td>
						<td>{{ payer.clearingHouse }}</td>
						<td>{{ payer.discoveryClearingHouse }}</td>
						<td>{{ payer.providerType }}</td>
						<td>{{ payer.discovery }}</td>
						<td>{{ payer.isCustomerPayer ? "Yes" : "No" }}</td>
					</tr>
				</tbody>
			</table>
			<table class="table w-full mt-6">
				<thead class="bg-gray-200 rounded-lg">
					<tr class="rounded-lg border-b-0">
						<th class="rounded-l-lg">Change ID</th>
						<th>Availity ID</th>
						<th>Office Ally ID</th>
						<th>UnitedHealthcare ID</th>
						<th class="rounded-r-lg">Stedi ID</th>
					</tr>
				</thead>
				<tbody>
					<tr class="border-b-0">
						<td>{{ payer.changeHealthcarePayerId }}</td>
						<td>{{ payer.availityPayerId }}</td>
						<td>{{ payer.officeAllyPayerId }}</td>
						<td>{{ payer.unitedHealthcarePayerId }}</td>
						<td>{{ payer.stediPayerId }}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div *ngIf="tab == 'payerIds'">
			<table class="table w-full">
				<thead class="bg-gray-200 rounded-lg">
					<tr class="rounded-lg border-b-0">
						<th class="rounded-l-lg">Payer ID</th>
						<th class="rounded-r-lg">Primary Payer ID</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let alias of payer.aliases">
						<td>{{ alias.id }}</td>
						<td>{{ alias.isStandardPayerId ? "Yes" : "" }}</td>
					</tr>
					<tr>
						<td>
							<input class="input input-bordered w-full bg-white" type="text" placeholder="Add a new alias payer ID" [(ngModel)]="aliasPayerId" />
						</td>
						<td>
							<a class="btn bg-white flex items-center gap-2 hover:bg-gray-100" (click)="didClickAddPayerAlias()">Add</a>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div *ngIf="tab == 'states'">
			<table>
				<thead>
					<td colspan="2">State</td>
				</thead>
				<tbody>
					<tr *ngFor="let state of payer.states">
						<td>{{ state }}</td>
						<td>
							<a class="button" (click)="didClickDeleteState(state)">Delete</a>
						</td>
					</tr>
					<tr>
						<td>
							<select [(ngModel)]="state">
								<option value="">
									Select a state
								</option>
								<option *ngFor="let _state of states" [value]="_state">
									{{ _state }}
								</option>
							</select>
						</td>
						<td>
							<a class="button" (click)="didClickAddState()">Add</a>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div *ngIf="tab == 'specialties'">
			<table>
				<thead>
					<td>Specialty</td>
				</thead>
				<tbody>
					<tr *ngFor="let specialty of specialties">
						<td>
							<a [routerLink]="['/payers', payer.payerId, 'specialties', specialty.specialtyId]">
								{{ specialty.name }}
							</a>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div *ngIf="tab == 'relatedPayers'">
			<table>
				<thead>
					<td>Payer</td>
					<td>Specialty</td>
					<td></td>
				</thead>
				<tbody>
					<tr *ngFor="let payer of relatedPayers">
						<td>{{ payer.payerName }}</td>
						<td>{{ payer.specialtyName }}</td>
						<td><a class="button" (click)="didClickDeleteRelatedPayer(payer.id)">Delete</a></td>
					</tr>
				</tbody>
			</table>
			<form [formGroup]="relatedPayerForm" (ngSubmit)="didSubmitCreateRelatedPayerForm()" class="inline">
				<select formControlName="relatedPayerId" required>
					<option value="null">Select a related payer</option>
					<option *ngFor="let payer of payers" [value]="payer.payerId">{{ payer.payerName }} - {{ payer.changeHealthcarePayerId }}</option>
				</select>
				<select formControlName="specialtyId" required>
					<option [value]="null">Select a specialty</option>
					<option *ngFor="let specialty of specialties" [value]="specialty.specialtyId">
						{{ specialty.name }}
					</option>
				</select>
				<button class="rel-pay-btn">Submit</button>
			</form>
		</div>
		<div *ngIf="tab == 'auditRecords'">
			<table>
				<thead>
					<td>Operation</td>
					<td>Author</td>
					<td>Specialty Name</td>
					<td>Description</td>
					<td>Timestamp</td>
				</thead>
				<tbody>
					<tr *ngFor="let record of auditRecords">
						<td>{{ record.name }}</td>
						<td>{{ record.firstName }} {{ record.lastName }}</td>
						<td>
							<a [routerLink]="['/specialties', record.specialtyId]">{{ record.specialtyName }}</a>
						</td>
						<td class="json-view">
							<pre [ngClass]="{'expanded': record.expanded}">{{ record.description | json }}</pre>
							<button class="button expand-btn" (click)="toggleExpanded(record)">{{ record.expanded ? 'Collapse' : 'Expand'}}</button>
						</td>
						<td>{{ record.created | date: 'MM/dd/yyyy, HH:mm:ss' }}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div *ngIf="tab == 'configurations'">
			<pre class="p-4 text-xs bg-gray-100 rounded-lg">{{ payer.configurations | json }}</pre>
		</div>
	</div>
</div>
