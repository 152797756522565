import { Component, Input } from '@angular/core';
import { Verification } from "../@shared/models/verification"
import { AdminService } from "../@shared/services/admin.service"
import { Title } from "@angular/platform-browser"
import { Event } from "../@shared/models/event"
import { AuthService } from "../@shared/services/auth.service"

@Component({
  selector: 'app-verification-events',
  templateUrl: './verification-events.component.html'
})
export class VerificationEventsComponent {

  @Input()
  verification: Verification

  isExternalUser = false

  constructor(
    private adminService: AdminService,
    private titleService: Title,
    private authService: AuthService
  ) {}

  async ngOnInit() {
    this.titleService.setTitle("Events - Verification")

    this.isExternalUser = await this.authService.hasGroup("external")
  }

  async getResponse(event: Event) {
    if (event.response) {
      event.response = null
      return
    }
    const verificationId = this.verification.verificationId
    const responseId = event.responseId
    event.response = await this.adminService.getResponse(verificationId, responseId)
  }
}
