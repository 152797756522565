<div class="fixed top-0 left-[260px] w-[calc(100%-260px)] bg-white border-b border-gray-300 p-6 h-[80px] flex items-center justify-between z-10">
	<h1 class="text-xl font-bold">Create Customer</h1>
</div>

<div class="mt-[60px] p-6">
	<div class="w-[600px] mx-auto bg-white border rounded-lg p-6">
		<form [formGroup]="form" (ngSubmit)="didClickCreateSubmit()">

			<label class="form-control w-full">
				<div class="label">
					<span class="label-text">Email Address</span>
				</div>
				<input type="text" class="input input-bordered w-full bg-white"  formControlName="emailAddress" placeholder="Enter email address">
			</label>

			<label class="form-control w-full mt-4">
				<div class="label">
					<span class="label-text">Customer Name</span>
				</div>
				<input type="text" class="input input-bordered w-full bg-white"  formControlName="customerName" placeholder="Enter customer name">
			</label>

			<label class="form-control w-full mt-2">
				<div class="label">
					<span class="label-text">Quota</span>
				</div>
				<input type="number" class="input input-bordered w-full bg-white"  formControlName="customerName" formControlName="maxProductionVerificationCount" placeholder="Enter quota">
				<p class="text-gray-500 text-sm mt-4">This is an optional field that sets the maximum number of verifications that customer can create. This should be used for pilot customers.</p>
			</label>

			<button class="btn btn-accent text-white mt-8 min-w-[180px]">Submit</button>
		</form>
		<table>
			<thead>
				<tr>
					<td>Email Address</td>
					<td>Password</td>
					<td>Production API Key</td>
					<td>Sandbox API Key</td>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>{{ emailAddress }}</td>
					<td>{{ password }}</td>
					<td>{{ productionApiKey }}</td>
					<td>{{ sandboxApiKey }}</td>
				</tr>
			</tbody>
		</table>
		<p>Make sure to copy the password and API keys after creating the customer, they won't be available after you leave this page.</p>
	</div>
</div>