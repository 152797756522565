<div class="fixed top-0 left-[260px] w-[calc(100%-260px)] bg-white border-b border-gray-300 p-6 h-[80px] flex items-center justify-between z-10">
	<h1 class="text-xl font-bold">Edit Payer</h1>
</div>

<div class="mt-[60px] p-6">
	<div class="w-[600px] mx-auto bg-white border rounded-lg p-6">
		<form [formGroup]="form" (ngSubmit)="onSubmit()">

			<label class="form-control w-full">
				<div class="label">
					<span class="label-text">Name</span>
				</div>
				<input type="text" class="input input-bordered w-full bg-white" formControlName="payerName" placeholder="Payer Name" />
			</label>

			<div class="flex gap-4">
				<label class="form-control w-full mt-4">
					<div class="label">
						<span class="label-text">Change Healthcare Payer ID</span>
					</div>
					<input type="text" class="input input-bordered w-full bg-white" formControlName="changeHealthcarePayerId" placeholder="Change Healthcare Payer ID" />
				</label>

				<label class="form-control w-full mt-4">
					<div class="label">
						<span class="label-text">Availity ID</span>
					</div>
					<input type="text" class="input input-bordered w-full bg-white" formControlName="availityPayerId" placeholder="Availity ID" />
				</label>
			</div>

			<div class="flex gap-4">
				<label class="form-control w-full mt-4">
					<div class="label">
						<span class="label-text">Office Ally ID</span>
					</div>
					<input type="text" class="input input-bordered w-full bg-white" formControlName="officeAllyPayerId" placeholder="Office Ally ID" />
				</label>

				<label class="form-control w-full mt-4">
					<div class="label">
						<span class="label-text">Stedi ID</span>
					</div>
					<input type="text" class="input input-bordered w-full bg-white" formControlName="stediPayerId" placeholder="Stedi ID" />
				</label>
			</div>

			<div class="flex gap-4">
				<label class="form-control w-full mt-4">
					<div class="label">
						<span class="label-text">Clearing House</span>
					</div>
					<select class="select select-bordered bg-white" formControlName="clearingHouseIdentifier" required>
						<option [ngValue]="0">Change Healthcare</option>
						<option [ngValue]="1">Availity</option>
						<option [ngValue]="2">Office Ally</option>
						<option [ngValue]="4">Stedi</option>
					</select>
				</label>

				<label class="form-control w-full mt-4">
					<div class="label">
						<span class="label-text">Discovery Clearing House</span>
					</div>
					<select class="select select-bordered bg-white" formControlName="discoveryClearingHouseIdentifier" required>
						<option [ngValue]="0">Change Healthcare</option>
						<option [ngValue]="1">Availity</option>
						<option [ngValue]="2">Office Ally</option>
						<option [ngValue]="4">Stedi</option>
					</select>
				</label>
			</div>

			<div class="flex gap-4">
				<label class="form-control w-full mt-4">
					<div class="label">
						<span class="label-text">Provider Type</span>
					</div>
					<select class="select select-bordered bg-white" formControlName="providerType" required>
						<option value="1">Rendering NPI (Type 1)</option>
						<option value="2">Group NPI (Type 2)</option>
					</select>
				</label>

				<label class="form-control w-full mt-4">
					<div class="label">
						<span class="label-text">Type</span>
					</div>
					<select class="select select-bordered bg-white" formControlName="payerType" required>
						<option value="COMMERCIAL">Commercial</option>
						<option value="MEDICAID">Medicaid</option>
						<option value="MEDICARE">Medicare</option>
						<option value="MILITARY">Military</option>
						<option value="BCBS">Blue Cross Blue Shield</option>
						<option value="MCO">MCO</option>
					</select>
				</label>
			</div>

			<label class="form-control w-full mt-4">
				<div class="label">
					<span class="label-text">Discovery Enabled</span>
				</div>
				<select class="select select-bordered bg-white" formControlName="discovery" required>
					<option value="false">False</option>
					<option value="true">True</option>
				</select>
			</label>

			<button class="btn btn-accent text-white mt-8 min-w-[180px]">Submit</button>
		</form>
	</div>
</div>
