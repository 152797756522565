import { Injectable } from "@angular/core"
import { Alert } from "../models/alert"
import { Subscriber } from "../models/subscriber"

@Injectable({
  providedIn: "root"
})

export class AlertService {

  private alerts: Alert[] = []
  private subscribers: Subscriber[] = []

  async publish(message: string, type: string) {

    const id = Math.floor(Math.random() * 1000000000)

    this.alerts.push({
      id,
      message,
      type
    })

    this.alertSubscribers()

    await new Promise(resolve => setTimeout(resolve, 4000))

    for (let i = 0; i < this.alerts.length; i++) {
      const alert = this.alerts[i]

      if (alert.id !== id) {
        continue
      }

      this.alerts.splice(i, 1)

      break
    }
  }

  subscribe(subscriber: Subscriber) {
    this.subscribers.push(subscriber)
  }

  unsubcribe(_subscriber: Subscriber) {

    for (let i = 0; i < this.subscribers.length; i++) {

      const subscriber = this.subscribers[i]

      if (subscriber != _subscriber) {
        continue
      }

      this.subscribers.splice(i, 1)

      break
    }
  }

  private alertSubscribers() {
    for (const subscriber of this.subscribers) {
      subscriber.handleAlerts(this.alerts)
    }
  }

}
