<section class="events" *ngIf="verification">
	<div class="event">
		<div class="dot"><div></div></div>
		<div class="line"><div></div></div>
		<div class="title">
			<div><b>Created</b></div>
			<div class="date">{{ verification.timestamp | date: 'MM/dd/yyyy, HH:mm:ss' }}</div>
		</div>
	</div>
	<div class="event" *ngFor="let event of verification.events">
		<div class="dot"><div></div></div>
		<div class="line"><div></div></div>
		<div class="title">
			<div>
				<a [routerLink]="['/payers', event.payerId]">
					{{ event.payerName }}
				</a>
			</div>
			<div class="date">{{ event.timestamp | date: 'MM/dd/yyyy, HH:mm:ss' }}</div>
		</div>
		<div class="metadata" *ngIf="!isExternalUser">
			<div>{{ event.clearingHouse }}</div>
			<div>{{ event.clearingHousePayerId }}</div>
			<div>{{ event.serviceTypeCode }}</div>
			<div>{{ event.statusCodeMessage }}</div>
			<div>
				<a [routerLink]="['/payers', event.payerId, 'specialties', verification.specialtyId]">
					{{ verification.specialtyName }}
				</a>
			</div>
			<div class="action">
				<a class="btn bg-white hover:bg-gray-100" (click)="getResponse(event)">{{ event.response ? "Hide" : "Show" }}</a>
			</div>
		</div>
		<pre class="p-4 text-xs bg-gray-100 rounded-lg max-w-[800px]" *ngIf="event.response && event.statusCode != '408'">{{ event.response | json }}</pre>
	</div>
</section>
