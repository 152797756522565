<div *ngIf="isVisible" class="modal-backdrop">
	<div class="modal-body">
		<h2>{{ relatedEntity?.name }}</h2>
		<h3 *ngIf="relatedEntity && relatedEntity.phoneNumber">{{ relatedEntity?.phoneNumber }}</h3>
		<form [formGroup]="form" (ngSubmit)="didClickSubmit()">
			<label>Type</label>
			<select formControlName="type" required>
				<option [value]="null">Select a type</option>
				<option [value]="'internal'">Internal</option>
				<option [value]="'um'">Utilization Management</option>
				<option [value]="'mco'">MCO</option>
			</select>
			<label>Payer</label>
			<select formControlName="payerId">
				<option value="null">Select a payer</option>
				<option *ngFor="let payer of payers" [value]="payer.payerId">{{ payer.payerName }}</option>
			</select>
			<button>Submit</button>
			<a class="cancel" (click)="didClickCancel()">Cancel</a>
		</form>
	</div>
</div>