<div class="header">
	<h1>Edit Payer</h1>
</div>
<div>
	<form [formGroup]="form" (ngSubmit)="onSubmit()">
		<label>Name</label>
		<input type="text" formControlName="payerName" placeholder="Payer Name" />
		<label>Change Healthcare Payer ID</label>
		<input type="text" formControlName="changeHealthcarePayerId" placeholder="Change Healthcare Payer ID" />
		<label>Availity ID</label>
		<input type="text" formControlName="availityPayerId" placeholder="Availity ID" />
		<label>Office Ally ID</label>
		<input type="text" formControlName="officeAllyPayerId" placeholder="Office Ally ID" />
		<label>Clearing House</label>
		<select formControlName="clearingHouseIdentifier" required>
			<option value="0">Change Healthcare</option>
			<option value="1">Availity</option>
			<option value="2">Office Ally</option>
		</select>
		<label>Provider Type</label>
		<select formControlName="providerType" required>
			<option value="1">Rendering NPI (Type 1)</option>
			<option value="2">Group NPI (Type 2)</option>
		</select>
		<label>Type</label>
		<select formControlName="payerType" required>
			<option value="COMMERCIAL">Commercial</option>
			<option value="MEDICAID">Medicaid</option>
			<option value="MEDICARE">Medicare</option>
			<option value="MILITARY">Military</option>
		</select>
		<button>Submit</button>
	</form>
</div>