<div class="header">
	<div class="stat">
		<b>Total</b>
		<span>{{ filteredDiscoveryRequests.length | number }}</span>
	</div>
	<div class="stat">
		<b>Complete</b>
		<span>{{ completeCount | number }}</span>
	</div>
	<div class="stat">
		<b>Pending</b>
		<span>{{ pendingCount | number }}</span>
	</div>
	<div class="stat">
		<b>Successful</b>
		<span>{{ successfulPercentage | percent }}</span>
	</div>
	<div class="stat">
		<b>Time to Resolve</b>
		<span>{{ averageSeconds | number }}</span>
	</div>
</div>
<div class="filters">
	<select [(ngModel)]="filterTimestamp" (change)="didChangeHoursAgoSelection($event)">
		<option value="">
			All Time
		</option>
		<option value="24-hours">
			Previous 24 Hours
		</option>
		<option value="12-hours">
			Previous 12 Hours
		</option>
		<option value="1-hour">
			Previous Hour
		</option>
	</select>
	<select [(ngModel)]="filterStatus">
		<option value="">
			All Statuses
		</option>
		<option [value]="0">
			Created
		</option>
		<option [value]="3">
			In Progress
		</option>
		<option [value]="1">
			Pending
		</option>
		<option [value]="2">
			Complete
		</option>
		<option [value]="5">
			Error
		</option>
	</select>
</div>
<div>
	<table>
		<thead>
			<td>Discovery ID</td>
			<td>Customer</td>
			<td>Patient</td>
			<td>State</td>
			<td>Payer</td>
			<td>Member ID</td>
			<td>Requests</td>
			<td>Status</td>
			<td>Created</td>
			<td>Seconds</td>
		</thead>
		<tbody *ngFor="let discoveryRequest of filteredDiscoveryRequests">
			<tr>
				<td>
					<a [routerLink]="['/discovery', discoveryRequest.discoveryId]">{{ discoveryRequest.discoveryId }}</a>
				</td>
				<td>{{ discoveryRequest.name }}</td>
				<td>{{ discoveryRequest.firstName }} {{ discoveryRequest.lastName }}</td>
				<td>{{ discoveryRequest.state }}</td>
				<td>{{ discoveryRequest.payerName }}</td>
				<td>{{ discoveryRequest.memberId }}</td>
				<td>{{ discoveryRequest.count }}</td>
				<td>{{ statusMap[discoveryRequest.status] }}</td>
				<td>{{ discoveryRequest.timestamp | date: 'MMM dd, yyyy, HH:mm:ss' }}</td>
				<td>{{ discoveryRequest.seconds }}</td>
			</tr>
		</tbody>
	</table>
</div>
