import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { AdminService } from "../@shared/services/admin.service"
import { Configuration } from "../@shared/models/configuration"
import { SERVICE_TYPE_CODES } from "../@shared/constants/data"

@Component({
  selector: 'app-taxonomy-code',
  templateUrl: './taxonomy-code.component.html'
})

export class TaxonomyCodeComponent {

  payerId: string|null = null
  specialtyId: string|null = null
  filters: {
    id: number,
    value: string,
    prioritise: boolean,
    exclude: boolean
  }[] = []
  configurations: Configuration[] = []
  requestConfigurations: Configuration[] = []
  responseConfigurations: Configuration[] = []
  requestServiceTypeCode: string|null = null
  responseServiceTypeCode: string|null = null
  serviceTypeCodes: {
    code: string,
    name: string
  }[] = []
  filterValue: string|null = null
  prioritiseFilterValue: string = "true"
  excludeFilterValue: string = "false"
  payer: any
  specialty: {
    specialtyId: string,
    name: string
  }|null = null

  constructor(
    private adminService: AdminService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.payerId = this.route.snapshot.paramMap.get("id")
    this.specialtyId = this.route.snapshot.paramMap.get("specialtyId")
  
    if (
      !this.payerId ||
      !this.specialtyId
    )  {
      alert("Something went wrong")
      return
    }

    this.serviceTypeCodes = SERVICE_TYPE_CODES

    await this.getPayerTaxonomyCode()
  }

  async getPayerTaxonomyCode() {
    const res = await this.adminService.getPayerSpecialty(
      this.payerId,
      this.specialtyId)

    this.payer = res.payer
    this.filters = res.payerSpecialtyFilters || []
    this.configurations = res.payerConfigurations
    this.specialty = res.specialty
    this.requestConfigurations = res.payerConfigurations.filter((obj: any) => {
      return obj.transactionSet == "270"
    })
    this.responseConfigurations = res.payerConfigurations.filter((obj: any) => {
      return obj.transactionSet == "271"
    })
  }

  async didClickDeleteConfiguration(
    configuration: Configuration
  ) {
    const payerId = this.payerId
    const configurationId = configuration.configurationId

    if (
      !payerId ||
      !configurationId
    ) {
      return
    }

    const confirmed = confirm("Are you sure you want to delete this configuration?")

    if (!confirmed) {
      return
    }

    try {
      await this.adminService.deleteConfiguration(
        payerId,
        configurationId
      )
      alert("Configuration deleted")
      await this.getPayerTaxonomyCode()
    } catch (error: any) {
      alert("Something went wrong")
    }
  }

  async didClickDeleteFilterValue(
    id: number
  ) {
    const payerId = this.payerId
    const specialtyId = this.specialtyId

    if (
      !payerId||
      !specialtyId ||
      id == null ||
      id == undefined
    ) {
      return
    }

    const confirmed = confirm(
      `Are you sure you want to delete this value?`
    )

    if (!confirmed) {
      return
    }

    try {
      await this.adminService.deleteFilterValue(
        payerId,
        specialtyId,
        id
      )
      await this.getPayerTaxonomyCode()
    } catch (error: any) {
      alert("Something went wrong")
    }
  }

  async didClickAddFilterValue() {
    const payerId = this.payerId
    const specialtyId = this.specialtyId
    const filterValue = this.filterValue
    const prioritise = this.prioritiseFilterValue == "true"
    const exclude = this.excludeFilterValue == "true"

    if (!payerId||
      !specialtyId ||
      !filterValue) {
      return
    }

    try {
      await this.adminService.createFilterValue(
        payerId,
        specialtyId,
        filterValue,
        prioritise,
        exclude)

      this.filterValue = null
      this.prioritiseFilterValue = "true"
      this.excludeFilterValue = "false"
      await this.getPayerTaxonomyCode()
    } catch (error: any) {
      alert("Something went wrong")
    }
  }

  async didClickAddServiceTypeCode(
    serviceTypeCode: string,
    transactionSet: string
  ) {
    const payerId = this.payerId
    const specialtyId = this.specialtyId

    if (
      !payerId||
      !specialtyId ||
      !transactionSet ||
      !serviceTypeCode
    ) {
      return
    }

    let priority = 0

    if (
      transactionSet == "270" &&
      this.requestConfigurations.length > 0
    ) {
      priority = this.requestConfigurations[this.requestConfigurations.length - 1].priority + 1
    } else if (
      transactionSet == "271" &&
      this.responseConfigurations.length > 0
    ) {
      priority = this.responseConfigurations[this.responseConfigurations.length - 1].priority + 1
    }

    try {
      await this.adminService.createConfiguration(
        payerId,
        specialtyId,
        serviceTypeCode,
        transactionSet,
        priority
      )
      this.requestServiceTypeCode = null
      this.responseServiceTypeCode = null
      await this.getPayerTaxonomyCode()
    } catch (error: any) {
      alert("Something went wrong")
    }
  }
}
