<div class="header">
	<h1>Specialties</h1>
</div>
<table>
	<thead>
		<td>Specialty</td>
		<td>Specialty Code</td>
		<td>Taxonomy Codes</td>
		<td>CPT Codes</td>
	</thead>
	<tbody>
		<tr *ngFor="let specialty of sortedSpecialties">
			<td>
				<a [routerLink]="['/specialties', specialty.specialtyId]">
					{{ specialty.name }}
				</a>
			</td>
			<td>{{ specialty.code }}</td>
			<td>{{ specialty.taxonomyCodeCount }}</td>
			<td>{{ specialty.cptCodeCount }}</td>
		</tr>
		<tr>
			<td>
				<input type="text" placeholder="Specialty name" [(ngModel)]="name" />
			</td>
			<td colspan="2">
				<input type="text" placeholder="Specialty code" [(ngModel)]="code" />
			</td>
			<td>
				<a class="button" (click)="didClickCreateSpecialty()">Create</a>
			</td>
		</tr>
	</tbody>
</table>
