import { Component, Input } from '@angular/core';
import { PayerMapping } from "../@shared/models/payer-mapping"
import { Verification } from "../@shared/models/verification"
import { Title } from "@angular/platform-browser"
import { AdminService } from "../@shared/services/admin.service"
import { Payer } from "../@shared/models/payer"
import { PayerIntelligenceService } from "../@shared/services/payer-intelligence.service"
import { getEntityIdentifierCode, getPayerId } from "../@shared/helpers/relatedEntities"

@Component({
  selector: 'app-verification-related-entities',
  templateUrl: './verification-related-entities.component.html'
})
export class VerificationRelatedEntitiesComponent {

  @Input() verification: Verification

  @Input() payers: Payer[] = []

  rules: PayerMapping[] = []

  verificationId: string|null = null

  constructor(
    private payerIntelligenceService: PayerIntelligenceService,
    private titleService: Title,
    private adminService: AdminService
  ) {}

  async ngOnInit() {
    this.titleService.setTitle("Related Entities - Verification")

    this.verificationId = this.verification.verificationId

    await this.getRules()

    this.rules = this.rules.map((rule) => {
      if (rule.relatedEntityPayerId == null &&
        rule.selectedRelatedEntityPayerId == null) {
        const selectedRelatedEntityPayerId = getPayerId(rule.data, rule.payerId, this.payers)

        if (selectedRelatedEntityPayerId != null) {
          rule.selectPayer = true
          rule.selectedRelatedEntityPayerId = selectedRelatedEntityPayerId
        }
      }

      if (rule.entityIdentifierCode == null &&
        rule.selectedEntityIdentifierCode == null) {
        rule.selectedEntityIdentifierCode = getEntityIdentifierCode(rule.data)
      }

      return rule
    })
  }

  async getRules() {
    this.rules = await this.adminService.getVerificationPayerMappingRules(this.verification.verificationId)
  }

  async getRuleForId(id: number) {
    const rule = await this.payerIntelligenceService.getPayerMappingRule(id)

    if (rule == null) {
      return
    }

    let index = -1

    for (let i = 0; i < this.rules.length; i++) {
      if (rule.id == this.rules[i].id) {
        index = i
        break
      }
    }

    this.rules[index] = rule
  }
}
